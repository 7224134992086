<template>
    <div>
        <loader v-if='loading' />
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Title">
                <b-form-input v-model="subscription.title" :state="states.title" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.title' />
            </b-form-group>

            <b-form-group label="Status">
                <v-select v-model='subscription.status' :options="['active', 'cancelled']" />
                <FormGroupError :errors='errors.status' />
            </b-form-group>

            <b-form-group label="Start At">
                <b-form-datepicker v-model="subscription.start_at"></b-form-datepicker>
                <FormGroupError :errors='errors.start_at' />
            </b-form-group>

            <b-form-group label="End At">
                <b-form-datepicker v-model="subscription.end_at"></b-form-datepicker>
                <FormGroupError :errors='errors.end_at' />
            </b-form-group>


            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    data: function () {
        return {
            subscription: {
                status: 'active'
            }
        }
    },
    methods: {
        load: function (id) {
            return this.apiRequest(apiClient => apiClient.get('subscriptions/' + id))
                .then(response => this.subscription = response);
        },
        save: function (apiClient) {
            if (this.id) {
                return apiClient.put('subscriptions/' + this.id, this.subscription);
            } else {
                return apiClient.post('subscriptions', this.subscription);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        }
    },
}
</script>