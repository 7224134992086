<template>
    <div>
        <ListView title="Transactions List" :modalObject='currentModalObject' >
            <template v-slot:actions>

            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>
                    </div>
                    <b-form-group>
                        <b-form-checkbox
                            v-model="filter.summary"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Summary
                        </b-form-checkbox>
                    </b-form-group>

                    <b-form-group label="Summary Options" v-if='filter.summary == 1'>
                        <b-form-checkbox
                            v-model="filter.group_by_type"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Group By Type
                        </b-form-checkbox>
                        <b-form-checkbox
                            v-model="filter.group_by_entity_id"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Group By Entity
                        </b-form-checkbox>

                        <b-form-checkbox
                            v-model="filter.group_by_status"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Group By Status
                        </b-form-checkbox>

                        <b-form-checkbox
                            v-model="filter.group_by_transactionable_type"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Group By Transctionable Type
                        </b-form-checkbox>

                        <b-form-group label="Group By">
                            <b-form-radio-group
                                v-model="filter.group_by_period"
                                :options="['day', 'month', 'year', 'none']"
                            ></b-form-radio-group>
                        </b-form-group>

                    </b-form-group>
                </div>

            </template>

			<template v-slot:table>
				<transactions-summary-list-table  v-if='filter.summary == "1"' :filter='filter' ref='tableList' :viewType='viewType'></transactions-summary-list-table>
				<TransactionsListTable v-else :filter='filter' ref='tableList' :viewType='viewType'></TransactionsListTable>
			</template>
                    
        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import TransactionsListTable from '@/components/Lists/TransactionsListTable';
import TransactionsSummaryListTable from '../../components/Lists/TransactionsSummaryListTable.vue';

export default {
    extends: BaseList,
    props: ['entityId'],
    components: {TransactionsListTable, TransactionsSummaryListTable},
    methods: {
        refreshTable () {
            this.$refs.tableList.refresh();
        }
    },
    watch: {
        entityId: {
            immediate: true,
            handler:
                function () {
                    this.filter.entity_id = this.entityId;
                }
        }
    }
}
</script>
