<template>
    <div>
        <b-form class='m-2'>
            <b-form-group label="Date Range">
                <my-date-range-picker v-model='dateRange' :style='{display:"inline-block", "vertical-align": "middle"}'/>
                <div class='btn btn-danger btn-xxs ml-2' @click='() => dateRange = {startDate: null, endDate: null}'>x</div>
            </b-form-group>
            <h3>
                from {{ startDate() }} to {{ endDate() }}
            </h3>
            <b-form-checkbox
                v-model="localFilter.hide_canceled"
                value="1"
                unchecked-value="0"
                :inline='true'
            >
                Hide Canceled 
            </b-form-checkbox>

            <b-form-checkbox
                v-model="localFilter.group_by_status"
                value="1"
                unchecked-value="0"
                :inline='true'
            >
                Group By Status
            </b-form-checkbox>
        </b-form>
        
        <authorized-url class='btn btn-primary ml-2 float-right' url='summary/view' :params='exportParams'
            target="_blank">
            <i class="fa fa-file-excel"></i>
            Export
        </authorized-url>
        <loader v-if='loading'/>
        <b-tabs v-else content-class="mt-3" pills>
            <b-tab title="Sales" active>
                <div v-if='summary.sales'>
                    <div v-for='(entitySales, entityType) in summary.sales' :key='entityType'>
                        <h3>{{ entityType }}</h3>
                        <hr>
                        <div v-for='(sales, group_name) in entitySales' :key='group_name' class='m-3'>
                            <h4 class=''>{{ group_name }}</h4>
                            <b-table :items='sales' :fields='visibleFields([
                                {key: "name", sortable: true},
                                {key: "status", sortable: true, visible: localFilter.group_by_status == 1 ? true : false},
                                {key: "total", sortable: true},
                                {key: "total_remaining", sortable: true},
                                {key: "total_paid", sortable: true},
                                {key: "count", sortable: true},
                                {key: "actions", sortable: false}
                            ])' hover foot-clone>
                                <template #cell(total)="data">
                                    {{ data.item.total | decimal }}
                                </template>
                                <template #cell(total_remaining)="data">
                                    <div v-if='data.item.total_remaining > 0'>
                                    {{ data.item.total_remaining | decimal }}
                                    </div>
                                </template>
                                <template #cell(total_paid)="data">
                                    <div v-if='data.item.total_paid > 0'>
                                    {{ data.item.total_paid | decimal }}
                                    </div>
                                </template>
                                <template #cell(count)="data">
                                    {{ data.item.count | decimal }}
                                </template>

                                <template  #cell(actions)="data">
                                    <div class='btn btn-xs btn-primary' @click="modal('entitySaleablesList', {filter: {saleable_id: data.item.id, start_date:dateRange.startDate, end_date:dateRange.endDate, by_start: 1}})">
                                        view
                                    </div>
                                </template>
                                <template #foot(total)="">
                                    {{ total(sales, 'total') | decimal }}
                                </template>
                                <template #foot(total_remaining)="">
                                    {{ total(sales, 'total_remaining') | decimal }}
                                </template>
                                <template #foot(total_paid)="">
                                    {{ total(sales, 'total_paid') | decimal }}
                                </template>
                                <template #foot(count)="">
                                    {{ total(sales, 'count') | decimal }}
                                </template>
                            </b-table>
                            <hr>
                        </div>
                        
                    </div>
                </div>
            </b-tab>
            <b-tab title="Payments" lazy>
                <b-table :items='summary.cash_payments'  hover>
                    <template v-slot:cell(total)='data'>
                        <div>
                            {{ data.item.total | currency }}
                        </div>
                    </template>
                </b-table>
            </b-tab>
            <b-tab title="Delivery" lazy>

                <b-table :items='summary.items'  hover>
                    <template v-slot:cell(total)='data'>
                        <div>
                            {{ data.item.total | currency }}
                        </div>
                    </template>
                </b-table>
            </b-tab>
        </b-tabs>
        
		<Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
		</Modals>
    </div>
</template>
<script>
import AuthorizedUrl from '../../components/AuthorizedUrl.vue';
export default {
  components: { AuthorizedUrl },
    props: ['filter'],
    data: function () {
        return {
            summary: {},
            dateRange: {},
            localFilter: {
                hide_canceled: 1,
                group_by_status: 0,
            },
            loading: true
        };
    },
    mounted() {
        this.load();
    },
    methods: {
        load: function () {
            this.loading = true;
            this.apiRequest(apiClient => {
                var startDate = this.dateRange.startDate ? this.toDate(this.dateRange.startDate) : null;
    			var endDate = this.dateRange.endDate ? this.toDate(this.dateRange.endDate) : null; 
                var filter = this.localFilter
                if (startDate && endDate) {
                    filter = {...filter, start_date: startDate, end_date: endDate};
                }

                return apiClient.get('summary/view', filter);
            }).then(response => {
                this.loading = false;
                this.summary = response
            });
        },
        startDate: function () {
            return this.dateRange.startDate ? this.toDate(this.dateRange.startDate) : null;
        },
        endDate: function () {
            return this.dateRange.endDate ? this.toDate(this.dateRange.endDate) : null;
        },
        total: function(items, key) {
            return items.reduce((a, c) => {
                return a + Number(c[key] || 0);
            }, 0);
        },
        visibleFields(fields) {
            return fields.filter(field => (field.visible || field.visible == undefined) ? true : false)
        }
    },
    // {export: 1, start_date: startDate(), end_date: endDate(), ...localFilter}
    computed: {
        exportParams: function () {
            return {
                ...this.localFilter,
                export: 1,
                start_date: this.startDate(),
                end_date: this.endDate(),
            }
        }
    },
    watch: {
		dateRange: {
            immediate: true,
            deep: true,
            handler: function () {
                // var startDate = this.dateRange.startDate ? this.toDate(this.dateRange.startDate) : null;
                // var endDate = this.dateRange.endDate ? this.toDate(this.dateRange.endDate) : null; 

                // if (this.filter && startDate == this.filter.start_date && endDate == this.filter.end_date) {
                //     return;
                // } 
                this.load();
            }
        },
        filter: {
            handler() {
                this.localFilter = {...this.localFilter, ...this.filter};
            },
            immediate: true,
            deep: true
        },
        localFilter: {
            handler() {
				var filter = this.localFilter;
                this.dateRange = {
                    startDate: filter.start_date ? filter.start_date : null,
                    endDate: filter.end_date ? filter.end_date : null
                }; 
                
                this.load();
            },
            immediate: true,
            deep: true
        },
    }
}
</script>