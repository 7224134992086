<template>
    <div class='summary-table'>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject'>
            <template v-slot:cell(date)='data'>
                {{ data.item.date }}
            </template>

            <template v-slot:cell(starting_balance)='data'>
                <div  title="Starting balance">
                    <div v-if='data.item.starting_balance != 0 || data.item.balance_adjustment != 0'>
                        {{ data.item.starting_balance | currency}}
                    </div>
                    <div v-if='data.item.balance_adjustment != 0' class='badge badge-warning'>
                        Adjustment: {{ data.item.balance_adjustment | currency}}
                    </div>
                </div>
            </template>

            <template v-slot:cell(ending_balance)='data'>
                <div v-if='data.item.ending_balance != 0' title="ending balance">
                    {{ data.item.ending_balance | currency}}
                </div>
            </template>
            <template v-slot:cell(total_in)='data'>
                <div v-if='data.item.total_in != 0'>
				    <div class='clickable' @click="modal('cashPaymentsList', {filter: {type: 'cash-in', entity_id: filter.entity_id, start_date: startDate(data.item.date), end_date: endDate(data.item.date)}}, 'refresh')">
                        {{ data.item.total_in | currency}}
                    </div>
                </div>
            </template>
             <template v-slot:cell(total_out)='data'>
                <div v-if='data.item.total_out != 0'>
				    <div class='clickable' @click="modal('cashPaymentsList', {filter: {type: 'cash-out', entity_id: filter.entity_id, start_date: startDate(data.item.date), end_date: endDate(data.item.date)}}, 'refresh')">
                        {{ data.item.total_out | currency}}
                    </div>
                </div>
            </template>
            <template v-slot:cell(balance_in)='data'>
                <div v-if='data.item.balance_in != 0'>
                    {{ data.item.balance_in | currency}}
				    <button class='btn btn-xxs btn-primary m-2' @click="modal('cashPaymentsList', {filter: {type: 'balance-in', entity_id: filter.entity_id, start_date: startDate(data.item.date), end_date: endDate(data.item.date)}}, 'refresh')">
                        <i class='fa fa-eye'></i>
                    </button>
                </div>
            </template>
            <template v-slot:cell(balance_out)='data'>
                <div v-if='data.item.balance_out != 0'>
				    <div class='clickable' @click="modal('cashPaymentsList', {filter: {type: 'balance-out', entity_id: entityId, start_date: startDate(data.item.date), end_date: endDate(data.item.date)}}, 'refresh')">
                        {{ data.item.balance_out | currency}}
                    </div>
                </div>
            </template>
            <template v-slot:cell(sales_count)='data'>
                <div v-if='data.item.sales_count != 0'>
                    {{ data.item.sales_count | decimal}}

				    <button class='btn btn-xxs btn-primary m-2' @click="modal('entitySaleablesList', {filter: {entity_id: filter.entity_id, start_date: startDate(data.item.date), end_date: endDate(data.item.date)}}, 'refresh')">
                        <i class='fa fa-eye'></i>
                    </button>
                </div>
            </template>
            <template v-slot:cell(total_sales)='data'>
                <div v-if='data.item.total_sales != 0'>
				    <div class='clickable' @click="modal('entitySaleablesList', {filter: {type: 'sales', entity_id: filter.entity_id, start_date: startDate(data.item.date), end_date: endDate(data.item.date)}}, 'refresh')">
                        <div class="badge badge-light mr-2" title='number of sales'>
                            {{ data.item.sales_count | decimal}}
                        </div>
                        <i class='fa fa-shopping-cart'></i>
                        {{ data.item.total_sales | currency}}
                    </div>
                </div>
            </template>
            <template v-slot:cell(total_expenses)='data'>
                <div v-if='data.item.total_expenses != 0'>
				    <div class='clickable' @click="modal('entitySaleablesList', {filter: {type: 'expenses', entity_id: filter.entity_id, start_date: startDate(data.item.date), end_date: endDate(data.item.date)}}, 'refresh')">
                        <div class="badge badge-light mr-2" title='number of sales'>
                            {{ data.item.expenses_count | decimal}}
                        </div>
                        <i class='fa fa-shopping-cart'></i>
                        {{ data.item.total_expenses | currency}}
                    </div>
                </div>
            </template>
            <template v-slot:cell(revenue)='data'>
                <div v-if='data.item.total_in - data.item.total_out != 0'>
                    {{ data.item.total_in - data.item.total_out | currency }}
                </div>
            </template>
            <template v-slot:cell(total_delivery)='data'>
                <div v-if='data.item.total_delivery != 0'>
                    <i class='fa fa-truck-loading'></i>
                    {{ data.item.total_delivery | decimal }}
                </div>
            </template>
            <template v-slot:cell(actions)='data'>
				<button v-if='can("summary.view")' class='btn btn-xs btn-primary m-2' @click="modal('summaryView', {filter: {entity_id: filter.entity_id, start_date: startDate(data.item.date), end_date: endDate(data.item.date)}}, 'refresh')">view</button>
            </template>
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';
import moment from 'moment';

export default {
    components: { BaseList },
    extends: BaseListMethods,
    props: ['viewType'],
    data: function () {
        return {
            fields: [
                "date",
                {key: "starting_balance", tdClass: "grey"},
                {key: "balance_in", tdClass: "green-extra"},
                {key: "total_in", tdClass: "green"},
                {key: "total_out", tdClass: "red"},
                {key: "balance_out", tdClass: "red-extra"},
                {key: "revenue", tdClass: "grey"},
                {key: "ending_balance", tdClass: "grey"},
                {key: "total_sales", tdClass: "orange"},
                {key: "total_expenses", tdClass: "orange"},
                {key: "total_delivery", tdClass: "orange"},
                "actions",
            ],
        };
    },
    mounted() {
        if (this.viewType == 'entity') {
            this.hideFields = ['starting_balance', 'ending_balance'];
        }
    },
    methods: {
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('summary', filter, cancelToken).then(data => {
                return {data: data};
            });
		},
        startDate: function (date) {
            if (this.filter.group_by == 'month') {
                return moment(date+"-01").startOf('month').format('YYYY-MM-DD');
            } else if (this.filter.group_by == 'year') {
                return moment(date+"-01-01").startOf('year').format('YYYY-MM-DD');
            } 

            return date;
        },
        endDate: function (date) {
            if (this.filter.group_by == 'month') {
                return moment(date+"-01").endOf('month').format('YYYY-MM-DD');
            } else if (this.filter.group_by == 'year') {
                return moment(date+"-01-01").endOf('year').format('YYYY-MM-DD');
            } 
            return date;
        }
    },
}
</script>
<style>
    .summary-table .grey {
        background-color: rgb(237 237 237);
    }
    .summary-table .green {
        background-color: rgb(56, 212, 147);
    }
    .summary-table .green-extra {
        background-color: rgb(155 214 190);
    }
    .summary-table .red {
        background-color: rgb(245, 147, 147);
    }
    .summary-table .red-extra {
        background-color: rgb(240 186 186);
    }
    .summary-table .orange {
        background-color: rgb(182, 211, 235);
    }
    .summary-table tr:hover {
        background-color: #eee;
        filter: brightness(105%);
    }
    .summary-table td:hover {
        filter: brightness(105%);
    }
    .summary-table .clickable {
        cursor: pointer;
    }
</style>