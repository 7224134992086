<template>
    <div>
        <div v-if='classRoom'>

            <h3><i class='fa fa-child'></i> {{ classRoom.name }}</h3>
            
             <div class='profile-tab'>
                <div class='custom-tab-1'>
                    <b-tabs content-class="mt-3">
                        <b-tab title="Kids" lazy>
                            <kids-list :classRoomId='classRoom.id'></kids-list>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>

		<Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'/>
    </div>
</template>

<script>
import KidsList from './KidsList.vue';

export default {
    components:{
        KidsList
    },
    props: ['id'],
    mounted() {
        this.apiRequest(
            (apiClient) => {
                return apiClient.get('class-rooms/'+this.id)
                    .then(classRoom => {
                        this.classRoom = classRoom;
                    });
            }
        );
    },
    methods: {
        
    },
    data: function () {
        return {
            classRoom: null
        }
    },
}
</script>