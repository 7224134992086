<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='currentFields' :modalObject='currentModalObject' @pageChanged='pageChanged'>
            
            
            <template v-slot:cell(entity)='data'>
                <div v-if='data.item.entity'>
                    <router-link v-if='data.item.entity.type == "kid"' :to="{ name: 'ViewKid', params: {id: data.item.id}}" class=''>
                        <h5>
                        {{ data.item.entity.name }}
                        </h5>
                    </router-link>
                    <div v-else>
                        {{ data.item.entity.name }}
                    </div>
                    <div class='badge badge-light'>
                        {{ data.item.entity.type }}
                    </div>
                </div>
            </template>

            <template v-slot:cell(date)='data'>
                {{ data.item.date | date }}                
            </template>

            <template v-slot:cell(cash_register)='data'>
                <div v-if='data.item.cash_register'>
                    {{ data.item.cash_register.name }}
                </div>
            </template>
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
	props: ['viewType'],
    components: { BaseList },
    extends: BaseListMethods,
    data() {
        return {
			fields: [
                'year',
                'month',
                'date',
                'cash_register',
                'entity',
                'total_in',
                'total_out',
                'total_balance_in',
                'total_balance_out',
			],
            statusBadges: {
                "in progress": "badge-warning",
                "finished": "badge-success",
                "pending": "badge-light",
            },
            currentFields: []
        }
    },
	methods: {
        statusBadge: function(status) {
            return this.statusBadges[status] ? this.statusBadges[status] : "badge-light";
        },
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('cash-payments', filter, cancelToken)
                .then(response => {
                    var firstResult = response.data[0];
                    if (firstResult) {
			            this.currentFields = this.fields.filter(item => firstResult[item] ? true : false);
                    }
                    return response;
                });
		},
        deleteEntitySaleable: function (entitySaleable) {
            return this.apiRequest(apiClient => apiClient.delete('entity-saleables/'+entitySaleable.id));
        }
    },
    watch: {
        
		// viewType: {
		// 	immediate: true,
		// 	handler: function () {
		// 	}
		// },
    }
}
</script>