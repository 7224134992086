<template>
    <div class="auth-form">
        <h3>{{ $t("hey") }}, {{ user.name }}</h3>
        <p>
            {{ $t("auth.password_reset_info") }}
        </p>
        <b-form class='basic-form' @submit="submit" novalidate>

            <div class="form-group">
                <label class="mb-1"><strong>{{ $t('auth.new_password') }}</strong></label>
                <input type="password" v-model='form.new_password' class="form-control" value="">
            </div>
            <div class="form-group">
                <label class="mb-1"><strong>{{ $t('auth.new_password_confirmation') }}</strong></label>
                <input type="password" v-model='form.new_password_confirmation' class="form-control" value="">
            </div>
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <div class="text-center">
                <button v-if='!this.saving' type="submit" class="btn btn-primary btn-block">{{ $t('auth.reset') }}</button>

                <b-button v-if='this.saving' variant="primary" class="btn btn-primary btn-block" :disabled='true'>
                    <b-spinner small type="grow"></b-spinner>
                    checking...
                </b-button>
            </div>

        </b-form>
        <div class="d-flex justify-content-center mb-4 mt-5">
            <locale-switcher />
        </div>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'

export default {
    extends: BaseForm,
    data() {
        return {
            user: null,
            form: {
            }
        };
    },
    created() {
        this.user = this.loadUser();
    },
    methods: {
        save: function (apiClient) {
            return apiClient.post('auth/reset-password', this.form);
        },
        afterSave: function (response) {
            if (response.status) {
                this.apiClient.user.needs_password_reset = 0;
                this.$router.push({ name: 'Main' });
                this.$emit('saved', 1);
            }

        }
    },
}
</script>