<template>
    <div>
        <loader v-if='loading' />
        <b-form v-if='!loading && !generatedPassword' class='basic-form' @submit="submit" novalidate>

            <div v-if="this.id && !user.email">
                <b-form-checkbox id="isActive" v-model="user.is_active" name="isActive" :value="1"
                    :unchecked-value="0">Active</b-form-checkbox>
                <FormGroupError :errors='errors.is_active' />
            </div>
            <b-form-group v-if="!user.email" label="Name">
                <b-form-input v-model="user.name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name' />
            </b-form-group>
            <b-form-group v-if="!user.email" label="User Name">
                <b-form-input v-model="user.username" :state="states.username" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.username' />
            </b-form-group>

            <b-form-group v-if="user.email" label="Email">
                <b>{{user.email}}</b>
            </b-form-group>
            <b-form-group label="Role">
                <RolesSelect v-model='role' />
                <FormGroupError :errors='errors.role_id' />
            </b-form-group>

            <b-form-group label="Password" v-if="id && !user.email">
                <b-form-input v-model="user.password" :state="states.password" :id="dynamicID()" type="password"
                    placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.password' />
            </b-form-group>

            <b-form-group label="Repeate Password" v-if="id && !user.email">
                <b-form-input v-model="user.password_confirmation" :state="states.password_confirmation" :id="dynamicID()"
                    type="password" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.password_confirmation' />
            </b-form-group>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
        <div v-else>
            <h3>User : {{ user.name }}</h3>
            <b-input-group prepend="Generated Password" class="mt-3">
                <b-form-input :value="generatedPassword" ref="generatedPassword" disabled></b-form-input>
                <b-input-group-append>
                    <b-button :variant="copied ? 'success' : 'primary'" @click="copyGeneratedPassword()">{{ copied ? '✔' :
                        'Copy' }}</b-button>
                </b-input-group-append>
            </b-input-group>
        </div>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    data: function () {
        return {
            generatedPassword: null,
            copied: false,
            user: {
            },
            settings: {},
            role: {},
            doctors: [],
            branch: {}
        }
    },
    methods: {

        load: function (id) {
            return this.apiRequest(apiClient => apiClient.get('users/' + id))
                .then(response => {
                    this.user = response;
                    this.settings = response.settings ? response.settings : {};
                    this.role = response.role;
                    this.doctors = response.doctors;
                    this.branch = response.branch;
                });
        },
        save: function (apiClient) {
            var user = { ...this.user };
            user.role_id = this.role ? this.role.id : null;
            user.doctor_ids = this.doctors ? this.doctors.map(item => item.id) : null;
            user.branch_id = this.branch ? this.branch.id : null;
            var settings = this.settings ? this.settings : null;

            if (settings && settings.leads_from_branches) {
                settings = {
                    ...settings,
                    leads_from_branches: settings.leads_from_branches.map(item => Number.isInteger(item) ? item : item.id).filter(x => x !== null)
                };
            }
            user = { ...user, settings: settings };
            if (this.id) {
                return apiClient.put('users/' + this.id, user);
            } else {
                return apiClient.post('users', user);
            }
        },
        afterSave: function (response) {
            if (!this.id) {
                this.user = response.user;
                this.generatedPassword = response.generated_password;
                this.$emit('saved', 0); // keep the modal visible to show the password
            } else {
                this.$emit('saved', 1); // hide the modal
            }
        },
        copyGeneratedPassword() {
            if (!navigator.clipboard) {
                // old deprecated way for old browsers
                const input = this.$refs.generatedPassword;
                input.select();
                input.setSelectionRange(0, 99999);
                if (document.execCommand('copy')) {
                    this.copied = true;
                    setTimeout(() => {
                        this.copied = false;
                    }, 2000);
                } else {
                    alert("Error can't copy data");
                }
            } else {
                // new way 
                navigator.clipboard.writeText(this.generatedPassword).then(() => {
                    this.copied = true;
                    setTimeout(() => {
                        this.copied = false;
                    }, 2000);
                })
                    .catch(
                        function () {
                            alert("Error can't copy data");
                        });
            }
        }
    },
}
</script>