<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Name">
                <b-form-input v-model="role.name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name'/>
            </b-form-group>
            
            <b-row>
                <b-col
                    v-for='(permissionGroup, index) in permissions'
                    :key='index'
                    cols='3'
                    class='m-3'
                >

                    <b-form-group>

                        <template #label>
                            <b-form-checkbox
                                :value='permissionGroup.title'
                                :indeterminate="groupsStatus[permissionGroup.title] == 'intermedite' ? true : false"
                                :checked="groupsStatus[permissionGroup.title] == 'selected' ? permissionGroup.title : ''"
                                @change="toggle(permissionGroup)"
                                size='lg'
                            >
                            <b>{{ permissionGroup.title }}</b>
                            </b-form-checkbox>
                        </template>
                        <b-form-checkbox-group
                            v-model="selectedPermissions"
                            :options="permissionGroup.permissions"
                            stacked
                            size="lg"
                        ></b-form-checkbox-group>
                    </b-form-group>
                </b-col>
                
            </b-row>
            
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    data: function () {
        return {
            role: {
            },
            permissions: [],
            selectedPermissions: []
        }
    },
    mounted() {
        this.apiRequest(apiClient => apiClient.get('permissions'))
            .then(permissions => this.permissions = permissions);

    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('roles/'+id))
                .then(response => {
                    this.role = response;
                    this.selectedPermissions = this.role.permissions;
                });
        },
        save: function (apiClient) {
            if (this.id) {
                return apiClient.put('roles/'+this.id, {
                    name: this.role.name,
                    permissions: this.selectedPermissions
                });
            } else {
                return apiClient.post('roles', {
                    name: this.role.name,
                    permissions: this.selectedPermissions
                });
            }
        },
        afterSave: function () {
            window.location.reload();
            this.$emit('saved', 1);
        },
        toggle(permissionGroup) {
            var selectedPermissions = this.selectedPermissions;
            if (this.groupsStatus[permissionGroup.title] == 'selected') {
                permissionGroup.permissions.map(permission => permission.value).forEach(item => {
                    var index = selectedPermissions.indexOf(item);
                    if (index !== -1) {
                        selectedPermissions.splice(index, 1);
                    }
                });
            } else {
                permissionGroup.permissions.map(permission => permission.value).forEach(item => {
                    selectedPermissions.indexOf(item) === -1 ? selectedPermissions.push(item) : '';
                });
            }

            this.selectedPermissions = selectedPermissions;
        }
    },
    computed: {
        groupsStatus() {
            var groupsStatus = {};
            
            this.permissions.forEach(permissionGroup => {
                var status = permissionGroup.permissions.reduce((preStatus, current) => {
                    if (this.selectedPermissions.indexOf(current.value) !== -1) {
                        if (preStatus == 'unknown') {
                            preStatus = 'selected';
                        } else if (preStatus == 'unselected') {
                            preStatus = 'intermedite';
                        }
                    } else {
                        if (preStatus == 'selected') {
                            preStatus = 'intermedite';
                        } else if (preStatus == 'unknown') {
                            preStatus = 'unselected';
                        }
                    } 
                    return preStatus;
                }, 'unknown');
                
                groupsStatus[permissionGroup.title] = status;
            });

            return groupsStatus;
        }
    }
}
</script>