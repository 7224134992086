<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='currentFields' :modalObject='currentModalObject' @pageChanged='pageChanged'>
            
            <template v-slot:cell(entity)='data'>
                <div>
                    {{ data.item.entity.name }}
                </div>
            </template>
			<template v-slot:cell(actions)='data'>
				<button class='btn btn-xs btn-primary m-2' @click="modal('entityNoteForm', {id: data.item.id}, 'refresh')">Edit</button>
				<button class='btn btn-xs btn-danger m-2' @click="confirmAction('deleteEntityNote', data.item, 'refresh')">Delete</button>
            </template>
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
	props: ['viewType'],
    components: { BaseList },
    extends: BaseListMethods,
    data() {
        return {
			fields: [
                // { key: 'id', label: 'ID' },
                { key: 'entity', label: 'Entity' },
                { key: 'note', label: 'Note' },
                { key: 'actions', label: 'Actions' },
			]
        }
    },
	methods: {
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('entity-notes', filter, cancelToken);
		},
        deleteEntityNote: function (entityNote) {
            return this.apiRequest(apiClient => apiClient.delete('entity-notes/'+entityNote.id));
        }
    },
    watch: {

		// viewType: {
		// 	immediate: true,
		// 	handler: function () {
		// 	}
		// },
    },
    computed: {
        currentFields: function () {
            return this.viewType == 'entity' ? this.fields.filter(item => item.key == 'entity' ? false : true) : this.fields;
        }
    }
}
</script>