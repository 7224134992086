<template>
    <div >        
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject' @pageChanged='pageChanged'>
            
            
            <template v-slot:cell(saleables)='data'>
                <div v-for='(saleableItem, index) in data.item.saleable_items' :key='index'>
                    <div v-if='saleableItem.saleable && saleableItem.saleable.is_active'>

                        <div v-if='saleableItem.saleable'>

                            {{ saleableItem.saleable.name }}

                            <div v-if='saleableItem.saleable.type == "sale"' class='badge badge-success m-2'>
                                sale: {{ saleableItem.saleable.price | currency }}
                            </div>
                            
                            <div v-if='saleableItem.saleable.type == "purchase"' class='badge badge-warning m-2'>
                                purchase: {{ saleableItem.saleable.price  | currency }}
                            </div>
                            <div v-if='saleableItem.saleable.is_active' class="badge badge-success m-2">
                                Active
                            </div>
                            <div v-else class='badge badge-light m-2'>
                                inactive
                            </div>
                        </div>

                        <div class='badge badge-info m-2'>
                            {{ saleableItem.quantity }} 
                            {{ saleableItem.quantity | pluralize('item', 'items')}}
                        </div>
                        <div class='badge badge-info m-2' v-if='saleableItem.period'>
                            {{ saleableItem.period }}
                        </div>
                    </div>
                </div>
            </template>
			<template v-slot:cell(actions)='data'>
				<button v-if='can("services_products.edit")' class='btn btn-xs btn-primary m-2' @click="modal('itemForm', {id: data.item.id}, 'refresh')">Edit</button>
				<button v-if='can("services_products.delete")' class='btn btn-xs btn-danger m-2' @click="confirmAction('deleteItem', data.item, 'refresh')">x</button>
            </template>
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
	props: ['viewType'],
    components: { BaseList },
    extends: BaseListMethods,
    data() {
        return {
			fields: [
                { key: 'id', label: 'ID' },
                { key: 'name', label: 'Name' },
                { key: 'type', label: 'Type' },
                { key: 'sold_to', label: 'Sold To' },
                { key: 'saleables', label: 'Saleables' },
                { key: 'actions', label: 'Actions' },
			]
        }
    },
	methods: {
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('items', filter, cancelToken);
		},
        deleteItem: function (item) {
            return this.apiRequest(apiClient => apiClient.delete('items/'+item.id));
        },
    },
    watch: {

		// viewType: {
		// 	immediate: true,
		// 	handler: function () {
		// 	}
		// },
    }
}
</script>