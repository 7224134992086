    <template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject' @pageChanged='pageChanged'>
            
			<template v-slot:cell(date)='data'>
                {{ data.item.date | date }}
            </template>
            
			<template v-slot:cell(payments)='data'>
                <div class='badge badge-success'>
                    total paid: {{ data.item.amount_paid }}
                </div>
                <div class='badge badge-danger' v-if='data.item.amount_remaining > 0'>
                    total remaining: {{ data.item.amount_remaining }}
                </div>
                <div v-for='(cashPaymentPart, index) in data.item.cash_payment_parts' :key='index'>
                    <div class='badge badge-success'>
                        {{ cashPaymentPart.amount | currency}}
                    </div>
                    <div class='badge badge-light ml-2'>
                        {{ cashPaymentPart.cash_payment.date | date }}
                    </div>
                </div>
            </template>
            
            
			<template v-slot:cell(entity)='data'>
                {{ data.item.entity.name }}
                <div class='badge badge-light'>
                    {{ data.item.entity.type }}
                </div>
            </template>
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
	props: ['viewType'],
    components: { BaseList },
    extends: BaseListMethods,
    data() {
        return {
			fields: [
                { key: 'id', label: 'ID' },
                { key: 'date', label: 'Date' },
                { key: 'title', label: 'Title' },
                { key: 'entity', label: 'Entity' },
                { key: 'status', label: 'Status' },
                { key: 'amount', label: 'Amount' },
                { key: 'type', label: 'Type' },
                { key: 'payments', label: 'Payments' },
			]
        }
    },
	methods: {
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('transactions', filter, cancelToken);
		}
    },
    watch: {

		// viewType: {
		// 	immediate: true,
		// 	handler: function () {
		// 	}
		// },
    }
}
</script>