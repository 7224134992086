<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject' @pageChanged='pageChanged'>
            
            <template v-slot:cell(name)='data'>
                
            	<router-link :to="{ name: 'ViewKid', params: {id: data.item.id}}" class=''>
					<h5>
					{{ data.item.name }}
					</h5>
                    <div class="alert-danger mt-2 pl-2" v-if='data.item.entity.unpaid_transactions.length > 0'>
                        <i class='fa fa-credit-card'></i>
                        !! has unpaid items
                    </div>
                    <div class="alert-danger mt-2 pl-2" v-if='data.item.entity.unsubscribed_entity_items.length > 0'>
                        <i class='fa fa-truck-loading'></i>
                        !! delivered without sale
                    </div>
				</router-link>

                <div v-if="!data.item.entity.is_active" class='badge badge-light'>inactive</div>
            </template>
			<template v-slot:cell(birth_date)='data'>
                <div class='badge badge-light'>
                    <i class='fa fa-calendar'></i>
                    {{ data.item.birth_date }}
                </div>
            </template>

            <template v-slot:cell(month_status)='data'>
                <div v-if='data.item.items_quantity'>
                    <div v-for='(item, index) in data.item.items_quantity' :key='index'>
                        <div class='badge badge-success'>
                            {{ item.item.name }}
                        </div>
                        <Progress style='width:100px;' v-if='item.used_quantity || item.available_quantity' :used="item.used_quantity" :total="item.used_quantity + item.available_quantity"/>
                        
                        <Progress style='width:100px; margin-top:5px;' v-if='item.over_used_quantity' :used="item.over_used_quantity" :total="0"/>
                    </div>
                </div>
            </template>

			<template v-slot:cell(entity_items)='data'>

                <div v-for="(entityItem, index) in data.item.entity.entity_items" :key='index'>
                    <div class='badge badge-success'>
                        {{ entityItem.item.name }}
                    </div>
                    <div class='badge badge-success ml-1'>
                        <i class='fa fa-check'></i>
                        {{ entityItem.quantity }}
                    </div>
                </div>
            </template>

			<template v-slot:cell(phone_numbers)='data'>
                <div v-for='(entityPhone, index) in data.item.entity.entity_phones' :key='index'>
                    <div class='badge badge-success'>
                        {{ entityPhone.name }}:
                        {{ entityPhone.number }}
                    </div>
                    <div class='badge badge-success ml-2' v-if='entityPhone.id == data.item.entity.primary_phone_id'>
                        primary phone
                    </div>
                </div>
            </template>

			<template v-slot:cell(class_room)='data'>
                <div v-if='data.item.class_room'>
                    Class:
                    {{ data.item.class_room.name }}
                </div>
            </template>

			<template v-slot:cell(balance)='data'>
                <div v-for='(item, index) in data.item.entity.transactions_totals' :key='index'>
                    <div class='badge badge-light m-1'>
                        {{ item.transaction_type }}
                    </div>
                    <div class='badge badge-light m-1'>
                        {{ item.status }}
                    </div>
                    <div class="badge m-1 badge-success" v-if='item.transaction_type == "cash in" || item.type == "cash out"'>
                        {{ item.total }}
                    </div>
                    <div class='badge m-1' v-else :class="item.paid == item.total ? 'badge-success' : 'badge-danger'" title='paid/total'>
                        {{ item.paid }} /
                        {{ item.total }}
                    </div>
                    <div class='badge badge-light m-1' v-if='item.remaining > 0'>
                        remaining:
                        {{ item.remaining }}
                    </div>
                </div>
            </template>
			<template v-slot:cell(actions)='data'>
				<button v-if='can("kids.edit")'  class='btn btn-xs btn-primary m-2' @click="modal('kidForm', {id: data.item.id}, 'refresh')">Edit</button>
				<button v-if='false && can("kids.edit")'  class='btn btn-xs btn-primary m-2' @click="modal('kidApplicationForm', {id: data.item.entity_id}, 'refresh')">Edit Application</button>
            </template>
        </base-list>
    </div>
</template>

<script>
import Progress from '../Progress.vue';
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
	props: ['viewType'],
    components: { BaseList, Progress },
    extends: BaseListMethods,
    data() {
        return {
			fields: [
                { key: 'id', label: 'ID' },
                { key: 'name', label: 'Name' },
                { key: 'class_room', label: 'Class Room' },
                { key: 'entity_items', label: 'Delivered this day' },
                { key: 'month_status', label: 'Month Delivery' },
                // { key: 'balance', label: 'Balance' },
                // { key: 'phone_numbers', label: 'Phone Numbers' },
                { key: 'actions', label: 'Actions' },
			]
        }
    },
	methods: {
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('kids', filter, cancelToken);
		}
    },
    watch: {

		// viewType: {
		// 	immediate: true,
		// 	handler: function () {
		// 	}
		// },
    }
}
</script>