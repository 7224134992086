<template>
    <span class="p-container" :class="inline?'inline':''">
        <span class='part used' :class='(total - used) < 0 ? "danger" : ""' :style='usedStyle'>{{ parseFloat(used) }}</span>        
        <span class='part remaining' v-if='(total - used) > 0' :style='remainingStyle'>{{ total - used }}</span>        
    </span>
</template>

<script>
export default {
    props: ['used', 'total', 'inline'],
    computed: {
        usedStyle: function () {
            if ((this.total - this.used) < 0) {
                return "width: 100%";
            }
            var w = (parseFloat(this.used)/parseFloat(this.total)) * 100;
            return "width: "+w+"%";
        },
        remainingStyle: function () {
            var wUsed = (parseFloat(this.used)/parseFloat(this.total)) * 100;
            var w = 100 - wUsed;
            return "width: "+w+"%";
        }
    }
}
</script>

<style >
.p-container {
     display: flex;
    border-radius: 8px;
    overflow: hidden;
    line-height: 12px;
}
.p-container.inline {
    display: inline-flex;
}
.p-container .part {
    display: inline-block;
    padding:2px 10px;
    text-align: center;
    color: #222;
    font-size: 10px;
}
.p-container .used {
    background-color: rgb(150 214 145);
}

.p-container .danger {
    background-color: rgb(215 57 57);
    color: #fefefe;
}

.p-container .remaining {
    background-color: rgb(216 216 216);
}
</style>