<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject' @pageChanged='pageChanged'>
            
			<template v-slot:cell(saleable_items)='data'>
                <div v-for='(saleableItem, index) in data.item.saleable_items' :key='index'>
                    {{ saleableItem.quantity }} x {{ saleableItem.item.name }}
                </div>
            </template>
			<template v-slot:cell(saleable_group)='data'>
                <div v-if='data.item.saleable_group' class='badge badge-success'>
                    {{ data.item.saleable_group.name }}
                </div>
            </template>
			<template v-slot:cell(status)='data'>
                <div v-if='data.item.is_active' class='badge badge-success'>
                    active
                </div>
                <div v-else class='badge badge-light'>
                    inactive
                </div>
            </template>
			<template v-slot:cell(actions)='data'>
				<button v-if='can("saleables.edit")' class='btn btn-xs btn-primary m-2' @click="modal('saleableForm', {id: data.item.id}, 'refresh')">Edit</button>
            </template>
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
	props: ['viewType'],
    components: { BaseList },
    extends: BaseListMethods,
    data() {
        return {
			fields: [
                { key: 'id', label: 'ID' },
                { key: 'name', label: 'Name' },
                { key: 'saleable_group', label: 'Group' },
                { key: 'saleable_items', label: 'Items' },
                { key: 'type', label: 'Type' },
                { key: 'status', label: 'Status' },
                { key: 'price', label: 'Price' },
                { key: 'actions', label: 'Actions' },
			]
        }
    },
	methods: {
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('saleables', filter, cancelToken);
		}
    },
    watch: {

		// viewType: {
		// 	immediate: true,
		// 	handler: function () {
		// 	}
		// },
    }
}
</script>