<template>
    <div>

        <ListView title="Subscriptions List" :collection="collection" :fields="fields" @pageChanged='pageChanged'
            :loading="loading">

            <template v-slot:filter>
                <b-form>
                    <b-row>
                        <b-col cols='3'>
                            <b-form-group label="Date Range">
                                <my-date-range-picker v-model='dateRange'
                                    :style='{ display: "inline-block", "vertical-align": "middle" }' />
                                <div class='btn btn-danger btn-xxs ml-2'
                                    @click='() => dateRange = { startDate: null, endDate: null }'>x</div>
                            </b-form-group>
                        </b-col>
                        <b-col cols='3'>
                            <b-form-group label='entity'>
                                <entities-select v-model='filter.entity_id' :returnId='true' />
                            </b-form-group>
                        </b-col>
                        <b-col cols=3 v-if="!entityId">
                            <b-form-group label="Class">
                                <class-rooms-select v-model='filter.class_room_id' :returnId='true' />
                            </b-form-group>
                        </b-col>
                        <b-col cols='3'>
                            <b-form-group label='Saleables'>
                                <saleables-select v-model='filter.saleable_id' :returnId='true' />
                            </b-form-group>
                        </b-col>

                        <b-col cols='3'>
                            <b-form-group label='Status'>
                                <v-select v-model='filter.status' :options="['active', 'cancelled']" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </template>

            <template v-slot:cell(next_billing_date)='data'>
                {{ data.item.status == 'active' ? data.item.next_billing_date : '' }}
            </template>


            <template v-slot:cell(entity)='data'>
                <router-link v-if='data.item.entity.type == "kid"'
                    :to="{ name: 'ViewKid', params: { id: data.item.entity.kid.id } }" class=''>
                    <h5>
                        {{ data.item.entity.name }}
                    </h5>
                </router-link>
                <div v-else>
                    <router-link :to="{ name: 'ViewEntity', params: { id: data.item.entity.id } }" class=''>
                        <h5>
                            {{ data.item.entity.name }}
                        </h5>
                    </router-link>
                </div>

            </template>
            <template v-slot:cell(status)='data'>
                <span class="badge"
                    :class="data.item.status == 'active' ? 'badge-success' : (data.item.status == 'cancelled' ? 'badge-warning' : 'badge-info')">
                    {{ data.item.status }}
                </span>
            </template>

            <template v-slot:cell(actions)='data'>
                <b-button v-if='can("subscriptions.edit")' v-b-modal.subscription-modal class='mr-2'
                    @click="selectedSubscriptionId = data.item.id">Edit</b-button>
                <button v-if='can("subscriptions.cancel") && data.item.status == "active"' class='btn btn-xs btn-danger m-2'
                    @click="confirmAction('cancelSubscription', data.item, 'refresh')">Cancel</button>
                <button v-if='can("subscriptions.edit") && data.item.status == "cancelled"'
                    class='btn btn-xs btn-success m-2'
                    @click="confirmAction('activeSubscription', data.item, 'refresh')">Activate</button>
                <button v-if='can("subscriptions.delete")' class='btn btn-xs btn-danger m-2'
                    @click="confirmAction('deleteSubscription', data.item, 'refresh')">Delete</button>
            </template>

        </ListView>

        <b-modal :no-close-on-backdrop=true id="subscription-modal" hide-footer title="Subscription Form">
            <SubscriptionForm @saved='subscriptionSaved' :id='selectedSubscriptionId'></SubscriptionForm>
        </b-modal>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import SubscriptionForm from './SubscriptionForm';
import EntitiesSelect from '../../components/Select/EntitiesSelect.vue';
import SaleablesSelect from '../../components/Select/SaleablesSelect.vue';
import ClassRoomsSelect from '../../components/Select/ClassRoomsSelect.vue';
export default {
    extends: BaseList,
    props: ['entityId'],
    components: { SubscriptionForm, EntitiesSelect, SaleablesSelect, ClassRoomsSelect },
    methods: {
        load: function (apiClient, cancelToken) {
            return apiClient.get('subscriptions', this.filter, cancelToken);
        },
        subscriptionSaved() {
            this.refresh();
            this.$bvModal.hide('subscription-modal');
        },
        activeSubscription: function (subscription) {
            return this.apiRequest(apiClient => apiClient.put('subscriptions/active/' + subscription.id));
        },
        deleteSubscription: function (subscription) {
            return this.apiRequest(apiClient => apiClient.delete('subscriptions/' + subscription.id));
        },
        cancelSubscription: function (subscription) {
            return this.apiRequest(apiClient => apiClient.put('subscriptions/cancel/' + subscription.id));
        }
    },
    data: function () {
        return {
            defaultFilter: {
                status: 'active'
            },
            fields: [
                { key: 'id', label: '#' },
                { key: 'entity', label: 'Entity Name' },
                { key: 'entity.kid.classroom.name', label: 'Class' },
                { key: 'saleable.name', label: 'Saleable' },
                { key: 'start_at', label: 'Start At' },
                { key: 'next_billing_date', label: 'Next Payment' },
                { key: 'status', label: 'Status' },
                { key: 'actions', label: '' }
            ],
            selectedSubscriptionId: null
        };
    },
    watch: {
        entityId: {
            immediate: true,
            handler:
                function () {
                    this.filter.entity_id = this.entityId;
                }
        }
    }
}
</script>
