<template>
  
    <div class="authincation h-100">
        <div class="container h-100">
            <div class="row justify-content-center h-100 align-items-center">
                <div class="col-md-6">
                    <div class="authincation-content">
                        <div class="row no-gutters">
                            <div class="col-xl-12">
                                <slot/>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


  
</template>
<script>
export default {
  
}
</script>

<style>
.recentOrderTable table tbody td{
  line-height: 30px;
}

.badge {
  font-weight: 300;
  font-size: 85%;
}

.tab-nav-menu li a i {
  transform: scale(1.0);
}

</style>