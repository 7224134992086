<template>
    <div>
        <ListView title="Items Delivered" :modalObject='currentModalObject' >
            <template v-slot:actions>
                <b-button @click="modal('itemsDeliveryForm', {entityType: 'kid', entityIds: entityId ? [entityId] : null}, 'refreshTable')" variant='primary' class='mr-2'>
                    Deliver Items
                </b-button>

            </template>

            <template v-slot:filter>
                <b-row>
                    <b-col cols='3'>
                        <b-form-group label="Date Range">
                            <my-date-range-picker v-model='dateRange' :style='{display:"inline-block", "vertical-align": "middle"}'/>
                            <div class='btn btn-danger btn-xxs ml-2' @click='() => dateRange = {startDate: null, endDate: null}'>x</div>
                        </b-form-group>
                    </b-col>
                </b-row>
                <div class='form'>
                    <b-row>
                        <b-col cols='3'>
                            <b-form-group label='Item'>
                                <items-select v-model='filter.item_id' :returnId='true'/>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-form-group>
                        <b-form-checkbox v-model='filter.not_subscriped' value='1' unchecked-value="0" :inline='true'>
                            Without Sale
                        </b-form-checkbox>
                    </b-form-group>
                    <b-form-group>
                        <b-form-checkbox
                            v-model="filter.summary"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Summary
                        </b-form-checkbox>
                    </b-form-group>
                    <b-form-group label="Summary Options" v-if='filter.summary == 1'>
                        <b-form-checkbox
                            v-model="filter.group_by_sale"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Group By Sale
                        </b-form-checkbox>
                        <b-form-checkbox
                            v-model="filter.group_by_item_id"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Group By Item
                        </b-form-checkbox>
                        <b-form-checkbox
                            v-model="filter.group_by_direction"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Group By Direction
                        </b-form-checkbox>
                        <b-form-checkbox
                            v-model="filter.group_by_entity_id"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Group By Entity
                        </b-form-checkbox>

                        <b-form-group label="Group By">
                            <b-form-radio-group
                                v-model="filter.group_by_period"
                                :options="['day', 'month', 'year', 'none']"
                            ></b-form-radio-group>
                        </b-form-group>

                    </b-form-group>
                </div>
            </template>

			<template v-slot:table>
				<entity-items-summary-list-table v-if='filter.summary == "1"' :filter='filter' ref='tableList' :viewType='viewType'></entity-items-summary-list-table>
				<EntityItemsListTable v-else :filter='filter' ref='tableList' :viewType='viewType'></EntityItemsListTable>
			</template>
                    
        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import EntityItemsListTable from '@/components/Lists/EntityItemsListTable';
import EntityItemsSummaryListTable from '../../components/Lists/EntityItemsSummaryListTable.vue';
import ItemsSelect from '../../components/Select/ItemsSelect.vue';

export default {
    extends: BaseList,
    components: {EntityItemsListTable, EntityItemsSummaryListTable, ItemsSelect},
    props: ['entityId'],
    methods: {
        refreshTable () {
            this.$refs.tableList.refresh();
        }
    },
    watch: {
        entityId: {
            immediate: true,
            handler:
                function () {
                    this.filter.entity_id = this.entityId;
                    if (this.entityId) {
                        this.viewType = 'entity';
                    }
                }
        }
    }
}
</script>
