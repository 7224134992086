<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Date">
                <b-form-datepicker v-model="date" :state="states.date"/>
                <FormGroupError :errors='errors.date'/>
            </b-form-group>
            <b-form-group label="Direction">
                <v-select v-model='direction' :options='["in", "out"]'/>
            </b-form-group>
            
            <b-form-group label="Item">
                <items-select v-model="items" multiple/>
            </b-form-group>

            <b-form-group label="Status">
                <b-form-radio-group
                    v-model="filter.status"
                    :options="['all', 'active', 'inactive']"
                ></b-form-radio-group>
            </b-form-group>
            
            <b-form-checkbox
                v-model="filter.deliver_without_sale"
                value="1"
                unchecked-value="0"
            >
                Deliver Items Without Sale
            </b-form-checkbox>
            <b-form-group v-if='entityType == "kid"' label=''>
                <class-rooms-select v-model='filter.class_id' :returnId='true'/>
            </b-form-group>
            <b-row class='m-2'>
                <b-col cols='10'>
                </b-col>
                <b-col>
                    <b-form-checkbox
                        @change='toggle'
                        size='lg'
                    >
                    </b-form-checkbox>
                </b-col>
                <b-col>
                    Quantity
                </b-col>
            </b-row>
            <div v-for="(currentEntityItems, index) in entityItems" :key='index'>
                
                <div v-for="(entityItem, index2) in currentEntityItems" :key='index2'>
                    <b-row class='m-2'>
                        <b-col>
                            <b v-if='index2 == 0'>
                                {{ entityItem.entity.name }}
                            </b>
                        </b-col>
                        <b-col cols=8>
                            <b-row class='m-2'>
                                <b-col cols=2>
                                    <div class='badge badge-success'>
                                        {{ entityItem.item.name }}
                                    </div>
                                </b-col>
                                <b-col cols=8>
                                    <span v-if='entityItem.entity_saleable_item'>
                                        <div class='badge badge-light ml-2'>
                                            {{ entityItem.entity_saleable_item.entity_saleable.quantity }}
                                            X
                                            {{ entityItem.entity_saleable_item.entity_saleable.description }}
                                        </div>

                                        <div class='badge' :class="statusBadge(entityItem.entity_saleable_item.status)">
                                            {{ entityItem.entity_saleable_item.status }}
                                        </div>
                                        <div class='badge badge ml-2'  :class="statusBadge(entityItem.entity_saleable_item.status)" title='used/remaining'>

                                            {{ entityItem.entity_saleable_item.used_quantity }}
                                            /
                                            {{ entityItem.entity_saleable_item.total_quantity }}
                                        </div>
                                        <div class='m-2'>
                                            <div v-if='entityItem.entity_saleable_item.start_date' class="badge badge-success ml-2">
                                                from
                                                <i class='fa fa-calendar'></i>
                                                {{ entityItem.entity_saleable_item.start_date }}
                                            </div>
                                            <div v-if='entityItem.entity_saleable_item.end_date' class="badge badge-success ml-2">
                                                to
                                                <i class='fa fa-calendar'></i>
                                                {{ entityItem.entity_saleable_item.end_date }}
                                            </div>
                                        </div>
                                    </span>
                                </b-col>
                                <b-col>
                                    <div v-if='entityItem.quantity > 0 && selected.includes(entityItem.key)'>
                                        <i class='fa fa-check'></i>
                                        {{ entityItem.quantity }}
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col>
                            <div>
                                <b-row>
                                    <b-col cols='1'>

                                        <b-form-checkbox
                                            :value='entityItem.key'
                                            v-model='selected'
                                            size='lg'
                                        >
                                        </b-form-checkbox>
                                    </b-col>
                                    <b-col cols=8>
                                        <b-form-spinbutton v-model="entityItem.quantity" :disabled='selected.includes(entityItem.key) ? false : true' min="0" :max='entityItem.max_quantity' inline size='sm'></b-form-spinbutton>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </div>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
import ItemsSelect from '../../components/Select/ItemsSelect.vue';
import ClassRoomsSelect from '../../components/Select/ClassRoomsSelect.vue';
export default {
  components: { ItemsSelect, ClassRoomsSelect },
    extends: BaseForm,
    props: ['entityType', 'entityIds', 'classRoomId'],
    data: function () {
        return {
            items: [],
            filter: {},
            date: this.today(),
            direction: 'out',
            entityItems: [],
            selected: [],
            statusBadges: {
                "in progress": "badge-warning",
                "finished": "badge-success",
                "pending": "badge-light",
            }
        }
    },
    mounted: function () {
        this.filter = {type: this.entityType, class_id: this.classRoomId, status: 'active', deliver_without_sale: 1};
        this.loadEntities();
    },
    methods: {
        toggle: function (checked) {
            var selected = [];
            if (checked) {
                this.entityItems.forEach(currentEntityItems => {
                    for (let item of currentEntityItems) {
                        selected.push(item.key);
                        break;
                    }
                });
            }
            this.selected = selected;
        },
        save: function (apiClient) {
            if (this.id) {
                return apiClient.put('entity-items/'+this.id, this.entityItem);
            } else {
                var entityItems = this.entityItems.flat().map(item => ({
                    item_type: item.item_type,
                    item_id: item.item_id,
                    entity_id: item.entity.id,
                    entity_saleable_item_id: item.entity_saleable_item ? item.entity_saleable_item.id : null,
                    quantity: this.selected.includes(item.key) ? item.quantity : 0,
                }));
                return apiClient.post(
                    'entity-items/create-many', {
                        date: this.date, 
                        direction: this.direction,
                        entity_items: entityItems
                    }
                );
            }
        },
        statusBadge: function(status) {
            return this.statusBadges[status] ? this.statusBadges[status] : "badge-light";
        },
        afterSave: function () {
            this.$emit('saved', 1);
        },
        loadEntities: function () {
            this.entityItems = [];
            
            this.apiRequest(
                apiClient => apiClient.get('entities', {...this.filter, in_ids: this.entityIds, entity_saleable_items: {hide_consumed: 1, date: this.date, direction: this.direction, item_ids: this.items.map(item => item.id)}})
                    .then(
                        response => {
                            this.selected = [];
                            var x = [];
                            response.data.forEach(entity => {
                                var foundItemIds = [];
                                
                                x[entity.id] = entity.entity_saleable_items.map(
                                    saleableItem => {

                                        var entityItem = entity.entity_items.find(item => {
                                            if (item.entity_saleable_item_id == saleableItem.id) {
                                                return true;
                                            }
                                        });

                                        var maxQuantity = saleableItem.remaining_quantity + (entityItem ? parseInt(entityItem.quantity) : 0);

                                        if (saleableItem.item.max_amount_per_day && maxQuantity > saleableItem.item.max_amount_per_day) {
                                            maxQuantity = saleableItem.item.max_amount_per_day;
                                        }

                                        foundItemIds.push(saleableItem.item.id);


                                        var r = {
                                            item: saleableItem.item,
                                            item_type: saleableItem.item_type,
                                            item_id: saleableItem.item_id,
                                            entity: entity,
                                            quantity: entityItem ? parseInt(entityItem.quantity) : 0,
                                            entity_saleable_item: saleableItem,
                                            max_quantity: maxQuantity,
                                            key: saleableItem.item_id+'-'+entity.id+'-'+saleableItem.id
                                        };

                                        if (r.quantity > 0) {
                                            this.selected.push(r.key);
                                        } else {
                                            r.quantity = 1;
                                        }
                                        
                                        return r;
                                    }
                                );
                                if (entity.entity_items) {
                                    entity.entity_items.forEach(entityItem => {
    
                                        foundItemIds.push(entityItem.item.id);
                                        if (entityItem.entity_saleable_item_id) {
                                            return;
                                        }

                                        var r = {
                                            item: entityItem.item,
                                            item_type: entityItem.item.type,
                                            item_id: entityItem.item.id,
                                            entity: entity,
                                            quantity: entityItem ? parseInt(entityItem.quantity) : 0,
                                            entity_saleable_item: null,
                                            max_quantity: entityItem.item.max_amount_per_day ? entityItem.item.max_amount_per_day  : null,
                                            key: entityItem.item.id+'-'+entity.id
                                        };

                                        if (r.quantity > 0) {
                                            this.selected.push(r.key);
                                        } else {
                                            r.quantity = 1;
                                        }

                                        x[entity.id].push(r);
                                    });
                                }

                                this.items.forEach(item => {
                                    if (item.deliver_without_sale && !foundItemIds.includes(item.id)) {
                                        x[entity.id].push({
                                            item: item,
                                            item_type: item.type,
                                            item_id: item.id,
                                            entity: entity,
                                            quantity: 1,
                                            entity_saleable_item: null,
                                            max_quantity: item.max_amount_per_day ? item.max_amount_per_day  : null,
                                            key: item.id+'-'+entity.id
                                        });
                                    }
                                });
                            });
                            this.entityItems = x;
                        }
                    )
            )
        }
    },

    watch: {
        items: function () {
            this.loadEntities();
        },
        date: function () {
            this.loadEntities();
        },
        direction: function () {
            this.loadEntities();
        },
        hideConsumed: function () {
            this.loadEntities();
        },
        filter: {
            deep: true,
            handler: function () {
                this.loadEntities();
            }
        },
        classRoomId: {
            immediate: true,
            handler: function () {
                this.filter.class_id = this.classRoomId;
            }
        }
    }
}
</script>