<script>

export default {
    props:['pushHistory'],
    mounted: function () {
        this.refresh();
    },
    data: function() {
        return {
            loading: false, 
            viewType: null,
            collection: {},
            filter: {page: 1},
            defaultFilter: {page: 1},
            dateRange: {},
            cancelTokenSource: null
        };
    },
    methods: {
        refresh: function () {
            if (!this['load']) {
                return;
            }
            this.loading = true;
            
            this.apiRequest((apiCLient, cancelTokenSource) => {
                this.cancelTokenSource = cancelTokenSource
                return this.load(apiCLient, cancelTokenSource);
            }, this.cancelTokenSource)
                .then((response) => {
                    if (response) {
                        this.collection = response;
                        this.loading = false;
                    }
                });

        },
        pageChanged(page) {
            this.filter = {...this.filter, page: page};
        },
        confirmAction(methodName, data) {
            this.confirm().then(
                accept => {
                    if (accept) {
                        if (typeof methodName === 'string' || methodName instanceof String) {
                            this[methodName](data).then(() => this.refresh());
                        } else {
                            methodName.call(data).then(() => this.refresh());
                        }
                    }
                }
            );
        }
    },
    watch: {

		dateRange: function () {
			var startDate = this.dateRange.startDate ? this.toDate(this.dateRange.startDate) : null;
			var endDate = this.dateRange.endDate ? this.toDate(this.dateRange.endDate) : null; 
            
            
			if (startDate == this.filter.start_date && endDate == this.filter.end_date) {
				return;
			} 
			
			this.filter = {
				... this.filter,
				start_date: startDate,
				end_date: endDate
			};
		},
        filter: {
            handler() {
				var filter = this.filter;
                this.dateRange = {
                    startDate: filter.start_date ? filter.start_date : null,
                    endDate: filter.end_date ? filter.end_date : null
                }; 

                this.refresh();
				if (this.pushHistory) {
                    if (JSON.stringify(this.filter) == JSON.stringify(this.defaultFilter)) {
                        this.pushQuery({});
                    } else {
                        this.pushQuery(this.filter);
                    }
				}
            },
            deep: true
        },
        $route:{
            immediate: true,
            deep: true,
            handler: function (){
                var isEmpty = Object.keys(this.$route.query).length === 0 ? true : false ;
                if (isEmpty) {
                    this.filter = {...this.defaultFilter};
                } else {
                    this.filter = {...this.$route.query};
                }
            }
        }
    }
}
</script>
