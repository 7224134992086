<template>
    <div>
        <ListView title="ClassRooms List" :modalObject='currentModalObject' >
            <template v-slot:actions>
                <b-button @click="modal('classRoomForm', {}, 'refreshTable')" variant='primary' class='mr-2'>
                    Create ClassRoom
                </b-button>

            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>
                    </div>

                    <b-form-group label="Status">
                        <b-form-radio-group
                            v-model="filter.status"
                            :options="['all', 'active', 'inactive']"
                        ></b-form-radio-group>
                    </b-form-group>
                </div>
            </template>

			<template v-slot:table>
				<ClassRoomsListTable :filter='filter' ref='tableList' :viewType='viewType'></ClassRoomsListTable>
			</template>
                    
        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import ClassRoomsListTable from '@/components/Lists/ClassRoomsListTable';

export default {
    extends: BaseList,
    components: {ClassRoomsListTable},
    data: function () {
        return {
            defaultFilter: {
                status: 'all'
            }
        }
    },
    methods: {
        refreshTable () {
            this.$refs.tableList.refresh();
        }
    }
}
</script>
