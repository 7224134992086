<template>
    <div>
        <ListView title="CashPayments List" :modalObject='currentModalObject' >
            <template v-slot:actions>
                <b-button @click="modal('cashPaymentForm', {type:'cash-in', entityId: entityId}, 'refreshTable')" variant='primary' class='mr-2'>
                    <i class='fa fa-arrow-down'></i>
                    Create Cash In
                </b-button>
                <b-button @click="modal('cashPaymentForm', {type:'cash-out', entityId: entityId}, 'refreshTable')" variant='primary' class='mr-2'>
                    <i class='fa fa-arrow-up'></i>
                    Create Cash Out
                </b-button>
                <b-button @click="modal('cashPaymentForm', {type:'balance-in', entityId: entityId}, 'refreshTable')" variant='primary' class='mr-2'>
                    <i class='fa fa-arrow-down'></i>
                    Create Balance In
                </b-button>

                <b-button @click="modal('cashPaymentForm', {type:'balance-out', entityId: entityId}, 'refreshTable')" variant='primary' class='mr-2'>
                    Create Balance Out
                </b-button>

                <b-button v-if='!entityId' @click="modal('cashPaymentForm', {type:'starting-balance'}, 'refreshTable')" variant='primary' class='mr-2'>
                    New Starting Balance
                </b-button>
            </template>

            <template v-slot:filter>
                <div class='form'>
                    <b-col cols='3'>
                        <b-form-group label="Date Range">
                            <my-date-range-picker v-model='dateRange' :style='{display:"inline-block", "vertical-align": "middle"}'/>
                            <div class='btn btn-danger btn-xxs ml-2' @click='() => dateRange = {startDate: null, endDate: null}'>x</div>
                        </b-form-group>
                    </b-col>
                    <b-col cols='3'>
                        <b-form-group label='entity'>
                            <entities-select  v-model='filter.entity_id' :returnId='true'/>
                        </b-form-group>
                    </b-col>
                </div>


                <b-form-group>
                    <b-form-checkbox
                        v-model="filter.summary"
                        value="1"
                        unchecked-value="0"
                        :inline='true'
                    >
                        Summary
                    </b-form-checkbox>
                </b-form-group>
                <b-form-group label="Summary Options" v-if='filter.summary == 1'>
                    <b-form-checkbox
                        v-model="filter.group_by_entity_id"
                        value="1"
                        unchecked-value="0"
                        :inline='true'
                    >
                        Group By Entity
                    </b-form-checkbox>

                    <b-form-checkbox
                        v-model="filter.group_by_cash_register_id"
                        value="1"
                        unchecked-value="0"
                        :inline='true'
                    >
                        Group By Cash Register
                    </b-form-checkbox>

                    <b-form-group label="Group By">
                        <b-form-radio-group
                            v-model="filter.group_by_period"
                            :options="['day', 'month', 'year', 'none']"
                        ></b-form-radio-group>
                    </b-form-group>

                </b-form-group>
            </template>

			<template v-slot:table>
				<cash-payments-summary-list-table v-if='filter.summary == "1"'  :filter='filter' ref='tableList' :viewType='viewType'></cash-payments-summary-list-table>
				<CashPaymentsListTable v-else :filter='filter' ref='tableList' :viewType='viewType'></CashPaymentsListTable>
			</template>
                    
        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import CashPaymentsListTable from '@/components/Lists/CashPaymentsListTable';
import CashPaymentsSummaryListTable from '@/components/Lists/CashPaymentsSummaryListTable.vue';
import EntitiesSelect from '../../components/Select/EntitiesSelect.vue';
import moment from 'moment';

export default {
    extends: BaseList,
    props: ['entityId'],
    components: {
        CashPaymentsListTable,
        CashPaymentsSummaryListTable,
        EntitiesSelect
    },
    data: function () {
        return {
            defaultFilter: {
                start_date: moment().format('YYYY-MM-DD'),
                end_date: moment().format('YYYY-MM-DD'),
                group_by_period: 'day',

            }
        };
    },
    methods: {
        refreshTable () {
            this.$refs.tableList.refresh();
        }
    },
    watch: {
        entityId: {
            immediate: true,
            handler:
                function () {
                    this.filter.entity_id = this.entityId;
                    if (this.entityId) {
                        this.filter.start_date = null;
                        this.filter.end_date = null;
                        this.viewType = 'entity';
                    }
                }
        }
    }
}
</script>
