<template>
    <div>
        <div v-if='entity'>

            <b-row align-h="between">
                <b-col>
                    <h3><i class='fa fa-child'></i> {{ entity.name }}</h3>
                </b-col>
                <b-col cols='4'>
                    <div>
                        <button class='btn btn-xs btn-primary'
                            @click="modal('entitySaleablesForm', { entityId: entity.id }, 'refresh')"
                            title='New Sale/Purchase'><i class='fa fa-shopping-cart'></i></button>
                        <button class='btn btn-xs btn-primary ml-1'
                            @click="modal('itemsDeliveryForm', { entityIds: [entity.id] }, 'refresh')"><i
                                class='fa fa-truck-loading'></i></button>

                        <button class='btn btn-xs btn-primary ml-2'
                            @click="modal('cashPaymentForm', { entityId: entity.id }, 'refresh')"><i
                                class='fa fa-credit-card'></i></button>
                        <button class='btn btn-xs btn-primary ml-1'
                            @click="modal('cashReturnForm', { entityId: entity.id }, 'refresh')"><i class='fa fa-undo'></i>
                            <i class='fa fa-credit-card'></i></button>

                        <button class='btn btn-xs btn-primary ml-2' v-if='entity.type == "kid"'
                            @click="modal('kidForm', { id: entity.kid.id }, 'refresh')"><i class='fa fa-edit'></i> </button>
                        <button class='btn btn-xs btn-primary ml-2' v-else
                            @click="modal('entityForm', { id: entity.id }, 'refresh')"><i class='fa fa-edit'></i> </button>
                        <button v-if='can("entities.delete")' class='btn btn-xs btn-danger m-2'
                            @click="modal('deleteEntityForm', { id: entity.id }, 'refresh')"><i
                                class='fa fa-trash'></i></button>
                    </div>
                </b-col>
            </b-row>
            <div class='profile-tab'>
                <div class='custom-tab-1'>
                    <b-tabs content-class="mt-3">
                        <b-tab title="Sales" lazy>
                            <entity-saleables-list :entityId='entity.id'></entity-saleables-list>
                        </b-tab>
                        <b-tab title="Summary" lazy>
                            <summary-list :entityId='entity.id'></summary-list>
                        </b-tab>
                        <b-tab title="Delivered Items" lazy>
                            <entity-items-list :entityId='entity.id'></entity-items-list>
                        </b-tab>
                        <b-tab title="Cash Payments" lazy>
                            <cash-payments-list :entityId='entity.id'></cash-payments-list>
                        </b-tab>
                        <b-tab title="Transactions" lazy>
                            <transactions-list :entityId='entity.id'></transactions-list>
                        </b-tab>
                        <b-tab title="Subscriptions" lazy>
                            <subscriptions-list :entityId='entity.id'></subscriptions-list>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>

        <Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed' />
    </div>
</template>

<script>
import CashPaymentsList from '../CashPayments/CashPaymentsList.vue';
import EntityItemsList from '../EntityItems/EntityItemsList.vue';
import EntitySaleablesList from '../EntitySaleables/EntitySaleablesList.vue';
import SummaryList from '../Summary/SummaryList.vue';
import TransactionsList from '../Transactions/TransactionsList.vue';
import SubscriptionsList from '../Subscriptions/SubscriptionsList.vue';

export default {
    components: {
        EntitySaleablesList,
        EntityItemsList,
        CashPaymentsList,
        TransactionsList,
        SubscriptionsList,
        SummaryList
    },
    props: ['id'],
    mounted() {
        this.apiRequest(
            (apiClient) => {
                return apiClient.get('entities/' + this.id)
                    .then(entity => {
                        this.entity = entity;
                    });
            }
        );
    },
    methods: {

    },
    data: function () {
        return {
            entity: null
        }
    }
}
</script>