<template>
    <div>
        <list-view title="Summary List" :modalObject='currentModalObject' >
            <template v-slot:actions>
                <b-button  v-if='can("kids.create")' @click="modal('kidForm', {}, 'refreshTable')" variant='primary' class='mr-2'>
                    Create Kid
                </b-button>
                <b-button v-if='can("delivery.create")' @click="modal('itemsDeliveryForm', {entityType: 'kid', classRoomId: classRoomId}, 'refreshTable')" variant='primary' class='mr-2'>
                    Deliver Items
                </b-button>
                <b-button v-if='can("cash_payments.create")' @click="modal('cashPaymentForm', {type:'cash-in', entityId: entityId}, 'refreshTable')" variant='primary' class='mr-2'>
                    <i class='fa fa-arrow-down'></i>
                    Create Cash In
                </b-button>
                <b-button v-if='can("cash_payments.create")' @click="modal('cashPaymentForm', {type:'cash-out', entityId: entityId}, 'refreshTable')" variant='primary' class='mr-2'>
                    <i class='fa fa-arrow-up'></i>
                    Create Cash Out
                </b-button>
                <b-button v-if='can("cash_payments.create")' @click="modal('cashPaymentForm', {type:'balance-in', entityId: entityId}, 'refreshTable')" variant='primary' class='mr-2'>
                    <i class='fa fa-arrow-down'></i>
                    Create Balance In
                </b-button>

                <b-button v-if='can("cash_payments.create")' @click="modal('cashPaymentForm', {type:'balance-out', entityId: entityId}, 'refreshTable')" variant='primary' class='mr-2'>
                    Create Balance Out
                </b-button>

                <b-button v-if='!entityId && can("cash_payments.create")' @click="modal('cashPaymentForm', {type:'starting-balance'}, 'refreshTable')" variant='primary' class='mr-2'>
                    New Starting Balance
                </b-button>
            </template>

            <template v-slot:filter>
                <div class='form'>
                    <b-form-group label="Date Range">
                        <my-date-range-picker v-model='dateRange' :style='{display:"inline-block", "vertical-align": "middle"}'/>
                        <div class='btn btn-danger btn-xxs ml-2' @click='() => dateRange = {startDate: null, endDate: null}'>x</div>
                    </b-form-group>
                    <b-form-group label="Group By">
                        <b-form-radio-group
                            v-model="filter.group_by"
                            :options="['day', 'month', 'year']"
                        ></b-form-radio-group>
                    </b-form-group>
                </div>
            </template>

			<template v-slot:table>
                <summary-list-table :filter='filter' :viewType="entityId ? 'entity' : null" ref='tableList' ></summary-list-table>
			</template>
                    
        </list-view>
    </div>
</template>
<script>
import SummaryListTable from '../../components/Lists/SummaryListTable.vue';
import ListView from '../ListView.vue';
import BaseList from '@/views/BaseList';
import moment from 'moment';

export default {
    extends: BaseList,
  components: { SummaryListTable, ListView },
    props: ['entityId'],
    data: function () {
        return {
            defaultFilter: {
                start_date: moment().startOf('month').format('YYYY-MM-DD'),
                end_date: moment().endOf('month').format('YYYY-MM-DD'),
                group_by: 'day'
            }
        }
    },
    methods: {
        refreshTable () {
            this.$refs.tableList.refresh();
        }
    },
    watch: {
        entityId: {
            immediate: true,
            handler:
                function () {
                    this.filter.entity_id = this.entityId;
                    if (this.entityId) {
                        this.filter.start_date = null;
                        this.filter.end_date = null;
                        this.filter.group_by = 'month';
                    }
                }
        }
    }
}
</script>