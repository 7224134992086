<template>
    <div>
        <div v-if='kid'>
            <b-row align-h="between">
                <b-col>
                    <h3><i class='fa fa-child'></i> {{ kid.name }}</h3>
                    <h6><i class='fa fa-chair'></i> Class: {{ kid.class_room.name }}</h6>
                    <b-alert show v-for='(entityNote, index) in kid.entity.pinned_entity_notes' variant="info" :key='index'>
                        <b>
                            <pre>{{ entityNote.note }}</pre>
                            <i>{{ entityNote.created_at | datetime }}</i>
                            <button class='btn btn-xs btn-primary ml-2'
                                @click="modal('entityNoteForm', { id: entityNote.id }, 'refresh')">
                                <i class="fa fa-edit"></i>
                            </button>
                        </b>
                    </b-alert>

                    <b-alert v-if='kid.entity.non_sold_items.length > 0' show variant="danger">
                        <div v-if='kid.entity.non_sold_items.length > 0'>
                            <b>Delivered without sale</b><br>
                            <div v-for="(entityItem, index) in kid.entity.non_sold_items" :key='index'>
                                <div class='badge badge-danger'>
                                    <i class='fa fa-truck-loading' />
                                    {{ entityItem.item.name }}
                                </div>
                                <div class='badge badge-danger ml-1'>
                                    {{ entityItem.quantity }}
                                </div>
                                <div class='badge badge-danger ml-2'>
                                    <i class='fa fa-calendar'></i>
                                    {{ entityItem.date }}
                                </div>
                            </div>
                        </div>
                    </b-alert>
                    <div v-if='kid.entity.not_used_payments.length > 0'>
                        <b>Not Used Payments</b><br />
                        <div v-for='(paymentPart, index) in kid.entity.not_used_payments' :key='index'>
                            <div class='badge badge-light'>
                                {{ paymentPart.amount | currency }}
                            </div>
                            <div class='badge badge-light ml-2'>
                                <i class='fa fa-calendar'></i>
                                {{ paymentPart.cash_payment.date }}
                            </div>
                            <button class='btn btn-xs btn-primary m-2'
                                @click="modal('cashPaymentForm', { id: paymentPart.cash_payment.id }, 'refresh')">Edit</button>
                            <button class='btn btn-xs btn-primary ml-1'
                                @click="modal('cashReturnForm', { entityId: kid.entity_id }, 'refresh')"><i
                                    class='fa fa-undo'></i> <i class='fa fa-credit-card'></i> return payment</button>

                        </div>
                    </div>
                    <b-alert show variant="danger" v-if='unpaid'>
                        <b>
                            ! Total unpaid {{ unpaid }}
                            <button class='btn btn-xs btn-primary ml-2'
                                @click="modal('cashPaymentForm', { entityId: kid.entity_id }, 'refresh')">pay now</button>
                        </b>
                    </b-alert>
                </b-col>
                <b-col cols='4'>
                    <div>
                        <button class='btn btn-xs btn-primary ml-2'
                            @click="modal('entityNoteForm', { entityId: kid.entity_id }, 'refresh')"><i
                                class="fas fa-sticky-note"></i> </button>
                        <button class='btn btn-xs btn-primary ml-2'
                            @click="modal('entitySaleablesForm', { entityId: kid.entity_id }, 'refresh')"
                            title='New Sale/Purchase'><i class='fa fa-shopping-cart'></i></button>
                        <button class='btn btn-xs btn-primary ml-1'
                            @click="modal('itemsDeliveryForm', { entityIds: [kid.entity_id] }, 'refresh')"><i
                                class='fa fa-truck-loading'></i></button>

                        <button class='btn btn-xs btn-primary ml-2'
                            @click="modal('cashPaymentForm', { entityId: kid.entity_id }, 'refresh')"><i
                                class='fa fa-credit-card'></i></button>
                        <button class='btn btn-xs btn-primary ml-1'
                            @click="modal('cashReturnForm', { entityId: kid.entity_id }, 'refresh')"><i
                                class='fa fa-undo'></i> <i class='fa fa-credit-card'></i></button>

                        <button class='btn btn-xs btn-primary ml-2' @click="modal('kidForm', { id: kid.id }, 'refresh')"><i
                                class='fa fa-edit'></i> </button>
                        <button v-if='can("entities.delete")' class='btn btn-xs btn-danger m-2'
                            @click="modal('deleteEntityForm', { id: kid.entity_id }, 'refresh')"><i
                                class='fa fa-trash'></i></button>
                    </div>
                </b-col>
            </b-row>
            <div class='profile-tab'>
                <div class='custom-tab-1'>
                    <b-tabs content-class="mt-3">
                        <b-tab title="Active" lazy>
                            <kid-active-view :entityId='kid.entity_id'></kid-active-view>
                        </b-tab>
                        <b-tab title="Sales" lazy>
                            <entity-saleables-list :entityId='kid.entity_id'></entity-saleables-list>
                        </b-tab>
                        <b-tab title="Summary" lazy>
                            <summary-list :entityId='kid.entity_id'></summary-list>
                        </b-tab>
                        <b-tab title="Delivered Items" lazy>
                            <entity-items-list :entityId='kid.entity_id'></entity-items-list>
                        </b-tab>
                        <b-tab title="Cash Payments" lazy>
                            <cash-payments-list :entityId='kid.entity_id'></cash-payments-list>
                        </b-tab>
                        <b-tab title="Notes" lazy>
                            <entity-notes-list :entityId='kid.entity_id'></entity-notes-list>
                        </b-tab>
                        <b-tab title="Transactions" lazy>
                            <transactions-list :entityId='kid.entity_id'></transactions-list>
                        </b-tab>
                        <b-tab title="Subscriptions" lazy>
                            <subscriptions-list :entityId='kid.entity_id'></subscriptions-list>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>

        <Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed' />
    </div>
</template>

<script>
import CashPaymentsList from '../CashPayments/CashPaymentsList.vue';
import EntityItemsList from '../EntityItems/EntityItemsList.vue';
import EntityNotesList from '../EntityNotes/EntityNotesList.vue';
import EntitySaleablesList from '../EntitySaleables/EntitySaleablesList.vue';
import SummaryList from '../Summary/SummaryList.vue';
import TransactionsList from '../Transactions/TransactionsList.vue';
import SubscriptionsList from '../Subscriptions/SubscriptionsList.vue';
import KidActiveView from './KidActiveView.vue';

export default {
    components: {
        EntitySaleablesList,
        EntityItemsList,
        CashPaymentsList,
        TransactionsList,
        SubscriptionsList,
        SummaryList,
        EntityNotesList,
        KidActiveView
    },
    props: ['id'],
    KidActiveView,
    mounted() {
        this.apiRequest(
            (apiClient) => {
                return apiClient.get('kids/' + this.id)
                    .then(kid => {
                        this.kid = kid;
                    });
            }
        );
    },
    methods: {
        refresh: function () {
            this.$router.go(0);
        }
    },
    data: function () {
        return {
            kid: null
        }
    },
    computed: {
        unpaid: function () {
            return !this.kid ? 0 : this.kid.entity.unpaid_transactions.reduce(function (accumulator, a) {
                return accumulator + parseFloat(a.amount_remaining);
            }, 0)
        }
    }
}
</script>