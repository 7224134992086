<template>
    <div class="table-responsive recentOrderTable">

        <div class='text-right m-2' v-if='meta.total'>
            <h3>
                count: {{ meta.total ? meta.total : ''}}
            </h3>
        </div>
        <b-table hover :items="collection.data" :fields="fields"
            @row-selected='rowSelected'
            :selectable='selectable'
            responsive
            small
            :select-mode='selectMode'
            :busy="loading">
            <template #table-busy>
                <loader/>
            </template>
            <template v-for="slotName in Object.keys($scopedSlots)" v-slot:[slotName]="slotScope">
                <slot :name="slotName" v-bind="slotScope"></slot>
            </template>
        </b-table>

        <div class='row justify-content-md-center' v-if='meta.last_page && meta.last_page != 1'>
            <b-pagination
                v-model="currentPage"
                :total-rows="meta.total"
                :per-page="meta.per_page"
                @input="$emit('pageChanged', currentPage)"
                ></b-pagination>
        </div>
		<Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
		</Modals>
    </div>
</template>

<script>
export default {
    props: ["collection", "fields", "loading", "modalObject", "selectMode", "selectable"],
    watch:{
        modalObject:{
            deep: true,
            handler() {
                if (this.modalObject) {
                    this.currentModal = this.modalObject.currentModal;
                    this.currentModalData = this.modalObject.currentModalData;
                    this.modalCallback = this.modalObject.modalCallback;
                    this.modalClosed = this.modalObject.modalClosed;
                }
            }
        },
        meta: {
            immediate: true,
            handler: function () {
                if (this.meta.current_page) {
                    this.currentPage = this.meta.current_page;
                } else {
                    this.currentPage = 1;
                }
            }
        }
    },
    data: function() {
        return {
            currentPage: 1,
            selected: []
        };
    },
    methods: {
        rowSelected: function (selectedItems) {
            this.$emit('rowSelected', selectedItems);
        }
    },
    computed: {
        meta: function() {
            var meta = this.collection.meta ? this.collection.meta : {};
            return meta;
        }
    },
}
</script>