<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='currentFields' :modalObject='currentModalObject'
            @pageChanged='pageChanged'>

            <template v-slot:cell(entity)='data'>
                <div v-if='data.item.entity'>
                    <div v-if='data.item.entity.type == "kid" && data.item.entity.kid'>
                        <router-link :to="{ name: 'ViewKid', params: { id: data.item.entity.kid.id } }" class=''>
                            <h5>
                                {{ data.item.entity.name }}
                            </h5>
                        </router-link>
                    </div>
                    <div v-else>
                        <router-link :to="{ name: 'ViewEntity', params: { id: data.item.entity.id } }" class=''>
                            <h5>
                                {{ data.item.entity.name }}
                            </h5>
                        </router-link>
                    </div>
                    <div class='badge badge-light'>
                        {{ data.item.entity.type }}
                    </div>
                    <div v-for='(note, index) in data.item.entity.pinned_entity_notes' :key='index'>
                        <small :title="note.note">
                            {{ note.note | str_limit(100) }}
                        </small>
                    </div>
                </div>
            </template>
            <template v-slot:cell(cash_payment_parts)='data'>
                <div v-for='(cashPaymentPart, index) in data.item.cash_payment_parts' :key='index'>
                    <span v-if='cashPaymentPart.transaction'>

                        <span
                            v-if='cashPaymentPart.transaction.transactionable.started_at != cashPaymentPart.transaction.transactionable.finished_at'
                            class='ml-2'>
                            <div class='badge badge-light'>
                                {{ cashPaymentPart.transaction.transactionable.started_at }}
                            </div>
                            -
                            <div class='badge badge-light'>
                                {{ cashPaymentPart.transaction.transactionable.finished_at }}
                            </div>
                        </span>
                        <span v-else class='ml-2'>
                            <div class='badge badge-light'>
                                {{ cashPaymentPart.transaction.transactionable.started_at }}
                            </div>
                        </span>
                        <span class='badge badge-success ml-2' :class='statusBadge(cashPaymentPart.transaction.status)'>
                            {{ cashPaymentPart.transaction.title }}
                        </span>
                    </span>
                    <span class='badge ml-2'
                        :class='statusBadge(cashPaymentPart.transaction ? cashPaymentPart.transaction.status : "pending")'>
                        {{ cashPaymentPart.amount | currency }}
                    </span>
                    <div v-if='cashPaymentPart.cash_return_payment' class='badge badge-danger ml-2'>
                        <i class='fa fa-undo'></i>
                        returned at
                        {{ cashPaymentPart.cash_return_payment.date }}
                    </div>
                </div>
                <div v-for='(cashPaymentPart, index) in data.item.cash_return_payment_parts' :key='index'>
                    <div v-if='cashPaymentPart.transaction' class='badge badge-success'
                        :class='statusBadge(cashPaymentPart.transaction.status)'>
                        {{ cashPaymentPart.transaction.title }}
                    </div>
                    <div class='badge ml-2'
                        :class='statusBadge(cashPaymentPart.transaction ? cashPaymentPart.transaction.status : "pending")'>
                        <i class='fa fa-undo'></i>
                        {{ cashPaymentPart.amount | currency }}
                    </div>
                    <div v-if='cashPaymentPart.cash_payment' class='badge badge-light ml-2'>
                        <i class='fa fa-info'></i>
                        original payment
                        #{{ cashPaymentPart.cash_payment.id }}
                        at
                        {{ cashPaymentPart.cash_payment.date }}
                    </div>
                </div>
            </template>

            <template v-slot:cell(amount)='data'>
                <div class='badge' :class='typeClasses[data.item.type]'>
                    {{ data.item.amount | currency }}
                </div>

                <div class='badge badge-secondary mx-2' v-if="data.item.payment_method">
                    {{ data.item.payment_method.name }}
                </div>
            </template>

            <template v-slot:cell(type)='data'>
                <div class='badge' :class='typeClasses[data.item.type]'>
                    <i class='fa' :class='typeIcons[data.item.type]'></i>
                    {{ data.item.type }}
                </div>
                <br>
                <div v-if='data.item.is_return == 1' class='badge badge-danger'>
                    <i class='fa fa-undo'></i> Cash Return
                </div>
            </template>

            <template v-slot:cell(date)='data'>
                {{ data.item.date | date }}
            </template>

            <template v-slot:cell(actions)='data'>
                <span v-if='can("cash_payments.edit")'>
                    <button class='btn btn-xs btn-primary m-2' v-if='data.item.is_return == 1'
                        @click="modal('cashReturnForm', { id: data.item.id }, 'refresh')">Edit</button>
                    <button class='btn btn-xs btn-primary m-2' v-else
                        @click="modal('cashPaymentForm', { id: data.item.id }, 'refresh')">Edit</button>
                </span>
                <button v-if='can("cash_payments.delete")' class='btn btn-xs btn-danger m-2'
                    @click="modal('deleteCashPaymentForm', { id: data.item.id }, 'refresh')">Delete</button>
            </template>
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
    props: ['viewType'],
    components: { BaseList },
    extends: BaseListMethods,
    data() {
        return {
            fields: [
                { key: 'id', label: 'ID' },
                { key: 'entity', label: 'Entity' },
                { key: 'type', label: 'Type' },
                { key: 'amount', label: 'Amount' },
                { key: 'cash_register.name', label: 'Cash Register' },
                { key: 'date', label: 'Date' },
                { key: 'cash_payment_parts', label: 'Payment Parts' },
                { key: 'actions', label: 'Actions' },
            ],
            statusBadges: {
                "in progress": "badge-warning",
                "finished": "badge-success",
                "pending": "badge-light",
            },
            typeIcons: {
                "cash-in": "fa-arrow-down",
                "cash-out": "fa-arrow-up",
                "balance-in": "fa-arrow-down",
                "balance-out": "fa-arrow-up",
            },
            typeClasses: {
                "cash-in": "badge-success",
                "cash-out": "badge-danger",
                "balance-in": "badge-warning",
                "balance-out": "badge-danger",
            }
        }
    },
    methods: {
        statusBadge: function (status) {
            return this.statusBadges[status] ? this.statusBadges[status] : "badge-light";
        },
        load: function (apiClient, cancelToken, filter) {
            return apiClient.get('cash-payments', filter, cancelToken);
        },
        deleteCashPayment: function (cashPayment) {
            return this.apiRequest(apiClient => apiClient.delete('cash-payments/' + cashPayment.id));
        }
    },
    watch: {

        // viewType: {
        // 	immediate: true,
        // 	handler: function () {
        // 	}
        // },
    },
    computed: {
        currentFields: function () {
            return this.viewType == 'entity' ? this.fields.filter(item => item.key == 'entity' ? false : true) : this.fields;
        }
    }
}
</script>