<template>
    <div>
        <ListView title="Entities List" :modalObject='currentModalObject' >
            <template v-slot:actions>
                <b-button @click="modal('entityForm', {}, 'refreshTable')" variant='primary' class='mr-2'>
                    Create Entity
                </b-button>

            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>
                    </div>
                    <div class='form-row'>
                        <b-form-group label="Status">
                            <b-form-radio-group
                                v-model="filter.status"
                                :options="['all', 'active', 'inactive']"
                            ></b-form-radio-group>
                        </b-form-group>
                    </div>
                    <div class='form-row'>
                        <b-form-group label="Date">
                            <b-datepicker
                                v-model="filter.date"
                            ></b-datepicker>
                        </b-form-group>
                    </div>
                    <div class='form-row'>
                        <b-form-group>
                            <b-form-checkbox
                                v-model="filter.has_sales"
                                value="1"
                                unchecked-value="0"
                                :inline='true'
                            >
                                Has Sales
                            </b-form-checkbox>
                        </b-form-group>
                        <b-form-group>
                            <b-form-checkbox
                                v-model="filter.has_active_transactions"
                                value="1"
                                unchecked-value="0"
                                :inline='true'
                            >
                                Has active items
                            </b-form-checkbox>
                        </b-form-group>

                        <b-form-group>
                            <b-form-checkbox
                                v-model="filter.has_non_used_payments"
                                value="1"
                                unchecked-value="0"
                                :inline='true'
                            >
                                Has non used payments
                            </b-form-checkbox>
                        </b-form-group>
                        <b-form-group>
                            <b-form-checkbox
                                v-model="filter.has_unpaid_transactions"
                                value="1"
                                unchecked-value="0"
                                :inline='true'
                            >
                                Has unpaid items
                            </b-form-checkbox>
                        </b-form-group>

                        <b-form-group>
                            <b-form-checkbox
                                v-model="filter.has_reserved_transactions"
                                value="1"
                                unchecked-value="0"
                                :inline='true'
                            >
                                Has reseved items
                            </b-form-checkbox>
                        </b-form-group>

                        <b-form-group>
                            <b-form-checkbox
                                v-model="filter.has_non_sold_items"
                                value="1"
                                unchecked-value="0"
                                :inline='true'
                            >
                                Has non sold items
                            </b-form-checkbox>
                        </b-form-group>
                    </div>
                    <div class='form-row'>

                        <b-col cols=3>
                            <b-form-group label="Class">
                                <class-rooms-select v-model='filter.class_room_id' :returnId='true'/>
                            </b-form-group>
                        </b-col>
                        <b-col cols=3>
                            <b-form-group label="Saleable">
                                <saleables-select v-model='filter.saleable_id' :returnId='true'/>
                            </b-form-group>
                        </b-col>
                        <b-col cols=3>
                            <b-form-group label="Saleable Group">
                                <saleable-group-select v-model='filter.saleable_group_id' :returnId='true'/>
                            </b-form-group>
                        </b-col>
                    </div>
                </div>
            </template>

			<template v-slot:table>
				<EntitiesListTable :filter='filter' ref='tableList' :viewType='viewType'></EntitiesListTable>
			</template>
                    
        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import EntitiesListTable from '@/components/Lists/EntitiesListTable';
import SaleablesSelect from '../../components/Select/SaleablesSelect.vue';
import SaleableGroupSelect from '../../components/Select/SaleableGroupSelect.vue';
import ClassRoomsSelect from '../../components/Select/ClassRoomsSelect.vue';

export default {
    extends: BaseList,
    components: {EntitiesListTable, SaleablesSelect, SaleableGroupSelect, ClassRoomsSelect},
    props: ['type'],
    mounted: function () {
        this.filter = {...this.filter, status: 'active'};
    },
    watch: {
        type: {
            immediate: true,
            handler: function () {
                this.filter.entity_type = this.type;
            }
        }
    },
    methods: {
        refreshTable () {
            this.$refs.tableList.refresh();
        }
    }
}
</script>
