<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='currentFields' :modalObject='currentModalObject' @pageChanged='pageChanged'>
            
            <template v-slot:cell(name)='data'>
                <div>
                    {{ data.item.entity.name }}
                </div>
            </template>
            <template v-slot:cell(date)='data'>
                <div :title='data.item.id'>
                    {{ data.item.date | date }}
                </div>
            </template>

            <template v-slot:cell(quantity)='data'>
                <div>
                    {{ data.item.quantity }}
                </div>
            </template>

            <template v-slot:cell(item)='data'>
                <div>
                    {{ data.item.item.name }}
                </div>

                <div v-if='data.item.entity_saleable_item'>

                    <div class='badge badge-light ml-2'>
                        {{ data.item.entity_saleable_item.entity_saleable.quantity }}
                        X
                        {{ data.item.entity_saleable_item.entity_saleable.description }}
                    </div>

                    <div class='badge' :class="statusBadge(data.item.entity_saleable_item.status)">
                        {{ data.item.entity_saleable_item.status }}
                    </div>
                    <div class='badge badge ml-2'  :class="statusBadge(data.item.entity_saleable_item.status)" title='used/remaining'>

                        {{ data.item.entity_saleable_item.used_quantity }}
                        /
                        {{ data.item.entity_saleable_item.total_quantity }}
                    </div>

                    <div v-if='data.item.entity_saleable_item.start_date' class="badge badge-success ml-2">
                        from
                        <i class='fa fa-calendar'></i>
                        {{ data.item.entity_saleable_item.start_date }}
                    </div>
                    <div v-if='data.item.entity_saleable_item.end_date' class="badge badge-success ml-2">
                        to
                        <i class='fa fa-calendar'></i>
                        {{ data.item.entity_saleable_item.end_date }}
                    </div>
                </div>
                <div v-else>
                    <div class='badge badge-danger'>not subscriped</div>
                </div>
            </template>
			<template v-slot:cell(actions)='data'>
				<button v-if='can("delivery.delete")' class='btn btn-xs btn-danger m-2' @click="confirmAction('deleteEntityItem', data.item, 'refresh')">Delete</button>
            </template>
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
	props: ['viewType'],
    components: { BaseList },
    extends: BaseListMethods,
    data() {
        return {
			fields: [
                // { key: 'id', label: 'ID' },
                { key: 'date', label: 'Date' },
                { key: 'name', label: 'Name' },
                { key: 'quantity', label: 'Quantity' },
                { key: 'item', label: 'Item' },
                { key: 'actions', label: 'Actions' },
			],
            statusBadges: {
                "in progress": "badge-warning",
                "finished": "badge-success",
                "pending": "badge-light",
            }
        }
    },
	methods: {
        statusBadge: function(status) {
            return this.statusBadges[status] ? this.statusBadges[status] : "badge-light";
        },
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('entity-items', filter, cancelToken);
		},
        deleteEntityItem: function (entityItem) {
            return this.apiRequest(apiClient => apiClient.delete('entity-items/'+entityItem.id));
        }
    },
    watch: {

		// viewType: {
		// 	immediate: true,
		// 	handler: function () {
		// 	}
		// },
    },
    computed: {
        currentFields: function () {
            return this.viewType == 'entity' ? this.fields.filter(item => item.key == 'name' ? false : true) : this.fields;
        }
    }
}
</script>