<template>
    <div>

        <ListView title="Users List" :collection="collection" :fields="fields" @pageChanged='pageChanged'
            :loading="loading">
            <template v-slot:actions>
                <b-button v-if='can("users.create")' v-b-modal.user-modal @click="selectedUserId = null" variant='primary'
                    class='mr-2'>
                    Create User
                </b-button>

            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:cell(status)='data'>
                <span class="badge"
                    :class="{ 'badge-success': data.item.is_active, 'badge-warning': !data.item.is_active }">
                    {{ data.item.is_active ? "active" : "inactive" }}
                </span>
            </template>
            <template v-slot:cell(actions)='data'>
                <b-button v-if='can("users.edit") && data.item.is_active && !data.item.is_owner' class='btn-danger mr-2'
                    @click="confirmAction('deactivateUser', data.item, 'refresh')">Deactivate</b-button>
                <b-button v-if='can("users.edit") && !data.item.is_active && !data.item.is_owner' class='btn-success mr-2'
                    @click="confirmAction('activateUser', data.item, 'refresh')">Activate</b-button>
                <b-button v-if='can("users.edit")' v-b-modal.user-modal
                    @click="selectedUserId = data.item.id">Edit</b-button>

            </template>

        </ListView>

        <b-modal :no-close-on-backdrop=true id="user-modal" hide-footer title="User Form">
            <UserForm @saved='userSaved' :id='selectedUserId'></UserForm>
        </b-modal>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import UserForm from './UserForm';

export default {
    extends: BaseList,
    components: { UserForm },
    methods: {
        load: function (apiClient, cancelToken) {
            return apiClient.get('users', this.filter, cancelToken);
        },
        userSaved(hideIt) {
            this.refresh();
            if (hideIt) {
                this.$bvModal.hide("user-modal");
            }
        },
        activateUser: function (user) {
            return this.apiRequest(apiClient => apiClient.put('users/activate/' + user.id));
        },
        deactivateUser: function (user) {
            return this.apiRequest(apiClient => apiClient.put('users/deactivate/' + user.id));
        },
    },
    data: function () {
        return {
            fields: [
                { key: 'id', label: '#' },
                { key: 'username', label: 'User Name' },
                { key: 'email', label: 'Email' },
                { key: 'name', label: 'Name' },
                { key: 'role.name', label: 'Role' },
                { key: 'status', label: 'Status' },
                { key: 'actions', label: '' }
            ],
            selectedUserId: null
        };
    }
}
</script>
