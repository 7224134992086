<template>
  <div>
    <!--*******************
          Preloader start
      ********************-->
    <div id="preloader" style="display: none;">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <!--*******************
          Preloader end
      ********************-->

    <!--**********************************
          Main wrapper start
      ***********************************-->
    <div id="main-wrapper" class='show menu-toggle'>

      <!--**********************************
              Nav header start
          ***********************************-->
      <div class="nav-header">

        <div class="nav-control" @click='() => this.showSideMenu = !this.showSideMenu'>
          <div class="hamburger">
            <span class="line"></span><span class="line"></span><span class="line"></span>
          </div>
        </div>
      </div>
      <!--**********************************
              Nav header end
          ***********************************-->

      <!--**********************************
              Sidebar Fixed
          ***********************************-->
      <div class="fixed-content-box" :class='showSideMenu ? "show" : ""'>
        <div class="head-name">
          <div v-if='user && user.clients'>
            <b-row no-gutters  class="pt-3 pl-2" align-v="center">
              <b-col cols="3"  >
                <b-img v-bind="{width:50, height:50, blank: !user.currentClient.logo, blankColor: '#eee'}" :src="user.currentClient.logo ? user.currentClient.logo.url : '../images/default_logo.png'" alt=""/>
              </b-col>
              <b-col cols="9" class="pl-2">
                <b-nav-item-dropdown block v-if="user.clients.length > 1" :text="user.currentClient.name"
                  toggle-class="nav-link-custom" right>
                  <b-dropdown-item v-for="client in user.clients" :key="client.id" @click="switchClient(client)">
                    <b-img v-bind="{width:25, height:25, blank: !client.logo, blankColor: '#eee', class: 'm1'}" rounded="circle" :src="client.logo ? client.logo.url : '../images/default_logo.png'" alt=""/>
                    {{ client.name }}
                  </b-dropdown-item>
                </b-nav-item-dropdown>
                <div v-else>
                  {{ user.currentClient.name }}
                </div>
              </b-col>
            </b-row>

          </div>


        </div>

        <div class="fixed-content-body dz-scroll" id="DZ_W_Fixed_Contant">
          <div class="tab-content" id="menu">
            <div class="tab-pane fade active show" id="dashboard" role="tabpanel">
              <ul class="metismenu tab-nav-menu">

                <li v-if='can("kids.list")'>
                  <router-link to="/kids"><i class='fa fa-child'></i> {{ $t('nav.kids') }}</router-link>
                </li>
                <li v-if='can("classes.list")'>
                  <router-link to="/kids-classes"><i class='fa fa-chair'></i> {{ $t('nav.classes') }}</router-link>
                </li>
                <hr>
                <li v-if='can("entities.list")'>
                  <router-link to="/accounts/entities"><i class='fa fa-users'></i> {{ $t('nav.entities') }}</router-link>
                  <ul>
                    <li>
                      <router-link to="/accounts/kids"><i class='fa fa-user'></i> {{ $t('nav.kids') }}</router-link>
                    </li>
                    <li>
                      <router-link to="/accounts/customers"><i class='fa fa-user'></i> {{ $t('nav.customers')
                      }}</router-link>
                    </li>
                    <li>
                      <router-link to="/accounts/suppliers"><i class='fa fa-user'></i> {{ $t('nav.suppliers')
                      }}</router-link>
                    </li>
                  </ul>
                </li>
                <hr>
                <li>
                  <router-link
                    to="/accounts/entities?has_unpaid_transactions=1&status=all&has_non_sold_items=1&has_non_used_payments=1"><i
                      class='fa fa-bell'></i> {{ $t('nav.notifications') }} </router-link>
                </li>
                <hr>
                <li v-if='can("summary.list")'>
                  <router-link to="/summary"><i class='fa fa-book'></i> {{ $t('nav.summary') }} </router-link>
                </li>
                <li v-if='can("sales.list")'>
                  <router-link to="/sales"><i class='fa fa-shopping-cart'></i> {{ $t('nav.sales') }}</router-link>
                </li>
                <li v-if='can("subscriptions.list")'>
                  <router-link to="/subscriptions"><i class='fa fa-history'></i> {{ $t('nav.subscriptions')
                  }}</router-link>
                </li>
                <li v-if='can("expenses.list")'>
                  <router-link to="/expenses"><i class="fa fa-coins"></i> {{ $t('nav.expenses') }}</router-link>
                </li>

                <li v-if='can("delivery.list")'>
                  <router-link to="/sale-items"><i class='fa fa-cubes'></i> {{ $t('nav.deliverables') }}</router-link>
                </li>
                <li v-if='can("delivery.list")'>
                  <router-link to="/entity-items"><i class='fa fa-truck-loading'></i> {{ $t('nav.deliveredItems')
                  }}</router-link>
                </li>

                <li v-if='can("cash_payments.list")'>
                  <router-link to="/cash-payments"><i class='fa fa-credit-card'></i> {{ $t('nav.cashPayments')
                  }}</router-link>
                </li>

                <li v-if='false && can("loans.list")'>
                  <router-link to="/loans"><i class="fas fa-hand-holding-usd"></i> {{ $t('nav.loans') }}</router-link>
                </li>

                <hr>
                <li v-if='false && can("inventory.list")'>
                  <router-link to="/inventory"><i class="fas fa-warehouse"></i> {{ $t('nav.inventory') }}</router-link>
                </li>

                <li v-if='false && can("transactions.list")'>
                  <router-link to="/transactions"><i class='fa fa-exchange-alt'></i> {{ $t('nav.transactions')
                  }}</router-link>
                </li>
              </ul>
            </div>

            <div class="tab-pane" id="setup" role="tabpanel">
              <ul class="metismenu tab-nav-menu">

                <li v-if='can("roles.list")'>
                  <router-link to="/roles"><i class='fa fa-user-check'></i> {{ $t('nav.roles') }}</router-link>
                </li>
                <li v-if='can("users.list")'>
                  <router-link to="/users"><i class='fa fa-users'></i> {{ $t('nav.users') }}</router-link>
                </li>
                <li v-if='can("user_logs.list")'>
                  <router-link to="/user-logs"><i class='fa fa-clipboard-list'></i> {{ $t('nav.userLogs')
                  }}</router-link>
                </li>
                <li v-if='can("services_products.list")'>
                  <router-link to="/items"><i class='fa fa-box'></i> {{ $t('nav.services-products') }}</router-link>
                </li>
                <li v-if='can("saleables.list")'>
                  <router-link to="/saleables"><i class='fa fa-cubes'></i> {{ $t('nav.saleables') }}</router-link>
                </li>
                <li v-if='can("saleables.list")'>
                  <router-link to="/saleable-groups"><i class='fa fa-cubes'></i> {{ $t('nav.saleableGroup')
                  }}</router-link>
                </li>
                <li v-if='can("price_groups.list")'>
                  <router-link to="/price-groups"><i class='fa fa-tags'></i> {{ $t('nav.priceGroups') }}</router-link>
                </li>
                <li v-if='can("classes.list")'>
                  <router-link to="/class-rooms"><i class='fa fa-child'></i> {{ $t('nav.classrooms') }}</router-link>
                </li>
                <hr>
                <li>
				          <b-link @click="modal('editClientSettings', {}, 'refresh')"><i class="fa fa-gear"></i> Edit Settings</b-link>
                </li>
                <hr>
                <li>
                  <div>
                    <b>Workspace URL</b> <a :href="workspaceUrl" target="_blank">{{ workspaceUrl }}</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!--**********************************
              Sidebar End
          ***********************************-->


      <!--**********************************
              Header start
          ***********************************-->
      <div class="header">
        <div class="header-content">
          <nav class="navbar navbar-expand">
            <div class="collapse navbar-collapse justify-content-between">
              <div class="header-left">

                <div class="search_bar dropdown" v-if='false'>
                  <span class="search_icon p-3 c-pointer" data-toggle="dropdown">
                    <i class="mdi mdi-magnify"></i>
                  </span>
                  <div class="dropdown-menu p-0 m-0">
                    <form>
                      <input class="form-control" type="search" placeholder="Search" aria-label="Search">
                    </form>
                  </div>
                </div>
              </div>

              <ul class="navbar-nav header-right">

                <li class="nav-item" v-if="false">
                  <locale-switcher />
                </li>

                <li class="nav-item">
                  <template v-if='cashRegisters.length > 1'>
                    <b>Cash Register</b>
                    <b-nav-item-dropdown id="my-nav-dropdown" v-if="cashRegisters.length > 1"
                      :text="selectedCashRegister.name" toggle-class="nav-link-custom" right>
                      <b-dropdown-item v-for="cashRegister in cashRegisters" :key="cashRegister.id"
                        @click="switchCashRegister(cashRegister.id)">{{
                          cashRegister.name }}</b-dropdown-item>
                    </b-nav-item-dropdown>
                  </template>
                </li>
                <li class="nav-item dropdown header-profile">
                  <a class="nav-link" href="#" role="button" data-toggle="dropdown">
                    <div class="header-info" v-if='user'>
                      <span>
                        {{ $t('hey') }}, <strong>{{ user.name }} </strong>
                        <i class="fa fa-angle-down"></i>
                      </span> 
                    </div>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">

                    <a @click.stop='logout' class="dropdown-item ai-icon">
                      <svg id="icon-logout" xmlns="http://www.w3.org/2000/svg" class="text-danger" width="18" height="18"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                        <polyline points="16 17 21 12 16 7"></polyline>
                        <line x1="21" y1="12" x2="9" y2="12"></line>
                      </svg>
                      <span class="ml-2">{{ $t('logout') }} </span>
                    </a>

                    <router-link to="reset-password" class="dropdown-item ai-icon">
                      <span class="ml-2">{{ $t('reset_password') }} </span>
                    </router-link>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <!--**********************************
              Header end ti-comment-alt
          ***********************************-->

      <!--**********************************
              Sidebar start
          ***********************************-->
      <div class="deznav">
        <div class="deznav-scroll">
          <ul class="nav menu-tabs">
            <li class="nav-item">
              <a class="nav-link active" data-toggle="tab" href="#dashboard">
                <i class="fa fa-home"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#setup">
                <i class="fa fa-wrench"></i>
              </a>
            </li>
          </ul>
        </div>
        <a @click.stop='logout' class="logout-btn"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" class="feather feather-log-out">
            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
            <polyline points="16 17 21 12 16 7"></polyline>
            <line x1="21" y1="12" x2="9" y2="12"></line>
          </svg></a>
      </div>
      <!--**********************************
              Sidebar end
          ***********************************-->

      <!--**********************************
              Content body start
          ***********************************-->
      <div class="content-body">
        <div class="container-fluid">
          <slot />
        </div>
      </div>
      <!--**********************************
              Content body end
          ***********************************-->

      <!--**********************************
              Footer start
          ***********************************-->
      <div class="footer">
        <div class="copyright">
          <p>
            Powered By <b-img v-bind="{width:25, height: 25}" src="images/modeer icon.svg"/> <b>Modeer</b> 
          </p>
        </div>
      </div>
      <!--**********************************
              Footer end
          ***********************************-->

      <!--**********************************
            Support ticket button start
          ***********************************-->

      <!--**********************************
            Support ticket button end
          ***********************************-->


    </div>
    <!--**********************************
        Main wrapper end
    ***********************************-->

		<Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
		</Modals>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cashRegisters: [],
      selectedCashRegister: {
        name: '',
      },
      user: null,
      showSideMenu: false,
      classRooms: [],
      hostName: '',
    };
  },
  created() {
    this.hostName = window.location.hostname;
    this.user = this.loadUser();
    document.title = this.user.currentClient.name ;
    this.apiRequest(apiClient => apiClient.getNoCancel('cash-registers')).then(
      response => {
        this.cashRegisters = response.data;

        if (!localStorage.cashRegisterId) {
          localStorage.cashRegisterId = this.cashRegisters[0].id;

          this.selectedCashRegister = this.cashRegisters[0];

        } else {
          this.selectedCashRegister = this.cashRegisters.find((cashRegister) => cashRegister.id == localStorage.cashRegisterId);
        }

      }
    );
    this.apiRequest(apiClient => apiClient.getNoCancel('class-rooms', { is_active: 1 })).then(
      response => this.classRooms = response.data
    );
  },
  computed: {
    workspaceUrl() {
      return 'https://' + this.user.currentClient.slug + '.' + this.hostName;
    }
  },
  methods: {
    logout() {
      this.apiRequest(apiClient => {
        apiClient.post('auth/logout').then(() => {
          localStorage.removeItem('cashRegisterId');
          localStorage.removeItem('token');
          this.apiClient.setUser(null);
          this.apiClient.setToken(null);
          this.$router.push({ name: 'Login' });
        });
      });
    },
    switchClient(client) {
      this.apiRequest(apiClient => {
        apiClient.post('switch-client', { client_id: client.id }).then((response) => {
          localStorage.removeItem('cashRegisterId');

          localStorage.token = response.token;
          this.apiClient.setUser(response.user);
          this.apiClient.setToken(response.token);
          // to make reload
          this.$router.go(this.$router.currentRoute);
        });
      });
    },
    switchCashRegister(cashRegisterId) {
      localStorage.cashRegisterId = cashRegisterId;
      this.selectedCashRegister = this.cashRegisters.find((cashRegister) => cashRegister.id == cashRegisterId);
    }
  },
}
</script>

<style>
.recentOrderTable table tbody td {
  line-height: 30px;
}

.badge {
  font-weight: 300;
  font-size: 85%;
}

.tab-nav-menu li a i {
  transform: scale(1.0);
}

.fixed-content-box {
  width: 230px;
}

@media (min-width: 991px) {
  .content-body {
    margin-left: 300px !important;
  }
}

@media (max-width: 768px) {
  .content-body {
    margin-left: 3rem !important;
  }
}

.tab-nav-menu li a {
  padding: 8px 0;
  font-size: 14px;
}

.tab-nav-menu li ul li {
  list-style: none;
}

.tab-nav-menu li ul li a {
  font-size: 14px;
  padding: 8px 25px;
}

.fixed-content-box .tab-pane {
  padding: 15px;
}

.table th,
.table td {
  padding: 5px 5px;
}

.fixed-content-box.show {
  left: 5rem !important;
}

.fixed-content-box.show .head-name {
  visibility: hidden;
}

@media (max-width: 768px) {
  .fixed-content-box.show {
    left: 3.5rem !important;
  }
}

.fixed-content-box {
  overflow-y: auto;
}

@media (max-width: 991px) {
  .nav-header .hamburger {
    display: inline-block !important;
  }

  [data-sidebar-style="mini"] .nav-header .nav-control .hamburger {
    left: 0 !important;
  }
}

[data-sidebar-style="mini"] .nav-control,
[data-layout="horizontal"] .nav-control {
  display: inline-block !important;
  right: -3.188rem !important;
  z-index: 1000;
}

.nav-link-custom {
  color: #222;
}

.nav-link-custom:hover {
  color: #222;
}
.fixed-content-box .head-name {
  font-size: 18px;
  padding: 0;
  display:block;
}

.logout-btn {
  color: #fff !important;
}
</style>