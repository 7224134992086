<template>
    <div>
        
      <b-modal :no-close-on-backdrop=true id="test-modal" size='xl' hide-footer  title="Create Invoice">
        Hi {{ test }}
      </b-modal>        
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            test: null
        }
    },
    methods : {
        modal: function (name, data) {
            this.test = data.test;
            this.$bvModal.show(name+'-modal');
        }
    }
}
</script>