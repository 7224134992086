<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject'
            @pageChanged='pageChanged'>

            <template v-slot:cell(name)='data'>
                <div v-if='data.item.type == "kid"'>
                    <router-link :to="{ name: 'ViewKid', params: { id: data.item.kid.id } }" class=''>
                        <h5>
                            {{ data.item.name }}
                        </h5>
                    </router-link>
                </div>
                <div v-else>
                    <router-link :to="{ name: 'ViewEntity', params: { id: data.item.id } }" class=''>
                        <h5>
                            {{ data.item.name }}
                        </h5>
                    </router-link>
                </div>
                <div v-for='(note, index) in data.item.pinned_entity_notes' :key='index'>
                    <small :title="note.note">
                        {{ note.note | str_limit(100) }}
                    </small>
                </div>
            </template>

            <template v-slot:cell(active_transactions)='data'>
                <div v-if='data.item.not_used_payments.length > 0'>
                    <b>Not Used Payments</b><br />
                    <div v-for='(paymentPart, index) in data.item.not_used_payments' :key='index'>
                        <div class='badge badge-light'>
                            {{ paymentPart.amount | currency }}
                        </div>
                        <div class='badge badge-light ml-2'>
                            <i class='fa fa-calendar'></i>
                            {{ paymentPart.cash_payment.date }}
                        </div>
                    </div>
                </div>
                <div v-if='data.item.in_progress_transactions.length > 0'>
                    <b>In Progress</b><br>
                    <div v-for="(transaction, index) in data.item.in_progress_transactions" :key='index'>
                        <div class='badge badge-warning'>
                            <i class="fa fa-spinner"></i>
                            {{ transaction.title }}
                        </div>
                        <div class='badge badge-light ml-2'>
                            <i class='fa fa-calendar'></i>
                            {{ transaction.date }}
                        </div>
                    </div>
                    <hr>
                </div>

                <div v-if='data.item.unpaid_transactions.length > 0'>
                    <b>Unpaid</b><br>
                    <div v-for="(transaction, index) in data.item.unpaid_transactions" :key='index'>
                        <div class='badge badge-danger'>
                            <i class="fa fa-credit-card"></i>
                            {{ transaction.title }}
                        </div>
                        <div class='badge badge-danger ml-2'>
                            <i class="fa fa-credit-card"></i>
                            {{ transaction.amount_paid }} / {{ transaction.amount }}
                        </div>
                        <div class='badge badge-light ml-2'>
                            <i class='fa fa-calendar'></i>
                            {{ transaction.date }}
                        </div>
                    </div>
                    <hr>
                </div>
                <div v-if='data.item.reserved_transactions.length > 0'>
                    <b>Reserved</b><br>
                    <div v-for="(transaction, index) in data.item.reserved_transactions" :key='index'>
                        <div class='badge badge-light'>
                            {{ transaction.title }}
                        </div>
                        <div class='badge badge-light ml-2'>
                            <i class="fa fa-credit-card"></i>
                            {{ transaction.amount_paid }} / {{ transaction.amount }}
                        </div>
                        <div class='badge badge-light ml-2'>
                            <i class='fa fa-calendar'></i>
                            {{ transaction.date }}
                        </div>
                    </div>
                    <hr>
                </div>
                <div v-if='data.item.non_sold_items.length > 0'>
                    <b>Delivered without sale</b><br>
                    <div v-for="(entityItem, index) in data.item.non_sold_items" :key='index'>
                        <div class='badge badge-danger'>
                            <i class='fa fa-truck-loading' />
                            {{ entityItem.item.name }}
                        </div>
                        <div class='badge badge-danger ml-1'>
                            {{ entityItem.quantity }}
                        </div>
                        <div class='badge badge-danger ml-2'>
                            <i class='fa fa-calendar'></i>
                            {{ entityItem.date }}
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:cell(in_progress_transactions)='data'>
                <div v-for="(transaction, index) in data.item.in_progress_transactions" :key='index'>
                    <div class='badge badge-warning'>
                        <i class="fa fa-spinner"></i>
                        {{ transaction.title }}
                    </div>
                    <div class='badge badge-light ml-2'>
                        <i class='fa fa-calendar'></i>
                        {{ transaction.date }}
                    </div>
                </div>
            </template>

            <template v-slot:cell(sales)='data'>
                <div v-for="(entitySaleable, index) in data.item.entity_saleables" :key='index'>
                    <div class='badge badge-light'>
                        <i class='fa fa-cart' />
                        {{ entitySaleable.description }}
                    </div>
                    <br>
                    <div v-if='entitySaleable.started_at != entitySaleable.finished_at' class='badge badge-light ml-2'>
                        <i class='fa fa-calendar'></i>
                        {{ entitySaleable.started_at }}
                        |
                        {{ entitySaleable.finished_at }}
                    </div>
                    <div v-else class='badge badge-light ml-2'>
                        <i class='fa fa-calendar'></i>
                        {{ entitySaleable.started_at }}
                    </div>
                    <hr>

                </div>
            </template>
            <template v-slot:cell(non_sold_items)='data'>
                <div v-for="(entityItem, index) in data.item.non_sold_items" :key='index'>
                    <div class='badge badge-danger'>
                        <i class='fa fa-truck-loading' />
                        {{ entityItem.item.name }}
                    </div>
                    <div class='badge badge-danger ml-2'>
                        <i class='fa fa-calendar'></i>
                        {{ entityItem.date }}
                    </div>
                </div>
            </template>

            <template v-slot:cell(reserved_transactions)='data'>
                <div v-for="(transaction, index) in data.item.reserved_transactions" :key='index'>
                    <b>Reserved</b><br>
                    <div class='badge badge-light'>
                        {{ transaction.title }}
                    </div>
                    <div class='badge badge-light ml-2'>
                        <i class="fa fa-credit-card"></i>
                        {{ transaction.amount_paid }} / {{ transaction.amount }}
                    </div>
                    <div class='badge badge-light ml-2'>
                        <i class='fa fa-calendar'></i>
                        {{ transaction.date }}
                    </div>
                </div>
            </template>

            <template v-slot:cell(unpaid_transactions)='data'>
                <div v-for="(transaction, index) in data.item.unpaid_transactions" :key='index'>
                    <div class='badge badge-danger'>
                        <i class="fa fa-credit-card"></i>
                        {{ transaction.title }}
                    </div>
                    <div class='badge badge-light ml-2'>
                        <i class='fa fa-calendar'></i>
                        {{ transaction.date }}
                    </div>
                </div>
            </template>

            <template v-slot:cell(phone_numbers)='data'>
                <div v-for='(entityPhone, index) in data.item.entity_phones' :key='index'>
                    <div class='badge badge-success'>
                        {{ entityPhone.name }}:
                        {{ entityPhone.number }}
                    </div>
                    <div class='badge badge-success ml-2' v-if='entityPhone.id == data.item.primary_phone_id'>
                        primary phone
                    </div>
                </div>
            </template>

            <template v-slot:cell(transaction_totals)='data'>
                <div v-for='(item, index) in data.item.transactions_totals' :key='index'>
                    <div class='badge badge-light m-1'>
                        {{ item.transaction_type }}
                    </div>
                    <div class='badge badge-light m-1'>
                        {{ item.status }}
                    </div>
                    <div class="badge m-1 badge-success"
                        v-if='item.transaction_type == "cash in" || item.type == "cash out"'>
                        {{ item.total }}
                    </div>
                    <div class='badge m-1' v-else :class="item.paid == item.total ? 'badge-success' : 'badge-danger'"
                        title='paid/total'>
                        {{ item.paid }} /
                        {{ item.total }}
                    </div>
                    <div class='badge badge-light m-1' v-if='item.remaining > 0'>
                        remaining:
                        {{ item.remaining }}
                    </div>
                </div>
            </template>

            <template v-slot:cell(delivered)='data'>
                <div v-if='data.item.items_quantity'>
                    <div v-for='(item, index) in data.item.items_quantity' :key='index'>
                        <div class='badge badge-success'>
                            {{ item.item.name }}
                        </div>
                        <Progress style='width:100px;' v-if='item.used_quantity || item.available_quantity'
                            :used="item.used_quantity" :total="item.used_quantity + item.available_quantity" />

                        <Progress style='width:100px; margin-top:5px;' v-if='item.over_used_quantity'
                            :used="item.over_used_quantity" :total="0" />
                    </div>
                </div>

                <div v-if='data.item.non_sold_items.length > 0'>
                    <b>Delivered without sale</b><br>
                    <div v-for="(entityItem, index) in data.item.non_sold_items" :key='index'>
                        <div class='badge badge-danger'>
                            <i class='fa fa-truck-loading' />
                            {{ entityItem.item.name }}
                        </div>
                        <div class='badge badge-danger ml-1'>
                            {{ entityItem.quantity }}
                        </div>
                        <div class='badge badge-danger ml-2'>
                            <i class='fa fa-calendar'></i>
                            {{ entityItem.date }}
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:cell(class)='data'>
                {{ (data.item.kid && data.item.kid.class_room) ? data.item.kid.class_room.name : '' }}
            </template>

            <template v-slot:cell(balance)='data'>
                <div v-if='unpaid(data.item)' class='badge badge-danger'>
                    {{ unpaid(data.item) | currency }}
                </div>

                <div v-if='unusedPayments(data.item)' class='badge badge-success'>
                    {{ unusedPayments(data.item) | currency }}
                </div>

            </template>
            <template v-slot:cell(entity_saleable_items)='data'>
                <div v-for='(item, index) in data.item.entity_saleable_items' :key='index'>
                    <div class='badge badge-warning'>
                        {{ item.entity_saleable.description }}
                    </div>
                    <div class='badge badge-warning m-2' :title='"from " + item.start_date + " to " + item.end_date'>
                        {{ item.item.name }}
                    </div>
                    <br>
                    <Progress style='width:100px;' :used="item.used_quantity" :total="item.total_quantity" />
                    <button class='btn btn-xxs btn-success m-2'
                        @click="modal('entityItemsList', { filter: { entity_saleable_item_id: item.id } }, 'refresh')"><i
                            class='fa fa-truck-loading'></i> </button>

                </div>
            </template>
            <template v-slot:cell(actions)='data'>
                <button class='btn btn-xs btn-primary ml-2'
                    @click="modal('entityNoteForm', { entityId: data.item.id }, 'refresh')"><i class="fas fa-sticky-note"></i>
                </button>
                <button v-if='can("sales.create")' class='btn btn-xs btn-primary ml-2'
                    @click="modal('entitySaleablesForm', { entityId: data.item.id, is_purchase: data.item.type == 'supplier' ? 1 : 0 }, 'refresh')"
                    title='New Sale/Purchase'><i class='fa fa-shopping-cart'></i></button>
                <button v-if='can("delivery.create")' class='btn btn-xs btn-primary ml-1'
                    @click="modal('itemsDeliveryForm', { entityIds: [data.item.id] }, 'refresh')"><i
                        class='fa fa-truck-loading'></i></button>

                <button v-if='can("cash_payments.create")' class='btn btn-xs btn-primary ml-2'
                    @click="modal('cashPaymentForm', { entityId: data.item.id }, 'refresh')"><i
                        class='fa fa-credit-card'></i></button>
                <button v-if='can("cash_payments.create")' class='btn btn-xs btn-primary ml-1'
                    @click="modal('cashReturnForm', { entityId: data.item.id }, 'refresh')"><i class='fa fa-undo'></i> <i
                        class='fa fa-credit-card'></i></button>

                <span v-if='can("entities.edit")'>
                    <button class='btn btn-xs btn-primary ml-2' v-if='data.item.type == "kid"'
                        @click="modal('kidForm', { id: data.item.kid.id }, 'refresh')"><i class='fa fa-edit'></i> </button>
                    <button class='btn btn-xs btn-primary ml-2' v-else
                        @click="modal('entityForm', { id: data.item.id }, 'refresh')"><i class='fa fa-edit'></i> </button>
                </span>

            </template>
        </base-list>
    </div>
</template>

<script>
import Progress from '../Progress.vue';
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
    props: ['viewType'],
    components: { BaseList, Progress },
    extends: BaseListMethods,
    data() {
        return {
            fields: [
                { key: 'id', label: 'ID' },
                { key: 'name', label: 'Name' },
                { key: 'class', label: 'Class' },
                { key: 'balance', label: 'Balance' },
                { key: 'delivered', label: 'Delivered' },
                { key: 'sales', label: 'Sales' },
                // { key: 'type', label: 'Type' },
                // { key: 'balance', label: 'Balance' },
                // { key: 'active_transactions', label: 'Active' },
                // { key: 'entity_saleable_items', label: 'Items' },
                // { key: 'in_progress_transactions', label: 'In Progress' },
                // { key: 'unpaid_transactions', label: 'Unpaid' },
                // { key: 'reserved_transactions', label: 'Reserved' },
                // { key: 'non_sold_items', label: 'Not Sale' },
                { key: 'actions', label: 'Actions' },
            ]
        }
    },
    methods: {
        load: function (apiClient, cancelToken, filter) {
            if (filter.has_sales == 1) {
                if (filter.date) {
                    filter.entity_saleable_items = { date: filter.date };
                } else {
                    filter.entity_saleable_items = {};
                }
            }
            return apiClient.get('entities', filter, cancelToken);
        },
        deleteEntity: function (entity) {
            return this.apiRequest(apiClient => apiClient.delete('entities/' + entity.id));
        },
        unpaid: function (entity) {
            return entity.unpaid_transactions.reduce(function (accumulator, a) {
                return accumulator + parseFloat(a.amount_remaining);
            }, 0)
        },
        unusedPayments: function (entity) {
            return entity.not_used_payments.reduce(function (accumulator, a) {
                return accumulator + parseFloat(a.amount);
            }, 0);
        }
    },
    watch: {

        // viewType: {
        // 	immediate: true,
        // 	handler: function () {
        // 	}
        // },
    }
}
</script>