<template>
    <div>
        <b-form-group label="Date Range">
            <my-date-range-picker v-model='dateRange' :style='{display:"inline-block", "vertical-align": "middle"}'/>
            <div class='btn btn-danger btn-xxs ml-2' @click='() => dateRange = {startDate: null, endDate: null}'>x</div>
        </b-form-group>
            <b-row>
                <b-col cols=3>
                    <b-card title="Payments" v-if='cash_payments_summary'>
                        <b>Total In:</b> {{ cash_payments_summary.total_in | currency }}
                        <br>
                        <b>Total Out:</b> {{ cash_payments_summary.total_out | currency }}
                        <br>
                        <b>Balance In:</b> {{ cash_payments_summary.total_balance_in | currency }}
                        <br>
                        <b>Balance Out:</b> {{ cash_payments_summary.total_balance_out | currency }}
                        <br>
                        <b>Total Unpaid Sales:</b> {{ transactions_summary.sales_unpaid }}
                        <br>
                        <b>Total Unpaid Purchases:</b> {{ transactions_summary.purchases_unpaid }}
                    </b-card>
                </b-col>
                <b-col>
                    <b-card title="Services / Products">
                        <div v-for='(data, index) in items_summary' :key='index'>
                            <div>
                                <div class='badge badge-success m-2'>{{data.item.name}}</div>
                                <div class='badge badge-success m-2'> Stock: {{parseFloat(data.item.quantity_in) - parseFloat(data.item.quantity_out)}} item</div>
                            </div>
                            <div v-if='data.in && data.in.saleable_item_summary' class="ml-4">
                                    <div class="badge badge-light"><b><i class='fa fa-arrow-down'></i> In</b></div>
                                    <b class="m-2">{{ data.in.entity_item_summary ? data.in.entity_item_summary.in_entities_count : 0 }} Delivered / {{ data.in.saleable_item_summary.entities_count }} Entities</b>
                                    <b class="m-2">{{ data.in.entity_item_summary ? data.in.entity_item_summary.quantity_in : 0 }} delivered <small>for selected period</small>  | {{ data.in.saleable_item_summary.total_used }} delivered / {{ data.in.saleable_item_summary.total_quantity }} total items | {{ data.in.saleable_item_summary.total_remaining }} remaining items </b>
                            </div>

                            <div v-if='data.out && data.out.saleable_item_summary' class="m-4">
                                <div class="badge badge-light"><b><i class='fa fa-arrow-up'></i> Out</b></div>
                                    <b class="m-2">{{ data.out.entity_item_summary ? data.out.entity_item_summary.out_entities_count : 0 }} Delivered / {{ data.out.saleable_item_summary.entities_count }} Entities</b>
                                    <b class="m-2">{{ data.out.entity_item_summary ? data.out.entity_item_summary.quantity_out : 0 }} delivered <small>for selected period</small>  | {{ data.out.saleable_item_summary.total_used }} delivered / {{ data.out.saleable_item_summary.total_quantity }} total items | {{ data.out.saleable_item_summary.total_remaining }} remaining items </b>
                                </div>


                            <div v-if='data.in_no_sale' class="ml-4">
                                <div class='badge badge-danger'>In Without Sale</div>
                                <div v-if='data.in_no_sale'>
                                    <b class="m-2">{{ data.in_no_sale.in_entities_count}} Delivered Entities</b>
                                    <b class="m-2">{{ data.in_no_sale.quantity_in}} delivered total items</b>
                                </div>
                            </div>
                    

                            <div v-if='data.out_no_sale' class="ml-4">
                                <div class='badge badge-danger'>Out Without Sale</div>
                                <div v-if='data.out_no_sale'>
                                    <b class="m-2">{{ data.out_no_sale.out_entities_count}} Delivered Entities</b>
                                    <b class="m-2">{{ data.out_no_sale.quantity_out}} delivered total items</b>
                                </div>
                            </div>
                            <hr/>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            dateRange: {
                startDate: Date.now(), 
                endDate: Date.now()
            },
            summary: null,
            cash_payments_summary: null,
            transactions_summary: null,
            entity_saleables_items_summary: null,
            items_summary: {}
        };
    },
    mounted() {
        this.load();
    },
    methods: {
        load: function () {
            this.items_summary = {};
            this.apiRequest(apiClient => {
                var startDate = this.dateRange.startDate ? this.toDate(this.dateRange.startDate) : null;
    			var endDate = this.dateRange.endDate ? this.toDate(this.dateRange.endDate) : null; 
                var filter = {...this.filter, 'summary': 1};
                if (startDate && endDate) {
                    filter = {...filter, start_date: startDate, end_date: endDate};
                }

                return apiClient.get('cash-payments', filter);
            }).then(response => {
                var item = response.data[0] ?? null;
                this.cash_payments_summary = {
                    total_in: item ? item.total_in : 0,
                    total_out: item ? item.total_out : 0,
                    total_balance_in: item ? item.total_balance_in : 0,
                    total_balance_out: item ? item.total_balance_out : 0
                };
            });

            this.apiRequest(apiClient => { 
                var filter = {'summary': 1, 'group_by_type': 1, 'not_canceled': 1};
                return apiClient.get('transactions', filter);
            }).then(response => {
                var sale = response.data.find(item => item.type == 'sale' ? true : false) ?? null;
                var purchase = response.data.find(item => item.type == 'purchase' ? true : false) ?? null;
                this.transactions_summary = {
                    sales_unpaid: sale ? sale.amount_remaining : 0,
                    purchases_unpaid: purchase ? purchase.amount_remaining : 0,
                };
            });


            this.apiRequest(apiClient => { 
                var startDate = this.dateRange.startDate ? this.toDate(this.dateRange.startDate) : null;
    			var endDate = this.dateRange.endDate ? this.toDate(this.dateRange.endDate) : null; 
                var filter = {...this.filter, 'summary': 1, 'group_by_item_id': 1, "available": 1};
                if (startDate && endDate) {
                    filter = {...filter, start_date: startDate, end_date: endDate};
                }
                return apiClient.get('entity-saleable-items', filter);
            }).then(response => {
                this.entity_saleables_items_summary = response.data;
                response.data.forEach(element => {
                    if (!this.items_summary[element.item_id]) {
                        this.items_summary[element.item_id] = {item: element.item};
                    }
                    if (!this.items_summary[element.item_id][element.direction]) {
                        this.items_summary[element.item_id][element.direction] = {
                            saleable_item_summary: null,
                            entity_item_summary: null 
                        };
                    }
                    this.items_summary[element.item_id][element.direction].saleable_item_summary = element;
                });
                this.items_summary = {...this.items_summary};
            });



            this.apiRequest(apiClient => { 
                var startDate = this.dateRange.startDate ? this.toDate(this.dateRange.startDate) : null;
    			var endDate = this.dateRange.endDate ? this.toDate(this.dateRange.endDate) : null; 
                var filter = {...this.filter, 'summary': 1, 'group_by_item_id': 1, 'group_by_sale': 1};
                if (startDate && endDate) {
                    filter = {...filter, start_date: startDate, end_date: endDate};
                }
                return apiClient.get('entity-items', filter);
            }).then(response => {
                this.entity_saleables_items_summary = response.data;
                response.data.forEach(element => {
                    if (!this.items_summary[element.item_id]) {
                        this.items_summary[element.item_id] = {item: element.item};
                    }
                    if (element.has_sale) {
                        if (element.quantity_in != 0) {
                            if (!this.items_summary[element.item_id]['in']) {
                                this.items_summary[element.item_id]['in'] = {
                                    saleable_item_summary: null,
                                    entity_item_summary: null 
                                };
                            }
                            this.items_summary[element.item_id]['in'].entity_item_summary = element;
                        }

                        if (element.quantity_out != 0) {

                            if (!this.items_summary[element.item_id]['out']) {
                                this.items_summary[element.item_id]['out'] = {
                                    saleable_item_summary: null,
                                    entity_item_summary: null 
                                };
                            }
                            this.items_summary[element.item_id]['out'].entity_item_summary = element;
                        }
                    } else {
                        if (element.quantity_in != 0) {
                            this.items_summary[element.item_id]['in_no_sale'] = element;
                        }

                        if (element.quantity_out != 0) {
                            this.items_summary[element.item_id]['out_no_sale'] = element;
                        }
                    }

                });
                this.items_summary = {...this.items_summary};
            });

            this.apiRequest(apiClient => {
                var startDate = this.dateRange.startDate ? this.toDate(this.dateRange.startDate) : null;
    			var endDate = this.dateRange.endDate ? this.toDate(this.dateRange.endDate) : null; 
                var filter = this.filter
                if (startDate && endDate) {
                    filter = {...filter, start_date: startDate, end_date: endDate};
                }

                return apiClient.get('summary/view', filter);
            }).then(response => this.summary = response);
        },
        extractPaymentType: function (type) {
            if (!this.summary) return 0;
            return this.summary.cash_payments
                .filter(item => item.type == type ? true : false)
                .reduce((total, item) => total + parseFloat(item.total), 0);
        }
    },

    watch: {
		dateRange: {
            immediate: true,
            handler: function () {
                var startDate = this.dateRange.startDate ? this.toDate(this.dateRange.startDate) : null;
                var endDate = this.dateRange.endDate ? this.toDate(this.dateRange.endDate) : null; 

                if (this.filter && startDate == this.filter.start_date && endDate == this.filter.end_date) {
                    return;
                } 
                this.load();
            }
        },
    },
    computed: {
        totalIn: function () {
            return this.extractPaymentType('cash-in');
        },
        totalOut: function () {
            return this.extractPaymentType('cash-out');
        },
        balanceIn: function () {
            return this.extractPaymentType('balance-in');
        },
        balanceOut: function () {
            return this.extractPaymentType('balance-out');
        },
    }

}
</script>