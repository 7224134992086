<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject' @pageChanged='pageChanged'>
            
            <template v-slot:cell(name)='data'>
                <div>

                    <router-link :to="{ name: 'ClassRoomView', params: {id: data.item.id}}" class=''>
                        <h5>
                        {{ data.item.name }}
                        </h5>
                    </router-link>
                </div>
            </template>
            <template v-slot:cell(capacity)='data'>
                <div>
                    <div class='badge badge-light m-2'>
                        Delivred: {{ data.item.delivered_kids_count }}
                    </div>
                    <br>
                    <div class='badge badge-light m-2'>
                        Active: {{ data.item.active_kids_count }}
                    </div>
                    <br>
                    <div class='badge badge-light m-2'>
                        Capacity: {{ data.item.capacity }}
                    </div>
                </div>
            </template>
			<template v-slot:cell(status)='data'>
                <div v-if="data.item.is_active" class='badge badge-success'>Active</div>
                <div v-else class='badge badge-light'>inactive</div>
            </template>
			<template v-slot:cell(actions)='data'>
                <b-button v-if='can("delivery.create")' @click="modal('itemsDeliveryForm', {entityType: 'kid', classRoomId: data.item.id}, 'refresh')" variant='primary' class='mr-2'>
                    Deliver
                </b-button>
				<button v-if='can("classes.edit")' class='btn btn-xs btn-primary m-2' @click="modal('classRoomForm', {id: data.item.id}, 'refresh')">Edit</button>
            </template>
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
	props: ['viewType'],
    components: { BaseList },
    extends: BaseListMethods,
    data() {
        return {
			fields: [
                { key: 'id', label: 'ID' },
                { key: 'name', label: 'Name' },
                { key: 'capacity', label: 'Capacity' },
                { key: 'status', label: 'Status' },
                { key: 'actions', label: 'Actions' },
			]
        }
    },
	methods: {
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('class-rooms', filter, cancelToken);
		}
    },
    watch: {

		// viewType: {
		// 	immediate: true,
		// 	handler: function () {
		// 	}
		// },
    }
}
</script>