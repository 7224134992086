<template>
    <div>
        <ListView title="EntityNotes List" :modalObject='currentModalObject' >
            <template v-slot:actions>
                <b-button @click="modal('entityNoteForm', {entityId: entityId}, 'refreshTable')" variant='primary' class='mr-2'>
                    Create EntityNote
                </b-button>

            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>
                    </div>
                </div>
            </template>

			<template v-slot:table>
				<EntityNotesListTable :filter='filter' ref='tableList' :viewType='viewType'></EntityNotesListTable>
			</template>
                    
        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import EntityNotesListTable from '@/components/Lists/EntityNotesListTable';

export default {
    props: ['entityId'],
    extends: BaseList,
    components: {EntityNotesListTable},
    methods: {
        refreshTable () {
            this.$refs.tableList.refresh();
        }
    },
    watch: {
        entityId: {
            immediate: true,
            handler:
                function () {
                    this.filter.entity_id = this.entityId;
                    if (this.entityId) {
                        this.viewType = 'entity';
                    }
                }
        }
    }
}
</script>
