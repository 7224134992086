<template>
    <div>
        <ListView title="PriceGroups List" :modalObject='currentModalObject' >
            <template v-slot:actions>
                <b-button @click="modal('priceGroupForm', {}, 'refreshTable')" variant='primary' class='mr-2'>
                    Create PriceGroup
                </b-button>

            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>
                    </div>
                </div>
            </template>

			<template v-slot:table>
				<PriceGroupsListTable :filter='filter' ref='tableList' :viewType='viewType'></PriceGroupsListTable>
			</template>
                    
        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import PriceGroupsListTable from '@/components/Lists/PriceGroupsListTable';

export default {
    extends: BaseList,
    components: {PriceGroupsListTable},
    methods: {
        refreshTable () {
            this.$refs.tableList.refresh();
        }
    }
}
</script>
