<template>
    <div>
        <ListView title="EntitySaleableItems List" :modalObject='currentModalObject' >
            <template v-slot:actions>

            </template>

            <template v-slot:filter>
                <div class='form'>
                    <b-form-row>
                        <b-col cols='3'>
                            <b-form-group label="Date Range">
                                <my-date-range-picker v-model='dateRange' :style='{display:"inline-block", "vertical-align": "middle"}'/>
                                <div class='btn btn-danger btn-xxs ml-2' @click='() => dateRange = {startDate: null, endDate: null}'>x</div>
                            </b-form-group>
                        </b-col>

                        <b-col cols='3'>
                            <b-form-group label='Status'>
                                <v-select v-model='filter.status' :multiple='true' :options="['pending', 'in progress', 'finished']"/>
                            </b-form-group>
                        </b-col>

                        <b-col cols='3'>
                            <b-form-group label='Service / product'>
                                <items-select  v-model='filter.item_id' :returnId='true'/>
                            </b-form-group>
                        </b-col>
                        <b-col cols='3'>
                            <b-form-group label='Saleable'>
                                <saleables-select  v-model='filter.saleable_id' :returnId='true'/>
                            </b-form-group>
                        </b-col>
                        <b-col cols='3'>
                            <b-form-group label='Saleable Group'>
                                <saleable-group-select  v-model='filter.saleable_group_id' :returnId='true'/>
                            </b-form-group>
                        </b-col>
                    </b-form-row>

                    <b-form-group>
                        <b-form-checkbox
                            v-model="filter.summary"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Summary
                        </b-form-checkbox>
                    </b-form-group>
                    <b-form-group label="Summary Options" v-if='filter.summary == 1'>
                        <b-form-checkbox
                            v-model="filter.group_by_item_id"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Group By Service / Product
                        </b-form-checkbox>
                        <b-form-checkbox
                            v-model="filter.group_by_saleable_id"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Group By Sale
                        </b-form-checkbox>

                        <b-form-checkbox
                            v-model="filter.group_by_status"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Group By Status
                        </b-form-checkbox>

                        <b-form-checkbox
                            v-model="filter.group_by_saleable_group_id"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Group By Saleable group
                        </b-form-checkbox>

                    </b-form-group>
                </div>
            </template>

			<template v-slot:table>
				<entity-saleable-items-summary-list-table v-if='filter.summary == "1"' :filter='filter' ref='tableList' :viewType='viewType'></entity-saleable-items-summary-list-table>
				<EntitySaleableItemsListTable v-else :filter='filter' ref='tableList' :viewType='viewType'></EntitySaleableItemsListTable>
			</template>
                    
        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import EntitySaleableItemsListTable from '@/components/Lists/EntitySaleableItemsListTable';
import EntitySaleableItemsSummaryListTable from '../../components/Lists/EntitySaleableItemsSummaryListTable.vue';
import SaleableGroupSelect from '../../components/Select/SaleableGroupSelect.vue';
import SaleablesSelect from '../../components/Select/SaleablesSelect.vue';
import ItemsSelect from '../../components/Select/ItemsSelect.vue';

export default {
    extends: BaseList,
    components: {EntitySaleableItemsListTable, EntitySaleableItemsSummaryListTable, SaleableGroupSelect, SaleablesSelect, ItemsSelect},
    props: ['entityId'],
    methods: {
        refreshTable () {
            this.$refs.tableList.refresh();
        }
    },
    watch: {
        entityId: {
            immediate: true,
            handler:
                function () {
                    this.filter.entity_id = this.entityId;
                }
        }
    }
}
</script>
