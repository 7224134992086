<template>
    <div>
        <ListView title="Items List" :modalObject='currentModalObject' >
            <template v-slot:actions>
                <b-button @click="modal('itemForm', {sale: true, type: 'service', soldTo: 'kid'}, 'refreshTable')" variant='primary' class='mr-2'>
                    Create Kids Service
                </b-button>
                <b-button @click="modal('itemForm', {sale: true, purchase: true, type: 'product', soldTo: 'kid'}, 'refreshTable')" variant='primary' class='mr-2'>
                    Create Kids Product
                </b-button>

                <b-button @click="modal('itemForm', {purchase: true, type: 'service'}, 'refreshTable')" variant='primary' class='mr-2'>
                    Create External Service
                </b-button>
            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>

                        <div class='form-group col-md-4'>
                            <v-select :options="typesOptions" v-model='filter.type'></v-select>
                        </div>
                    </div>

                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-group label="Status">
                                <b-form-radio-group
                                    v-model="filter.status"
                                    :options="['all', 'active', 'inactive']"
                                ></b-form-radio-group>
                            </b-form-group>
                        </div>
                    </div>
                </div>
            </template>

			<template v-slot:table>
				<ItemsListTable :filter='filter' ref='tableList' :viewType='viewType'></ItemsListTable>
			</template>
                    
        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import ItemsListTable from '@/components/Lists/ItemsListTable';

export default {
    extends: BaseList,
    components: {ItemsListTable},
    props: ['type', 'entityType'],
    data: function () {
        return {
            typesOptions: ['sale', 'purchase', 'sale and purchase'],
            defaultFilter: {
                status: 'all'
            }
        }
    },
    methods: {
        refreshTable () {
            this.$refs.tableList.refresh();
        }
    }
}
</script>
