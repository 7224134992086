<template>
    <div>
        <v-select
            @search="fetchOptions"
            @open="onOpen"
            :filterable='filterable'
            :options="options"
            :label='label'
            :value='myValue'
            v-on:input='updateValue'
            :multiple='multiple'
            :closeOnSelect='closeOnSelect'
        >
            <template #option="{ name, type }">
                {{ name }} 
                <div class='badge badge-light ml-2'>
                    {{ type }}
                </div>
            </template>
        </v-select>
    </div>
</template>

<script>
import BaseRemoteSelect from './BaseRemoteSelect.vue';

export default {
    extends: BaseRemoteSelect,
    data: function() {
        return {
            label: 'name'
        }
    },
    methods: {
        load(apiClient, search, cancelToken) {
            var filter = {
                keyword: search,
                is_active: 1
            };
            filter = {...filter, ...this.filter};
            return apiClient.get('entities', filter, cancelToken);
        },
        loadSingle(apiClient, id) {
            return apiClient.get('entities/'+id);
        }
    },
    watch: {
        sectionId: function () {
            this.fetchOptions('', true);
        },
        branchId: function () {
            this.fetchOptions('', true);
        }
    }
}
</script>
