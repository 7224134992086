<template>
    <div>
        <ListView title="Kids List" :modalObject='currentModalObject' >
            <template v-slot:actions>
                <b-button @click="modal('kidForm', {}, 'openKidProfile')" variant='primary' class='mr-2'>
                    Create Kid
                </b-button>
                <b-button @click="modal('itemsDeliveryForm', {entityType: 'kid', classRoomId: classRoomId}, 'refreshTable')" variant='primary' class='mr-2'>
                    Deliver Items
                </b-button>
            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>
                    </div>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-datepicker v-model="filter.date"></b-form-datepicker>
                        </div>
                    </div>
                    <b-row v-if='!classRoomId'>
                        <b-col cols=3>
                            <b-form-group label="Class">
                                <class-rooms-select v-model='filter.class_room_id' :returnId='true'/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-form-group label="Status">
                        <b-form-radio-group
                            v-model="filter.status"
                            :options="['all', 'active', 'inactive', 'has delivered items']"
                        ></b-form-radio-group>
                    </b-form-group>
                </div>
            </template>

			<template v-slot:table>
				<KidsListTable :filter='filter' ref='tableList' :viewType='viewType'></KidsListTable>
			</template>
                    
        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import KidsListTable from '@/components/Lists/KidsListTable';
import ClassRoomsSelect from '../../components/Select/ClassRoomsSelect.vue';

export default {
    extends: BaseList,
    props: ['classRoomId'],
    components: {
        KidsListTable,
        ClassRoomsSelect
    },
    data: function () {
        return {
            defaultFilter: {
                status: 'active'
            }
        }
    },
    methods: {
        refreshTable () {
            this.$refs.tableList.refresh();
        },
        openKidProfile (kid) {
            this.$router.push({name: 'ViewKid',  params: {id: kid.id}});
        }
    },
    watch: {
        classRoomId: {
            immediate: true,
            handler:
                function () {
                    this.filter.class_room_id = this.classRoomId;
                }
        }
    }
}
</script>
