import axios from 'axios';

class NurseryClient {
    
    constructor() {
        var baseUrl = process.env.VUE_APP_BASE_URL;
        this.baseurl = baseUrl;
        
        this.httpClient = axios.create({
            baseURL: this.baseurl,
            // withCredentials: true
        });

        this.csrf = null;
        this.cancelToken = this.cancelTokenSource();
        this.pendingRequests = [];
        this.user = null;

        var promiseResolve;
        this.userPromise =  new Promise(function(resolve){
            promiseResolve = resolve;
        });
        this.userPromiseResolve = promiseResolve;
    }

    setToken(token) {
        this.httpClient.defaults.headers = {
            Authorization: 'Bearer ' + token,
        };
        
    }

    setUser(user) {
        this.user = user;
    }

    getCookie() {
        if (this.csrf) {
            return Promise.resolve(() => true);
        }
        return this.httpClient.get('csrf-cookie').then(() => this.csrf = true)
            .catch(e => console.log(e.message));
    }

    async getNoCancel(endpoint, params) {
        return this.httpClient.get(endpoint, {
            params: params
        }).then(response => {
            return response.data;
        });
    }
    
    async get(endpoint, params, cancelTokenSource) {
        if (cancelTokenSource) {
            this.addCancelToken(cancelTokenSource);
        }
        
        return this.httpClient.get(endpoint, {
            params: params,
            cancelToken: cancelTokenSource ? cancelTokenSource.token : this.cancelToken.token
        }).then(response => {
            if (cancelTokenSource) {
                this.removeCancelToken(cancelTokenSource);
            }
            return response.data;
        });
    }

    async post(endpoint, body) {
        await this.getCookie();
        return this.httpClient.post(endpoint, body)
            .then(response => response.data);
    }

    async put(endpoint, body) {
        await this.getCookie();
        return this.httpClient.put(endpoint, body)
            .then(response => response.data);
    }

    async delete(endpoint, body = null) {
        await this.getCookie();
        return this.httpClient.delete(endpoint, {data: body})
            .then(response => response.data);
    }

    cancelTokenSource() {
        return axios.CancelToken.source();
    }

    cancel(cancelTokenSource) {
        this.removeCancelToken(cancelTokenSource);
        cancelTokenSource.cancel();
    }

    cancelAll() {
        this.cancelToken.cancel();
        this.cancelToken = this.cancelTokenSource();
        this.pendingRequests.forEach(item => item.cancel());
        this.pendingRequests = [];
    }

    addCancelToken(cancelTokenSource) {
        this.pendingRequests.push(cancelTokenSource);
    }

    removeCancelToken(cancelTokenSource) {
        var index = null;
        for (var i=0; i<this.pendingRequests.length; i++ ) {
            if (this.pendingRequests[i].token == cancelTokenSource.token) {
                index = i;
                break;
            }
        }

        if (index !== null) {
            this.pendingRequests.splice(index, 1);
        }
    }
}

export default NurseryClient;
