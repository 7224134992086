<template>
    <div>
    </div>
</template>
<script>
export default {
    props: ['filter'],
    mounted: function () {
        this.refresh();
    },
    data: function() {
        return {
            loading: false, 
            collection: {},
            page: 1,
            cancelTokenSource: null,
            hideFields: [],
            fields: []
        };
    },
    methods: {
        filterFields: function () {
			this.fields = this.fields.filter(item => {
                if (item.key) {
                    return this.hideFields.indexOf(item.key) > -1 ? false : true;
                } else {
                    return this.hideFields.indexOf(item) > -1 ? false : true;
                }
                
            });
        },
        refresh: function () {
            this.loading = true;
            
            this.apiRequest((apiCLient, cancelTokenSource) => {
                this.cancelTokenSource = cancelTokenSource
                return this.load(apiCLient, cancelTokenSource, {...this.filter, page: this.page});
            }, this.cancelTokenSource)
                .then((response) => {
                    if (response) {
                        this.collection = response;
                        this.loading = false;
                    }
                });
        },
        pageChanged(page) {
            this.page = page;
            this.refresh();
        },
        confirmAction(methodName, data) {
            this.confirm().then(
                accept => {
                    if (accept) {
                        if (typeof methodName === 'string' || methodName instanceof String) {
                            this[methodName](data).then(() => this.refresh());
                        } else {
                            methodName.call(data).then(() => this.refresh());
                        }
                    }
                }
            );
        }
    },
    watch: {
        filter: {
            handler() {
                this.page = 1;
                this.refresh();
            },
            deep: true
        },
		hideFields:{
			immediate: true,
			handler: function () {
				this.filterFields();
			}
		}
    }
}
</script>