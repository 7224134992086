<template>
    <div>
        
    </div>
</template>
<script>
export default {
    created() {
        this.$router.push({name: 'Kids'});
    }
}
</script>