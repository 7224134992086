<template>
    <div>
        <ListView :title="title" :modalObject='currentModalObject' hasFilterActions='true' >
            <template v-slot:actions>
                <div v-if='!entityId'>
                    <b-button @click="modal('entitySaleablesForm', {is_purchase: 0, entityType: 'kid'}, 'refreshTable')" variant='primary' class='mr-2'>
                        Create Kids Sale
                    </b-button>
                    <b-button @click="modal('entitySaleablesForm', {is_purchase: 0, entityType: 'customer'}, 'refreshTable')" variant='primary' class='mr-2'>
                        Create General Sale
                    </b-button>
                    <b-button @click="modal('entitySaleablesForm', {is_purchase: 1, entityType: 'supplier'}, 'refreshTable')" variant='primary' class='mr-2'>
                        Create Purchase
                    </b-button>
                </div>
                <div v-else>
                    <div v-if='entity'>
                        <b-button  v-if='entity.type != "supplier"'  @click="modal('entitySaleablesForm', {is_purchase: 0, entityId: entityId}, 'refreshTable')" variant='primary' class='mr-2'>
                            Create Sale
                        </b-button>
                        <b-button  v-if='entity.type == "supplier"' @click="modal('entitySaleablesForm', {is_purchase: 1}, 'refreshTable')" variant='primary' class='mr-2'>
                            Create Purchase
                        </b-button>
                    </div>
                </div>
            </template>

            <template v-slot:filter>
                <b-form>
                    <b-row>
                        <b-col cols='3'>
                            <b-form-group label="Date Range">
                                <my-date-range-picker v-model='dateRange' :style='{display:"inline-block", "vertical-align": "middle"}'/>
                                <div class='btn btn-danger btn-xxs ml-2' @click='() => dateRange = {startDate: null, endDate: null}'>x</div>
                            </b-form-group>
                        </b-col>
                        <b-col cols='3'>
                            <b-form-group label='entity'>
                                <entities-select  v-model='filter.entity_id' :returnId='true'/>
                            </b-form-group>
                        </b-col>
                        <b-col cols=3>
                            <b-form-group label="Class">
                                <class-rooms-select v-model='filter.class_room_id' :returnId='true'/>
                            </b-form-group>
                        </b-col>
                        <b-col cols='3'>
                            <b-form-group label='Saleables'>
                                <saleables-select v-model='filter.saleable_id' :returnId='true'/>
                            </b-form-group>
                        </b-col>
                        <b-col cols='3'>
                            <b-form-group label='Saleable Group'>
                                <saleable-group-select  v-model='filter.saleable_group_id' :returnId='true'/>
                            </b-form-group>
                        </b-col>
                        <b-col cols='3'>
                            <b-form-group label='Status'>
                                <v-select v-model='filter.status' :multiple='true' :options="['pending', 'in progress', 'finished']"/>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Payment Status">
                                <b-form-radio-group
                                    v-model="filter.payment_status"
                                    :options="['all', 'paid', 'unpaid']"
                                ></b-form-radio-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                

                    <b-form-group>
                        <b-form-checkbox
                            v-model="filter.summary"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Summary
                        </b-form-checkbox>
                    </b-form-group>
                    <b-form-group label="Summary Options" v-if='filter.summary == 1'>
                        <b-form-checkbox
                            v-model="filter.group_by_entity_type"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Group By Entity Type
                        </b-form-checkbox>
                        <b-form-checkbox
                            v-model="filter.group_by_entity_id"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Group By Entity
                        </b-form-checkbox>

                        <b-form-checkbox
                            v-model="filter.group_by_status"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Group By Status
                        </b-form-checkbox>

                        <b-form-checkbox
                            v-model="filter.group_by_saleable_id"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Group By Saleable
                        </b-form-checkbox>

                        <b-form-checkbox
                            v-model="filter.group_by_saleable_group_id"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Group By Saleable group
                        </b-form-checkbox>
                        <b-form-group label="Group By">
                            <b-form-radio-group
                                v-model="filter.group_by_period"
                                :options="['day', 'month', 'year', 'none']"
                            ></b-form-radio-group>
                        </b-form-group>

                    </b-form-group>
                </b-form>
            </template>

            <template v-slot:filter_actions>
                <authorized-url class='btn btn-primary ml-2 float-right'
                    url='entity-saleables/pdf' :params='filter' target="_blank">
                    <i class="fa fa-file-pdf"></i>
                    Print
                </authorized-url>
                <authorized-url class='btn btn-primary ml-2 float-right'
                    url='entity-saleables/export' :params='filter' target="_blank">
                    <i class="fa fa-file-excel"></i>
                    Export
                </authorized-url>
            </template>

			<template v-slot:table>
				<entity-saleables-summary-list-table v-if='filter.summary == "1"' :filter='filter' ref='tableList' :viewType='viewType'></entity-saleables-summary-list-table>
				<EntitySaleablesListTable v-else :filter='filter' ref='tableList' :viewType='viewType'></EntitySaleablesListTable>
			</template>
                    
        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import EntitySaleablesListTable from '@/components/Lists/EntitySaleablesListTable';
import EntitiesSelect from '../../components/Select/EntitiesSelect.vue';
import SaleablesSelect from '../../components/Select/SaleablesSelect.vue';
import EntitySaleablesSummaryListTable from '../../components/Lists/EntitySaleablesSummaryListTable.vue';
import ClassRoomsSelect from '../../components/Select/ClassRoomsSelect.vue';
import moment from 'moment';
import SaleableGroupSelect from '../../components/Select/SaleableGroupSelect.vue';
import AuthorizedUrl from '../../components/AuthorizedUrl.vue';

export default {
    extends: BaseList,
    props: ['entityId', 'type'],
    components: {EntitySaleablesListTable, EntitiesSelect, SaleablesSelect, EntitySaleablesSummaryListTable, ClassRoomsSelect, SaleableGroupSelect, AuthorizedUrl},
    methods: {
        refreshTable () {
            this.$refs.tableList.refresh();
        }
    },
    data: function () {
        return {
            entity: null,
            title: "Sales",
            defaultFilter: {
                start_date: moment().startOf('month').format('YYYY-MM-DD'),
                end_date: moment().endOf('month').format('YYYY-MM-DD'),
                group_by_saleable_id: 1,
                group_by_period: 'day',
            }
        };
    },
    watch: {
        entityId: {
            immediate: true,
            handler:
                function () {
                    this.filter.entity_id = this.entityId;

                    if (this.entityId) {
                        this.viewType='entity';
                        this.apiRequest(apiClient => apiClient.get('entities/'+this.entityId)).then(response => this.entity = response);
                    }
                }
        },
        type: {
            immediate: true,
            handler:
                function () {
                    this.title = this.type;
                    this.filter = {...this.filter, type: this.type};
                }
        }
    }
}
</script>
