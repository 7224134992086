import Vue from "vue";
import VueRouter from "vue-router";
import LoginForm from "../views/login/LoginForm"
import ResetPassword from "../views/ResetPassword/ResetPassword"
import SignupForm from "../views/Signup/SignupForm"
import EmptyLayout from "../layouts/EmptyLayout"
import Main from "../views/Main"
import KidsList from "../views/Kids/KidsList"
import ClassRoomView from "../views/Kids/ClassRoomView"
import KidsClassesList from "../views/Kids/KidsClassesList"
import EntitiesList from "../views/Entities/EntitiesList"
import KidView from "../views/Kids/KidView"
import EntityView from "../views/Entities/EntityView"
import ClassRoomsList from "../views/ClassRooms/ClassRoomsList"
import ItemsList from "../views/Items/ItemsList"
import InventoryList from "../views/Inventory/InventoryList"
import SaleablesList from "../views/Saleables/SaleablesList"
import TransactionsList from "../views/Transactions/TransactionsList"
import EntitySaleablesList from "../views/EntitySaleables/EntitySaleablesList"
import EntitySaleableItemsList from "../views/EntitySaleableItems/EntitySaleableItemsList"
import EntityItemsList from "../views/EntityItems/EntityItemsList"
import ItemsDeliveryPage from "../views/EntityItems/ItemsDeliveryPage"
import CashPaymentsList from "../views/CashPayments/CashPaymentsList"
import PriceGroupsList from "../views/PriceGroups/PriceGroupsList"
import SaleableGroupsList from "../views/SaleableGroups/SaleableGroupsList"
import SubscriptionsList from "../views/Subscriptions/SubscriptionsList"
import UsersList from "../views/Users/UsersList"
import RolesList from "../views/Roles/RolesList"
import SummaryList from "../views/Summary/SummaryList"
import SummaryView from "../views/Summary/SummaryView"
import Dashboard from "../views/Dashboard/Dashboard"

Vue.use(VueRouter);


const routes = [
  {
    path: "/",
    name: "Main",
    component: Main
  },
  {
    path: "/users",
    name: "Users",
    component: UsersList
  },
  {
    path: "/roles",
    name: "Roles",
    component: RolesList
  },
  {
    path: "/kids",
    name: "Kids",
    component: KidsList,
    props: { pushHistory: true }
  },
  {
    path: "/kids-classes",
    name: "KidsClasses",
    component: KidsClassesList,
    props: { pushHistory: true }
  },
  {
    path: "/classes/:id",
    name: "ClassRoomView",
    component: ClassRoomView,
    props: true
  },
  {
    path: "/accounts/entities",
    name: "Entities",
    component: EntitiesList,
    props: { pushHistory: true }
  },
  {
    path: "/accounts/kids",
    name: "Entities",
    component: EntitiesList,
    props: { pushHistory: true, type: 'kid' }
  },
  {
    path: "/accounts/customers",
    name: "Entities",
    component: EntitiesList,
    props: { pushHistory: true, type: 'customer' }
  },
  {
    path: "/accounts/suppliers",
    name: "Entities",
    component: EntitiesList,
    props: { pushHistory: true, type: 'supplier' }
  },
  {
    path: "/kids/:id",
    name: "ViewKid",
    component: KidView,
    props: true
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    component: SubscriptionsList,
    props: { pushHistory: true }
  },
  {
    path: "/entities/:id",
    name: "ViewEntity",
    component: EntityView,
    props: true
  },
  {
    path: "/class-rooms",
    name: "ClassRoom",
    component: ClassRoomsList,
    props: { pushHistory: true }
  },
  {
    path: "/items",
    name: "Items",
    component: ItemsList,
    props: { pushHistory: true }
  },
  {
    path: "/inventory",
    name: "Inventory",
    component: InventoryList,
    props: { pushHistory: true }
  },
  {
    path: "/saleables",
    name: "Saleables",
    component: SaleablesList,
    props: { pushHistory: true }
  },
  {
    path: "/entity-saleables",
    name: "EntitySaleables",
    component: EntitySaleablesList,
    props: { pushHistory: true }
  },
  {
    path: "/price-groups",
    name: "PriceGroups",
    component: PriceGroupsList,
    props: { pushHistory: true }
  },
  {
    path: "/entity-items",
    name: "EntityItems",
    component: EntityItemsList,
    props: { pushHistory: true }
  },
  {
    path: "/deliver-items",
    name: "EntityItemsDelivery",
    component: ItemsDeliveryPage,
    props: { pushHistory: true }
  },
  {
    path: "/sales",
    name: "EntitySaleables",
    component: EntitySaleablesList,
    props: { pushHistory: true, type: 'sales' }
  },
  {
    path: "/expenses",
    name: "EntitySaleables",
    component: EntitySaleablesList,
    props: { pushHistory: true, type: 'expenses' }
  },
  {
    path: "/sale-items",
    name: "EntitySaleableItems",
    component: EntitySaleableItemsList,
    props: { pushHistory: true, sale: 1 }
  },
  {
    path: "/transactions",
    name: "TransactionsList",
    component: TransactionsList,
    props: { pushHistory: true, sale: 1 }
  },
  {
    path: "/cash-payments",
    name: "CashPayments",
    component: CashPaymentsList,
    props: { pushHistory: true }
  },
  {
    path: "/summary",
    name: "Summary",
    component: SummaryList,
    props: { pushHistory: true }
  },
  {
    path: "/summary-view",
    name: "SummaryView",
    component: SummaryView,
    props: { pushHistory: true }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    props: { pushHistory: true }
  },
  {
    path: "/saleable-groups",
    name: "SaleableGroups",
    component: SaleableGroupsList,
    props: { pushHistory: true }
  },
  {
    path: "/login",
    name: "Login",
    component: LoginForm,
    meta: {
      layout: EmptyLayout,
      noAuth: true
    }
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      layout: EmptyLayout,
      noAuth: false
    }
  },
  {
    path: "/signup",
    name: "Signup",
    component: SignupForm,
    meta: {
      layout: EmptyLayout,
      noAuth: true
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});


router.beforeEach( async (to, from, next) => {
  let apiClient = Vue.GlobalApiClient;
  if (apiClient.userPromise) {
    await apiClient.userPromise;
  }
  apiClient.cancelAll();

  if (to.matched.some(record => !record.meta.noAuth)) {
    if (apiClient.user) {
      var user = apiClient.user;
      if (user.needs_password_reset && to.name != 'ResetPassword') {
        next('/reset-password');
        return;
      }
      if (to.matched.some(
        record => {
          if (!record.meta.permission) {
            return true;
          }
          if (apiClient.user) {
            var user = apiClient.user;
            if (user.role && user.role.permissions.indexOf(record.meta.permission) > -1) {
              return true;
            }
          }

        }
      )
      ) {
        next()
      }
      return
    }
    next('/login')
  } else {
    next()
  }
});

export default router;
