<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='currentFields' :modalObject='currentModalObject' @pageChanged='pageChanged'>
            
            <template v-slot:cell(saleable)='data'>
                <div>
                    {{ data.item.saleable.name }}
                </div>
            </template>

            <template v-slot:cell(saleable_group)='data'>
                <div v-if='data.item.saleable_group'>
                    {{ data.item.saleable_group.name }}
                </div>
            </template>
            <template v-slot:cell(status)='data'>
                <div class='badge' :class="statusBadge(data.item.status)">
                    {{ data.item.status }}
                </div>
            </template>
            <template v-slot:cell(entity)='data'>
                <div v-if='data.item.entity'>
                    <router-link v-if='data.item.entity.type == "kid"' :to="{ name: 'ViewKid', params: {id: data.item.id}}" class=''>
                        <h5>
                        {{ data.item.entity.name }}
                        </h5>
                    </router-link>
                    <div v-else>
                        {{ data.item.entity.name }}
                    </div>
                    <div class='badge badge-light'>
                        {{ data.item.entity.type }}
                    </div>
                </div>
            </template>
            
			<template v-slot:cell(discount)='data'>
                {{ data.item.price_no_discount - data.item.total_price | decimal }}
            </template>
			<template v-slot:cell(price_no_discount)='data'>
                {{ data.item.price_no_discount | currency }}
            </template>

			<template v-slot:cell(month)='data'>
                {{ data.item.year+"-"+data.item.month | moment("MMMM, YYYY") }}
            </template>

			<template v-slot:cell(date)='data'>
                {{ data.item.date | date }}
            </template>

			<template v-slot:cell(total_price)='data'>
                {{ data.item.total_price | currency }}
            </template>
			<template v-slot:cell(created_at)='data'>
                {{ data.item.created_at | date }}
            </template>
            
			<template v-slot:cell(item)='data'>
                {{ data.item.item.name }}
            </template>
            
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
	props: ['viewType'],
    components: { BaseList },
    extends: BaseListMethods,
    data() {
        return {
			fields: [
			],
            statusBadges: {
                "in progress": "badge-warning",
                "finished": "badge-success",
                "pending": "badge-light",
            },
            currentFields: []
        }
    },
	methods: {
        statusBadge: function(status) {
            return this.statusBadges[status] ? this.statusBadges[status] : "badge-light";
        },
        load: function (apiClient, cancelToken, filter) {   
			return apiClient.get('transactions', filter, cancelToken);
		},
    },
    watch: {
        
    }
}
</script>