
<script>
export default {
    // inject: ['apiRequest'],
    props: ['value', 'multiple', 'closeOnSelect', 'reloadOnOpen', 'id', 'returnId', 'filter'],
    data: function() {
        return {
            options: [],
            label: 'name',
            filterable: false,
            selectedOption: null,
            cancelToken: null,
            loading: false,
            lastSearch: null,
            optionsData: [],
            myValue: null
        }
    },
    methods: {
        fetchOptions (search, force) {
            if (this.lastSearch == search && !force) {
                return;
            }
            this.lastSearch = search;
            this.loading = true;
            return this.apiRequest((apiClient, cancelToken) => {
                if (this.cancelToken) {
                    apiClient.cancel(this.cancelToken);
                }
                
                this.cancelToken = cancelToken;
                return this.load(apiClient, search, cancelToken);
            }).then(response => {
                    if (!response) {
                        return;
                    }
                    this.options = response.data;
                    this.cancelToken = null;
                    this.loading = false;
                });
        },
        updateValue: function (val) {
            if (this.returnId && val && val.id) {
                this.$emit('input', val.id)
            } else {
                this.$emit('input', val)
            }
        },
        onOpen() {
            this.fetchOptions('');
        }
    },
    watch: {
        value: {
            immediate: true,
            handler() {
                if (
                    !this['loadSingle'] ||
                    !this.value ||
                    this.value[this.label] ||
                    ((typeof this.value === 'object') && !this.value.id)
                ) {
                    this.myValue = this.value;
                    return;
                }

                var id = (typeof this.value === 'object') ? this.value.id : this.value;
                var foundValue = this.options.find(item => item.id == id ? true : false);
                if (foundValue) {
                    this.myValue = foundValue;
                } else {
                    this.apiRequest(apiClient => this.loadSingle(apiClient, id).then(response => {
                        this.myValue = response;
                    }));
                }
            }
        }
    }
}
</script>
