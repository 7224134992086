<template>
    <div>
        <b-form-group label="Date Range">
            <div class='btn bt-default ml-2' @click='previousMonth'>
                <i class='fa fa-angle-left'></i>
            </div>
            <my-date-range-picker v-model='dateRange' :style='{ display: "inline-block", "vertical-align": "middle" }' />
            <div class='btn bt-default ml-2' @click='nextMonth'>
                <i class='fa fa-angle-right'></i>
            </div>
        </b-form-group>

        <h4 class='m-2'>
            <div v-if='entity'>
                <div>
                    To Deliver:
                </div>
                <div v-if='entity.items_quantity'>
                    <div v-for='(item, index) in entity.items_quantity' :key='index'>
                        <div class='badge badge-success m-2'>
                            {{ item.item.name }}
                        </div>
                        <Progress style='width:200px; height:20px; font-size:15px;'
                            v-if='item.used_quantity || item.available_quantity' :used="item.used_quantity"
                            :total="item.used_quantity + item.available_quantity" />

                        <Progress style='width:200px; margin-top:5px;' v-if='item.over_used_quantity'
                            :used="item.over_used_quantity" :total="0" />
                    </div>
                </div>

                <div class='mt-2'>
                    Balance:
                </div>
                <div v-if='unpaid(entity)' class='badge badge-danger m-2'>
                    {{ unpaid(entity) | currency }}
                </div>

                <div v-if='unusedPayments(entity)' class='badge badge-success m-2'>
                    {{ unusedPayments(entity) | currency }}
                </div>
            </div>
        </h4>
        <div class='card'>
            <div class='card-header'>
                <h4>
                    Sales
                </h4>
            </div>
            <div class='card-body'>
                <b-table :items='sales' :fields='["name", "quantity", "delivered", "price", "payments", "actions"]'
                    foot-clone>

                    <template v-slot:cell(name)='data'>
                        <div :title='data.item.id'>
                            <del v-if='data.item.status == "canceled"'>
                                {{ data.item.description }}
                            </del>
                            <div v-else>
                                {{ data.item.description }}
                            </div>
                        </div>

                        <p v-if='data.item.note' class='text-info p-1' :title="data.item.note">
                            {{ data.item.note | str_limit(30) }}
                        </p>

                        <div class="m-2">
                            <div class='badge badge-light' v-if='data.item.started_at'>
                                {{ data.item.started_at }}
                            </div>
                            -
                            <div class='badge badge-light' v-if='data.item.finished_at'>
                                {{ data.item.finished_at }}
                            </div>
                        </div>
                    </template>

                    <template #cell(delivered)='data'>
                        <div v-for='(entitySaleableItem, index) in data.item.entity_saleable_items' :key='index'>

                            <div v-if='data.item.entity_saleable_items.length > 1'>
                                <div class='badge badge-info m-2' :title='"quantity: " + entitySaleableItem.total_quantity'>
                                    {{ entitySaleableItem.item.name }}
                                </div>
                                <br>
                                <div v-if='entitySaleableItem.start_date' class="badge badge-success ml-2">
                                    from
                                    <i class='fa fa-calendar'></i>
                                    {{ entitySaleableItem.start_date }}
                                </div>
                                <div v-if='entitySaleableItem.end_date' class="badge badge-success ml-2">
                                    to
                                    <i class='fa fa-calendar'></i>
                                    {{ entitySaleableItem.end_date }}
                                </div>
                            </div>

                            <div class="ml-2">
                                <Progress style='width:100px;' :used="entitySaleableItem.used_quantity"
                                    :total="entitySaleableItem.total_quantity" />

                                <button class='btn btn-xxs btn-success m-2'
                                    @click="modal('entityItemsList', { filter: { entity_saleable_item_id: entitySaleableItem.id } }, 'refresh')"><i
                                        class='fa fa-truck-loading'></i> show</button>
                            </div>
                        </div>
                    </template>
                    <template #cell(payments)='data'>
                        <div v-if='data.item.transactions'>
                            <div v-for='(transaction, index2) in data.item.transactions' :key='index2'>
                                <div v-if='transaction.status != "canceled" && transaction.amount_remaining > 0'>
                                    <div class='badge badge-success'>
                                        paid: {{ transaction.amount_paid }}
                                    </div>
                                    <div class='badge badge-danger ml-2'>
                                        remaining: {{ transaction.amount_remaining }}
                                    </div>
                                    <div class='badge badge-light ml-2' v-if='transaction.cash_payment_parts.length == 1'>
                                        <i class='fa fa-calendar'></i>
                                        {{ transaction.cash_payment_parts[0].cash_payment.date | date }}
                                    </div>
                                </div>
                                <div v-else>
                                    <div class='badge badge-success'>
                                        <i class='fa fa-check'></i> paid: {{ transaction.amount_paid }}
                                    </div>
                                    <div class='badge badge-light ml-2' v-if='transaction.cash_payment_parts.length == 1'>
                                        <i class='fa fa-calendar'></i>
                                        {{ transaction.cash_payment_parts[0].cash_payment.date | date }}
                                    </div>
                                </div>

                                <div v-if='transaction.cash_payment_parts.length > 1'>
                                    <div v-for='(cashPaymentPart, index) in transaction.cash_payment_parts' :key='index'
                                        class='m-2'>
                                        <div class='badge badge-info'>
                                            {{ cashPaymentPart.amount | currency }}
                                        </div>
                                        <div class='badge badge-light ml-2'>
                                            {{ cashPaymentPart.cash_payment.date | date }}
                                        </div>
                                    </div>
                                </div>
                                <button v-if='transaction.cash_payment_parts.length > 0' class='btn btn-xxs btn-success m-2'
                                    @click="modal('cashPaymentsList', { filter: { transaction_id: transaction.id } }, 'refresh')"><i
                                        class='fa fa-credit-card'></i> show</button>

                            </div>
                        </div>
                    </template>

                    <template #cell(price)='data'>
                        {{ data.item.total_price | currency }}

                        <div v-if='data.item.quantity > 1'>
                            <br>
                            {{ data.item.quantity }} x

                            {{ data.item.price }}
                            <br>
                            <div class="badge badge-success">
                                {{ data.item.quantity * data.item.price | currency }}
                            </div>
                            <br>
                        </div>
                        <div class="badge badge-success" v-if='data.item.discount > 0'>
                            discount: {{ data.item.discount }} %
                        </div>
                    </template>

                    <template v-slot:cell(actions)='data'>
                        <div v-if='data.item.status != "canceled"'>
                            <button v-if='can("sales.edit")' class='btn btn-xs btn-primary m-2'
                                @click="modal('entitySaleableForm', { id: data.item.id }, 'refresh')">Edit</button>
                            <button v-if='can("sales.cancel")' class='btn btn-xs btn-danger m-2'
                                @click="confirmAction('cancelEntitySaleable', data.item, 'refresh')">Cancel</button>
                        </div>
                        <button v-if='can("sales.delete")' class='btn btn-xs btn-danger m-2'
                            @click="modal('deleteEntitySaleableForm', { id: data.item.id, entity_id: data.item.entity_id }, 'refresh')">Delete</button>
                    </template>
                    <template v-slot:foot(price)="">
                        {{ totalSales | currency }}
                    </template>
                    <template v-slot:foot(payments)="">
                        <div class='badge badge-success'>
                            {{ totalPayed | currency }}
                        </div>
                        <div class='badge badge-danger m-2'>
                            {{ totalRemaining | currency }}
                        </div>
                    </template>
                    <template #foot()="">
                        {{ }}
                    </template>
                </b-table>
            </div>
        </div>

        <div class='card'>
            <div class='card-header'>
                <h4>
                    Cash Payments
                </h4>
            </div>
            <div class='card-body'>
                <cash-payments-list-table viewType='entity'
                    :filter='{ entity_id: entityId, start_date: startDate, end_date: endDate }'></cash-payments-list-table>
            </div>
        </div>

        <Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
        </Modals>
    </div>
</template>

<script>
import moment from 'moment';
import Progress from '../../components/Progress.vue';
import CashPaymentsListTable from '../../components/Lists/CashPaymentsListTable.vue';
import MyDateRangePicker from '../../components/MyDateRangePicker.vue';
export default {
    components: { Progress, CashPaymentsListTable, MyDateRangePicker },
    props: ['entityId'],
    data: function () {
        return {
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            endDate: moment().endOf('month').format('YYYY-MM-DD'),
            sales: [],
            cashPayments: [],
            entity: null,
            dateRange: {},
        };
    },
    mounted() {
        this.refreshData();
    },
    methods: {
        refreshData: function () {
            this.loadEntity();
            this.loadSales();
            this.loadCashPayments();
        },
        refresh: function () {
            this.refreshData();
        },
        loadSales: function () {
            this.apiRequest(apiClient => {
                return apiClient.get('entity-saleables', {
                    entity_id: this.entityId,
                    start_date: this.startDate,
                    end_date: this.endDate,
                });
            }).then(response => {
                this.sales = response.data;
            });
        },
        loadCashPayments: function () {
            this.apiRequest(apiClient => {
                return apiClient.get('cash-payments', {
                    entity_id: this.entityId,
                    start_date: this.startDate,
                    end_date: this.endDate,
                });
            }).then(response => {
                this.cashPayments = response.data;
            });
        },
        loadEntity: function () {
            this.apiRequest(apiClient => {
                return apiClient.get('entities/' + this.entityId, {
                    start_date: this.startDate,
                    end_date: this.endDate,
                });
            }).then(response => {
                this.entity = response;
            });
        },
        unpaid: function (entity) {
            return entity.unpaid_transactions.reduce(function (accumulator, a) {
                return accumulator + parseFloat(a.amount_remaining);
            }, 0)
        },
        unusedPayments: function (entity) {
            return entity.not_used_payments.reduce(function (accumulator, a) {
                return accumulator + parseFloat(a.amount);
            }, 0);
        },
        previousMonth: function () {
            this.dateRange = {
                startDate: moment(this.startDate).subtract(1, "months").startOf("month").toDate(),
                endDate: moment(this.endDate).subtract(1, "months").endOf("month").toDate()
            };
        },
        nextMonth: function () {
            this.dateRange = {
                startDate: moment(this.startDate).add(1, "months").startOf("month").toDate(),
                endDate: moment(this.endDate).add(1, "months").endOf("month").toDate()
            };
        },
        confirmAction(methodName, data) {
            this.confirm().then(
                accept => {
                    if (accept) {
                        if (typeof methodName === 'string' || methodName instanceof String) {
                            this[methodName](data).then(() => this.refresh());
                        } else {
                            methodName.call(data).then(() => this.refresh());
                        }
                    }
                }
            );
        },
        deleteEntitySaleable: function (entitySaleable) {
            return this.apiRequest(apiClient => apiClient.delete('entity-saleables/' + entitySaleable.id)).then(
                () => {
                    this.modal('cashReturnForm', { entityId: entitySaleable.entity_id });
                }
            );
        },
        cancelEntitySaleable: function (entitySaleable) {
            return this.apiRequest(apiClient => apiClient.put('entity-saleables/cancel/' + entitySaleable.id)).then(
                () => {
                    this.modal('cashReturnForm', { entityId: entitySaleable.entity_id });
                }
            );
        }
    },

    watch: {
        dateRange: function () {
            this.startDate = this.dateRange.startDate ? this.toDate(this.dateRange.startDate) : moment().startOf('month').format('YYYY-MM-DD');
            this.endDate = this.dateRange.endDate ? this.toDate(this.dateRange.endDate) : moment(this.startDate).endOf('month').format('YYYY-MM-DD');
            this.refreshData();
        },
    },
    computed: {
        totalSales: function () {
            return this.sales.reduce((sum, sale) => {
                return sum + (sale.status != 'canceled' ? parseFloat(sale.total_price) : 0);
            }, 0);
        },
        totalPayed: function () {
            return this.sales.reduce(
                (sum, sale) => {
                    return sum + parseFloat(
                        sale.transactions ? sale.transactions.reduce(
                            (totalPayed, transaction) => {
                                return totalPayed + parseFloat(transaction.status == 'canceled' ? 0 : transaction.amount_paid);
                            }, 0
                        ) : 0
                    );
                }, 0
            );
        },
        totalRemaining: function () {
            return this.sales.reduce(
                (sum, sale) => {
                    return sum + parseFloat(
                        sale.transactions ? sale.transactions.reduce(
                            (totalRemaining, transaction) => {
                                return totalRemaining + parseFloat(transaction.amount_remaining);
                            }, 0
                        ) : 0
                    );
                }, 0
            );
        },
    }

}
</script>