<template>
    <b-card>
        <items-delivery-form @saved='saved' entityType="kid"></items-delivery-form>
    </b-card>
</template>
<script>
import ItemsDeliveryForm from './ItemsDeliveryForm.vue';
export default {
    components: { ItemsDeliveryForm },
    methods: {
        saved: function () {
            console.log('saved');
        }
    },
}
</script>