import Vue from 'vue'
import Cleave from 'cleave.js';
import moment from 'moment';
import axios from 'axios';
import countryList from 'country-list';

// import Loader from './components/Loader'

Vue.component('localeSwitcher',
    () => import('./components/LocaleSwitcher')
)

Vue.component('loader',
    () => import('./components/Loader')
)

Vue.component('FormErrors',
    () => import('./components/FormErrors')
)

Vue.component('FormGroupError',
    () => import('./components/FormGroupError')
)

Vue.component('RolesSelect',
    () => import('@/components/RolesSelect')
)

Vue.component('ListView',
    () => import('@/views/ListView')
)

Vue.component('SimpleListView',
    () => import('@/views/SimpleListView')
)

Vue.component('Modals',
    () => import('@/components/Modals/Modals')
)

Vue.component('FileUpload',
    () => import('@/components/FileUpload')
)

Vue.component('FileGroupView',
    () => import('@/components/FileGroupView')
)

Vue.component('DatePick',
    () => import('vue-date-pick')
)

Vue.component('Slider',
    () => import('@vueform/slider/dist/slider.vue2.js')
)


Vue.component('MyDateRangePicker',
    () => import('@/components/MyDateRangePicker')
)

Vue.filter('textTime', function (value) {
    if (!value) return ''
    return moment(value, 'h:mm a').format('h:mm a');
})

Vue.filter('datetime', function (value) {
    if (!value) return ''
    value = new moment(value);
    return value.format("ddd, DD MMM h:mm A");
})

Vue.filter('date', function (value) {
    if (!value) return ''
    value = new moment(value);
    return value.format("ddd, DD MMM, YYYY");
})

Vue.filter('date_normal', function (value) {
    if (!value) return ''
    value = new moment(value);

    if (value.year() === moment().year()) {
        return value.format("DD MMM");
    } else {
        return value.format("DD MMM YYYY");
    }
})

Vue.filter('fulldate', function (value) {
    if (!value) return ''
    value = new moment(value);
    return value.format("ddd, DD MMM, YYYY");
})

Vue.filter('time', function (value) {
    if (!value) return ''
    value = new moment(value);
    return value.format("h:mm A");
})

Vue.filter('price', function (value) {
    if (!value) return ''
    let val = (value / 1).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
})

Vue.filter('currency', function (value) {
    if (typeof value !== "number") {
        value = parseFloat(value);
    }
    var formatter = new Intl.NumberFormat('en-EG', {
        style: 'currency',
        currency: 'EGP',
        minimumFractionDigits: 2
    });
    return formatter.format(value);
});



Vue.filter('pluralize', (amount, singular, plural) => (amount > 1 || amount === 0) ? plural : singular);


Vue.filter('decimal', function (value) {
    if (typeof value !== "number") {
        value = parseFloat(value);
    }
    var formatter = new Intl.NumberFormat('en-EG', {
        style: 'decimal',
        minimumFractionDigits: 2
    });
    return formatter.format(value);
});

Vue.filter('str_limit', function (value, size) {
    if (!value) return '';
    value = value.toString();

    if (value.length <= size) {
        return value;
    }
    return value.substr(0, size) + '...';
});

Vue.component('BaseList',
    () => import('@/components/Lists/BaseList')
)

Vue.mixin({
    data() {
        return {
            apiClient: null,
            currentModal: null,
            currentModalData: null,
            modalCallbackName: null
        };
    },
    created() {
        this.apiClient = this.GlobalApiClient;
    },
    computed: {
        currentModalObject: function () {
            return {
                currentModal: this.currentModal,
                currentModalData: this.currentModalData,
                modalCallback: this.modalCallback,
                modalClosed: this.modalClosed
            };
        }
    },
    methods: {
        dynamicID() { return 'dynamicID-' + Math.floor(Math.random() * Date.now().toString()); },
        formatHours(hours) {
            var minutes = hours * 60;
            return moment().startOf('day').add(minutes, 'minutes').format('LT');
        },
        pushQuery(params) {
            params = this.removeObjectNulls(params);
            if (JSON.stringify(params) != JSON.stringify(this.$route.query)) {
                // console.log(params);
                this.$router.push({ query: params });
            }
        },
        removeObjectNulls(obj) {
            for (var propName in obj) {
                if (obj[propName] === null || obj[propName] === undefined) {
                    delete obj[propName];
                }
            }
            return obj
        },
        confirm(message) {
            message = message || 'Are you sure?';
            return this.$bvModal.msgBoxConfirm(message);
        },
        modal(currentModal, modalData, modalCallbackName) {
            this.currentModal = currentModal;
            this.currentModalData = modalData;
            this.modalCallbackName = modalCallbackName;
        },
        modalClosed() {
            this.currentModal = null;
        },
        modalCallback(data) {
            this.currentModal = null;
            if (this.modalCallbackName) {
                this[this.modalCallbackName](data);
            }
        },
        isCurrentModal(modalName) {
            return this.currentModal == modalName ? true : false;
        },
        loadUser() {
            return this.apiClient.user;
        },
        showErrors: function (error) {
            this.$bvToast.toast(error, {
                title: 'Error',
                autoHideDelay: 5000,
                noAutoHide: false,
                variant: 'danger',
                appendToast: true
            });
        },
        apiRequest(fn, preCancelTokenSource) {
            if (preCancelTokenSource) {
                this.apiClient.cancel(preCancelTokenSource);
            }
            this.apiClient = this.GlobalApiClient;
            let cancelTokenSource = this.apiClient.cancelTokenSource();
            return fn.call(this, this.apiClient, cancelTokenSource)
                .catch(e => {
                    if (axios.isCancel(e)) {
                        return;
                    }

                    var status = e.response?.status;
                    if (status == 401) {
                        localStorage.removeItem('token');
                        this.apiClient.setToken(null);
                        this.apiClient.setUser(null);
                        this.$router.push({ name: 'Login' });
                    } else if (status != 422) {
                        let message = e.response?.data?.message ?? e.response?.statusText ?? e.message;
                        this.showErrors(message);
                    }

                    throw e;

                });
        },
        today: function () {
            return moment().format("YYYY-MM-DD");
        },
        datetime: function (date) {
            return moment(date).format("YYYY-MM-DD HH:mm:ss");
        },
        toDate: function (date) {
            return moment(date).format("YYYY-MM-DD");
        },
        dayNumber: function (date) {
            date = moment(date);
            return (date.day() + 2) % 7;
        },
        can: function (key) {
            var user = this.loadUser();
            if (user) {
                if (user.role && user.role.permissions) {
                    return user.role.permissions.indexOf(key) > -1 ? true : false;
                }
            }

            return false;
        },
        userDoctor: function () {
            var user = this.loadUser();
            if (user && user.doctors) {
                return user.doctors.length == 1 ? user.doctors[0] : null;
            }

            return null;
        },
        userDoctors: function () {
            var user = this.loadUser();
            if (user && user.doctors) {
                return user.doctors;
            }

            return [];
        },


        userMultipleDoctors: function () {
            var user = this.loadUser();
            if (user && user.doctors) {
                return user.doctors.length > 1 ? true : false;
            }

            return false;
        },
        userBranch: function () {
            var user = this.loadUser();
            if (user && user.branch) {
                return user.branch;
            }

            return null;
        },
        countryName: function (countryCode) {
            var name = countryList.getName(countryCode);
            return name ? name : countryCode;
        }
    },
});

function getInput(el) {
    if (el.tagName.toLocaleUpperCase() !== 'INPUT') {
        const els = el.getElementsByTagName('input')
        if (els.length !== 1) {
            throw new Error(`v-cleave requires 1 input, found ${els.length}`)
        } else {
            el = els[0]
        }
    }
    return el
}
Vue.directive('cleave', {
    inserted: (el, binding) => {
        el = getInput(el)
        el.cleave = new Cleave(el, binding.value || {})
    },
    update: el => {
        el = getInput(el)
        const event = new Event('input', { bubbles: true })
        setTimeout(function () {
            el.value = el.cleave.properties.result
            el.dispatchEvent(event)
        }, 100)
    }
});