<template>
    <div>
        <date-range-picker
                ref="picker"
                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                :autoApply="true"
                :date-range='value'
                :ranges="ranges"
                @update="updateValue"
        >
            <template v-slot:input="picker" style="min-width: 350px;">
                {{ picker.startDate | date_normal }} - {{ picker.endDate | date_normal }}
            </template>
        </date-range-picker>
    </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
    components: { DateRangePicker },
    props: ['value'],
    data: function() {
        let today = new Date();
        today.setHours(0, 0, 0, 0)

        let yesterday = new Date()
        yesterday.setDate(today.getDate() - 1)
        yesterday.setHours(0, 0, 0, 0);
        
        return {
            ranges: {
                'Today': [today, today],
                'Yesterday': [yesterday, yesterday],
                'This month': [new Date(today.getFullYear(), today.getMonth(), 1), new Date(today.getFullYear(), today.getMonth(), 31)],
                'This year': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
                'Last year': [new Date(today.getFullYear() - 1, 0, 1), new Date(today.getFullYear() - 1, 11, 31)],
                'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
            }
        };
    },
    methods: {
        updateValue: function(value) {
            this.$emit('input', value);
        }
    }
}
</script>

<style>
.calendars {
    flex-wrap: unset !important;
}
</style>