import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'vue-progress-path/dist/vue-progress-path.css'
import VueProgress from 'vue-progress-path'

import VueCompositionAPI from '@vue/composition-api'

import '@/globalComponents'

import 'bootstrap-vue/dist/bootstrap-vue.css'

import Cleave from 'cleave.js';
// import VueCleaveDirective from 'vue-cleave-directive';
import "cleave.js/dist/addons/cleave-phone.eg";

import VCalendar from 'v-calendar';
import VueUploadComponent from 'vue-upload-component'

import VueCookies from 'vue-cookies'
import LightGallery from 'vue-light-gallery';
import NurseryApiClient from '@/api/NurseryClient';

import i18n from "./i18n";


const GlobalApiClient = new NurseryApiClient;
Vue.GlobalApiClient = Vue.prototype.GlobalApiClient = GlobalApiClient;
Vue.prototype.$eventHub = new Vue();

Vue.use(LightGallery);
Vue.use(VueCookies);
Vue.use(VueCompositionAPI);

Vue.component('file-upload', VueUploadComponent);

window.Cleave = Cleave;

Vue.config.productionTip = false;

// Vue.use(VueCleaveDirective);

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);


Vue.use(VueProgress, {
  defaultShape: 'circle',
})

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, { timezone: 'UTC' });



Vue.component('v-select', vSelect);
Vue.use(require('vue-moment'));

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
