<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='currentFields' :modalObject='currentModalObject'
            @pageChanged='pageChanged'>

            <template v-slot:cell(class)='data'>
                <div v-if='data.item.entity.type == "kid" && data.item.entity.kid.class_room'>
                    {{ data.item.entity.kid.class_room.name }}
                </div>
            </template>
            <template v-slot:cell(entity)='data'>
                <router-link v-if='data.item.entity.type == "kid"'
                    :to="{ name: 'ViewKid', params: { id: data.item.entity.kid.id } }" class=''>
                    <h5>
                        {{ data.item.entity.name }}
                    </h5>
                </router-link>
                <div v-else>
                    <router-link :to="{ name: 'ViewEntity', params: { id: data.item.entity.id } }" class=''>
                        <h5>
                            {{ data.item.entity.name }}
                        </h5>
                    </router-link>
                </div>
                <div v-if='false' class='badge badge-light'>
                    {{ data.item.entity.type }}
                </div>

            </template>

            <template v-slot:cell(description)='data'>
                <div :title='data.item.id'>
                    <div>
                        <del v-if='data.item.status == "canceled"'>
                            {{ data.item.quantity }}
                            X
                            {{ data.item.description }}
                        </del>
                        <div v-else>
                            {{ data.item.quantity }}
                            X
                            {{ data.item.description }}
                        </div>
                    </div>
                    <small v-if='data.item.saleable && data.item.saleable.saleable_group'>
                        {{ data.item.saleable.saleable_group.name }}
                    </small>

                </div>
                <div class='badge' v-if='false && data.item.status != "finished"' :class="statusBadge(data.item.status)">
                    {{ data.item.status }}
                </div>

                <p v-if='data.item.note' class='text-info p-1' :title="data.item.note">
                    {{ data.item.note | str_limit(30) }}
                </p>
            </template>
            <template v-slot:cell(items)='data'>
                <div v-if='data.item.entity_saleable_items'>
                    <div v-for='(entitySaleableItem, index) in data.item.entity_saleable_items' :key='index'
                        class="border-bottom">

                        <div>
                            <div class='badge badge-info m-2' :title='"quantity: " + entitySaleableItem.total_quantity'>
                                {{ entitySaleableItem.item.name }}
                            </div>
                            <br>
                            <div v-if='entitySaleableItem.start_date' class="badge badge-success ml-2">
                                from
                                <i class='fa fa-calendar'></i>
                                {{ entitySaleableItem.start_date }}
                            </div>
                            <div v-if='entitySaleableItem.end_date' class="badge badge-success ml-2">
                                to
                                <i class='fa fa-calendar'></i>
                                {{ entitySaleableItem.end_date }}
                            </div>
                        </div>

                        <div class="ml-2">
                            <Progress style='width:100px;' :inline='true' :used="entitySaleableItem.used_quantity"
                                :total="entitySaleableItem.total_quantity" />

                            <button class='btn btn-xxs btn-success m-2'
                                @click="modal('entityItemsList', { filter: { entity_saleable_item_id: entitySaleableItem.id } }, 'refresh')"><i
                                    class='fa fa-truck-loading'></i> show</button>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:cell(price)='data'>
                {{ data.item.total_price | currency }}

                <div v-if='data.item.quantity > 1'>
                    <br>
                    {{ data.item.quantity }} x

                    {{ data.item.price }}
                    <br>
                    <div class="badge badge-success">
                        {{ data.item.quantity * data.item.price | currency }}
                    </div>
                    <br>
                </div>
                <div class="badge badge-success" v-if='data.item.discount > 0'>
                    discount: {{ data.item.discount }} %
                </div>

            </template>
            <template #cell(date)='data'>
                <div>
                    {{ data.item.started_at | date }}
                </div>
            </template>
            <template v-slot:cell(payments)='data'>
                <div v-if='data.item.transactions'>
                    <div v-for='(transaction, index2) in data.item.transactions' :key='index2'>
                        <i class='fa fa-check' v-if='transaction.amount_remaining == 0'></i>
                        {{ transaction.amount | currency }}
                        <div v-if='transaction.status != "canceled" && transaction.amount_remaining > 0'>
                            <div class='badge badge-success'>
                                total paid: {{ transaction.amount_paid }}
                            </div>
                            <div class='badge badge-danger ml-2'>
                                total remaining: {{ transaction.amount_remaining }}
                            </div>
                        </div>
                        <button v-if='transaction.cash_payment_parts.length > 0' class='btn btn-xxs btn-success m-2'
                            @click="modal('cashPaymentsList', { filter: { transaction_id: transaction.id } }, 'refresh')"><i
                                class='fa fa-credit-card'></i> show</button>

                        <div v-if='transaction.cash_payment_parts.length > 1'>
                            <div v-for='(cashPaymentPart, index) in transaction.cash_payment_parts' :key='index'>
                                <div class='badge badge-success'>
                                    {{ cashPaymentPart.amount | currency }}
                                </div>
                                <div class='badge badge-light ml-2'>
                                    {{ cashPaymentPart.cash_payment.date | date }}
                                </div>
                            </div>
                        </div>
                        <div v-else-if='transaction.cash_payment_parts.length == 1'>
                            <div class='badge badge-light ml-2'>
                                {{ transaction.cash_payment_parts[0].cash_payment.date | date }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:cell(total_price)='data'>
                {{ data.item.total_price | currency }}
            </template>
            <template v-slot:cell(created_at)='data'>
                {{ data.item.created_at | date }}
            </template>
            <template v-slot:cell(actions)='data'>
                <div v-if='data.item.status != "canceled"'>
                    <button v-if='can("sales.edit")' class='btn btn-xs btn-primary m-2'
                        @click="modal('entitySaleableForm', { id: data.item.id }, 'refresh')">Edit</button>
                    <button v-if='can("sales.cancel")' class='btn btn-xs btn-danger m-2'
                        @click="confirmAction('cancelEntitySaleable', data.item, 'refresh')">Cancel</button>
                </div>
                <button v-if='can("sales.delete")' class='btn btn-xs btn-danger m-2'
                    @click="modal('deleteEntitySaleableForm', { id: data.item.id, entity_id: data.item.entity_id }, 'refresh')">Delete</button>
            </template>
        </base-list>
    </div>
</template>

<script>
import Progress from '../Progress.vue';
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
    props: ['viewType'],
    components: { BaseList, Progress },
    extends: BaseListMethods,
    data() {
        return {
            fields: [
                // { key: 'id', label: 'ID' },
                { key: 'class', label: 'Class' },
                { key: 'entity', label: 'Entity' },
                { key: 'description', label: 'Name' },
                { key: 'items', label: 'Items' },
                // { key: 'status', label: 'Status' },
                // { key: 'price', label: 'Final Price' },
                // { key: 'total_price', label: 'Final Price' },
                { key: 'payments', label: 'Payments' },
                { key: 'date', label: 'Started At' },
                { key: 'actions', label: 'Actions' },
            ],
            statusBadges: {
                "canceled": "badge-danger",
                "in progress": "badge-warning",
                "finished": "badge-success",
                "pending": "badge-light",
            }
        }
    },
    methods: {
        statusBadge: function (status) {
            return this.statusBadges[status] ? this.statusBadges[status] : "badge-light";
        },
        load: function (apiClient, cancelToken, filter) {
            return apiClient.get('entity-saleables', filter, cancelToken);
        },
        deleteEntitySaleable: function (entitySaleable) {
            return this.apiRequest(apiClient => apiClient.delete('entity-saleables/' + entitySaleable.id)).then(
                () => {
                    this.modal('cashReturnForm', { entityId: entitySaleable.entity_id });
                }
            );
        },
        cancelEntitySaleable: function (entitySaleable) {
            return this.apiRequest(apiClient => apiClient.put('entity-saleables/cancel/' + entitySaleable.id)).then(
                () => {
                    this.modal('cashReturnForm', { entityId: entitySaleable.entity_id });
                }
            );
        }
    },
    watch: {

        // viewType: {
        // 	immediate: true,
        // 	handler: function () {
        // 	}
        // },
    },
    computed: {
        currentFields: function () {
            return this.viewType == 'entity' ? this.fields.filter(item => item.key == 'entity' ? false : true) : this.fields;
        }
    }
}
</script>