<template>
    <div @click="submit">
        <form ref="form" :action="formUrl" method='post' :target="target ? target : ''" style='display:none;'>
            <input type="hidden" name='auth_token' :value='token'>
        </form>
        <slot>
            {{ title }}
        </slot>
    </div>
</template>

<script>
const queryString = require('query-string');

export default {
    props: ['url', 'title', 'target', 'params'],
    data: function () {
        return {
            token: localStorage.token,
            formUrl: ''
        }
    },
    methods : {
        submit : function(){
            
            if (this.url.indexOf('http') == 0) {
                this.formUrl = this.url;
            } else {
                var baseUrl = process.env.VUE_APP_BASE_URL;
                
                this.formUrl = baseUrl.trim('/') + '/' + this.url;

                if (this.params) {
                    var serializedParams = queryString.stringify(this.params);
                    this.formUrl += '?'+serializedParams;
                }
            }
            this.$nextTick(() => {
                this.$refs.form.requestSubmit();
            });
        }
    }
}
</script>