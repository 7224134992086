<template>
    <div class="auth-form">
        <div class='row justify-content-center mb-4'>
            <div class="d-flex flex-column gap-3 align-items-center" v-if="client.logo">
                <img class="logo-abbr" :src="client.logo.url" :alt="client.name">
                <h2> {{ client.name }} </h2>
            </div>
            <div class="col-md-12 text-center" v-if="!hasSubdomain">
                <button v-if="loginMode != 'workspace'" @click="loginMode = 'workspace'" class="btn btn-primary">{{
                    $t('auth.workspaceLogin') }}</button>
                <button v-if="loginMode != 'simple'" @click="loginMode = 'simple'" class="btn btn-primary">{{
                    $t('auth.simpleLogin') }}</button>
            </div>

        </div>

        <b-form class='basic-form' @submit="submit" novalidate>
            <template v-if="loginMode == 'workspace'">
                <div class="form-group" v-if="!hasSubdomain">
                    <label class="mb-1"><strong>{{ $t('auth.clientSlug') }}</strong></label>
                    <input type="text" v-model='form.client_slug' class="form-control" value="">
                </div>
                <div class="form-group">
                    <label class="mb-1"><strong>{{ $t('auth.username') }}</strong></label>
                    <input type="text" v-model='form.username' class="form-control" value="">
                </div>
            </template>
            <div class="form-group" v-if="loginMode == 'simple'">
                <label class="mb-1"><strong>{{ $t('auth.email') }}</strong></label>
                <input type="email" v-model='form.email' class="form-control" value="">
            </div>
            <div class="form-group">
                <label class="mb-1"><strong>{{ $t('auth.password') }}</strong></label>
                <input type="password" v-model='form.password' class="form-control" value="">
            </div>
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <div class="text-center">
                <button v-if='!this.saving' type="submit" class="btn btn-primary btn-block">{{ $t('auth.signin') }}</button>

                <b-button v-if='this.saving' variant="primary" class="btn btn-primary btn-block" :disabled='true'>
                    <b-spinner small type="grow"></b-spinner>
                    checking...
                </b-button>
            </div>

        </b-form>
        <div class="d-flex justify-content-center mb-4 mt-5">
            <locale-switcher />
        </div>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'

export default {
    extends: BaseForm,
    data() {
        return {
            client: {},
            hasSubdomain: null,
            loginMode: 'simple',
            form: {
            }
        };
    },
    mounted() {
        var host = window.location.host;
        var subdomain = host.split('.')[2] ? host.split('.')[0] : '';
        if (subdomain.length) {
            this.hasSubdomain = true;
            this.apiRequest(apiClient => {
                return apiClient.get('/client', { slug: subdomain }).then((response) => {
                    this.client = response.client;
                });
            });
            this.form.client_slug = subdomain;
            this.loginMode = 'workspace';
        }
        if (localStorage.token) {
            this.$router.push({ name: 'Main' });
        }
    },
    methods: {
        save: function (apiClient) {
            var form = this.form;
            if (this.loginMode == 'simple') {
                form = {
                    email: this.form.email,
                    password: this.form.password,
                };
            } else {
                form = {
                    client_slug: this.form.client_slug,
                    username: this.form.username,
                    password: this.form.password,
                };
            }
            return apiClient.post('auth-tokens', form);
        },
        afterSave: function (response) {
            localStorage.removeItem('cashRegisterId');

            localStorage.token = response.token;
            this.apiClient.setToken(response.token);
            this.apiClient.setUser(response.user);
            this.$router.push({ name: 'Main' });
            this.$emit('saved', 1);
        }
    },
}
</script>

<style>
.logo-abbr {
    max-height: 160px;
}

.gap-3 {
    gap: 1.5rem;
}
</style>